import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Empty, message, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "Components/BarLineCharts/charts";
import { AggCode, AirlinesQueryExt, Area, IDownloadHeader } from "Interface";
import useGlobal from "Store";
import {
  calculateCardsContrastVal,
  fillCharts,
  getBetweenDateArr,
  getModule,
  getModuleNameFromPath,
  isAirportMode,
  openDownloadDialog,
  showRawNum,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import {
  COMPARE_TYPE_PER_NAME,
  COMPARE_TYPE_VALUE_NAME,
  DATE_FORMAT,
  LIMIT_END_DATE,
  LIMIT_START_DATE,
  PERCENT_VALUES,
} from "Constants";
import Refetch from "Components/Refetch";
import "Components/BarLineCharts/index.css";
import { getServer } from "Service/server";
import DownloadBtn from "Components/DownloadBtn";
import { getDownloadColumnHeader } from "Utils/downloadXLSX";
import { useLocation } from "react-router-dom";
import { AxisType } from "../../Common";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  cardCode?: string;
  area?: Area;
  departArea?: Area;
  arriveArea?: Area;
  airlinesQueryExt?: AirlinesQueryExt;
  type?: number;
  height?: number;
  style?: React.CSSProperties;
  axis: AxisType;
  onAxisChange: (v: AxisType) => void;
  ext?: any;
  onSuccess?: (res: any) => void;
}

const SearchTrendsBarLineCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    cardCode,
    height = 452,
    style,
    type,
    departArea,
    arriveArea,
    onAxisChange,
    axis,
    ext: otherExt,
    onSuccess,
  } = props;

  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const startDate = isAirport
    ? queryCondition.startDate
    : airlinesQueryCondition.startDate;
  const endDate = isAirport
    ? queryCondition.endDate
    : airlinesQueryCondition.endDate;
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const getExt = useCallback(
    (code: string) => {
      switch (code) {
        case "search_index_airport":
          return { aggCode, departArea, arriveArea, type };
        case "search_index_airlines":
          return { aggCode, departArea, arriveArea, type };
        default:
          return { aggCode, cardCode };
      }
    },
    [aggCode, arriveArea, cardCode, departArea, type]
  );
  const ext = useMemo(() => getExt(moduleCode), [getExt, moduleCode]);
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);
  const axisExt = useMemo(() => {
    return {
      range: {
        ...otherExt?.range,
        axisReverse: axis,
      },
    };
  }, [axis, otherExt]);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
    onSuccess,
  });

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;
  const refetch = useCallback(() => {
    if (!otherExt) {
      return;
    }
    doFetch({
      query,
      ext: {
        ...otherExt,
        tag: otherExt?.tag || null,
        ...ext,
        ...axisExt,
      },
    });
  }, [doFetch, query, otherExt, ext, axisExt]);

  const handleDownload = () => {
    // 列名
    let columns: IDownloadHeader[] = [];
    let title1 = "";
    const axisName =
      axis === 1
        ? getSharkText("key.search.button")
        : getSharkText("config_page_takeoff");
    switch (aggCode) {
      case 0:
        title1 = `${axisName}${getSharkText("config_page_date_day")}`;
        break;
      case 1:
        title1 = `${axisName}${getSharkText("config_page_date_week")}`;
        break;
      case 2:
        title1 = `${axisName}${getSharkText("config_page_date_month")}`;
        break;
    }
    columns = columns.concat([
      {
        title: title1,
        dataIndex: "day",
        downloadFormatter: (v) => moment(v).format(DATE_FORMAT),
      },
    ]);
    columns = columns.concat([
      {
        title: getSharkText("key.search_index.name"),
        dataIndex: "value",
        downloadFormatter: (val: number) => showRawNum(val),
      },
    ]);
    const title2 = `${COMPARE_TYPE_VALUE_NAME[query.compareType]}${getSharkText(
      "key.search_index.name"
    )}`;
    const title3 = COMPARE_TYPE_PER_NAME[query.compareType];
    columns = columns.concat([
      {
        title: title2,
        dataIndex: "compareValue",
        downloadFormatter: (val: number) => showRawNum(val),
      },
      {
        title: title3,
        dataIndex: "resultValue",
        downloadFormatter: (val: number) => showRawNum(val, "percentage"),
      },
    ]);
    let sumSearchIndex = 0;
    let sumSearchIndexCompare = 0;
    // 转格式
    const sheetData1: any = [];
    const sheetDataFn = (record: any, i: number) => {
      const obj1: any = {};
      columns.forEach((c: IDownloadHeader) => {
        const title = getDownloadColumnHeader(c);
        obj1[title] = c.downloadFormatter
          ? c.downloadFormatter(record[c.dataIndex], record, i)
          : record[c.dataIndex];
      });
      sumSearchIndex = sumSearchIndex + record.value;
      sumSearchIndexCompare = sumSearchIndexCompare + record.compareValue;
      return obj1;
    };
    data.sort((d1: any, d2: any) => d1.day.localeCompare(d2.day));
    data.forEach((record: any, i: number) => {
      sheetData1.push(sheetDataFn(record, i));
    });
    // 插入总计
    const sumObj: any = {};
    sumObj[title1] = getSharkText("config_page_total");
    sumObj[getSharkText("key.search_index.name")] = showRawNum(sumSearchIndex);
    sumObj[title2] = showRawNum(sumSearchIndexCompare);
    sumObj[title3] = showRawNum(
      (sumSearchIndex / sumSearchIndexCompare - 1) * 100,
      "percentage"
    );
    sheetData1.push(sumObj);
    // excel
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("key.search_index.name")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("config_page_search_index_trend_chart") + ".xlsx"
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const dataCP = cloneDeep(data);
      const sortDate = dataCP.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value, 0) : 0;
        item.compareValue = item.compareValue
          ? _.round(item.compareValue, 0)
          : 0;
        const resultValue = _.round(
          calculateCardsContrastVal(
            item.value,
            item.compareValue,
            compareType,
            systemType,
            cardCode
          ),
          1
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const sd =
        axis === 1 ? otherExt?.range?.searchDateStart || startDate : startDate;
      const ed =
        axis === 1 ? otherExt?.range?.searchDateEnd || endDate : endDate;
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(sd), moment(ed), aggCode)
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [
    IsDemo,
    aggCode,
    axis,
    data,
    endDate,
    otherExt?.range?.searchDateEnd,
    otherExt?.range?.searchDateStart,
    startDate,
    systemType,
  ]);

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };
  const handleAxisChange = useCallback(
    (e: RadioChangeEvent) => {
      if (
        e.target.value === AxisType.search &&
        (!otherExt.range?.searchDateStart || !otherExt.range?.searchDateEnd)
      ) {
        message.error(getSharkText("key.search_date_as_axis.hint"));
      } else if (
        e.target.value === AxisType.takeoff &&
        query.startDate === LIMIT_START_DATE &&
        query.endDate === LIMIT_END_DATE
      ) {
        message.error(getSharkText("key.departure_date_as_axis.hint"));
      } else {
        onAxisChange(e.target.value);
      }
    },
    [
      otherExt.range?.searchDateStart,
      otherExt.range?.searchDateEnd,
      query.startDate,
      query.endDate,
      onAxisChange,
    ]
  );

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <span style={{ marginRight: 10 }}>
            {getSharkText("key.axis_display.name")}
          </span>
          <Radio.Group value={axis} onChange={handleAxisChange} id="chartsAxis">
            <Radio value={0}>
              {getSharkText("key.by_departure_date.name")}
            </Radio>
            <Radio value={1}>{getSharkText("key.by_search_date.name")}</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          isPercentVal={isPercentVal}
          loading={isLoading}
          systemType={systemType}
          compareType={compareType}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airlines={airlinesQueryCondition.airlines}
          cardCode={cardCode}
          isDemo={IsDemo}
          ext={{ axisType: axis }}
        />
      )}
    </Card>
  );
};

export default SearchTrendsBarLineCharts;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Empty, Spin } from "antd";
import useGlobal from "Store";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { cloneDeep, isEqual } from "lodash";
import Refetch from "Components/Refetch";
import { useNavigate } from "react-router-dom";
import EchartsReactBase from "Components/EchartsReactBase";

interface RadarChartsProps {
  server: string;
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  type: number;
  link: string;
  height?: number;
  queryExt?: any;
}

interface RadarObj {
  indicator: RadarIndicator[];
  radarValue: number[];
}

interface RadarIndicator {
  name: string;
  max: number;
}

interface RadarData {
  scoreName: string;
  value: number;
  compareValue: number;
}
const defaultArea = {};

const RadarCharts = (props: RadarChartsProps) => {
  const {
    server,
    queryUrl,
    moduleCode,
    chartTableCode,
    type,
    link,
    height = 300,
    queryExt = null,
  } = props;
  const navigate = useNavigate();
  const [radarObj, setRadarObj] = useState<RadarObj | null>(null);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [lastQuery, setLastQuery] = useState<any>(null);
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const queryWithExt = useMemo(
    () => ({ ...query, ...queryExt }),
    [query, queryExt]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext: {
      type,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query: queryWithExt,
      ext: { type },
    });
  }, [doFetch, queryWithExt, type]);

  useEffect(() => {
    if (
      isAirport &&
      lastQuery &&
      query.startDate &&
      query.endDate &&
      queryExt
    ) {
      // TODO 需要修复航司没有出入港导致获取参数异常的问题.
      // @ts-ignore 仅机场有出港入港, 航司没有, 属于陈旧的代码问题, 后续修复
      const { flightClass, arrive, departure } = query;
      const { area = defaultArea, yoy } = queryExt;
      const {
        flightClass: lastFlightClass,
        area: lastArea = defaultArea,
        arrive: lastArrive,
        departure: lastDeparture,
        yoy: lastYoy,
      } = lastQuery;
      // 机场模式, 能刷新的情况下, 如果flightClass,区域,进出港选项都相同则不刷新
      if (
        isEqual(flightClass, lastFlightClass) &&
        isEqual(area, lastArea) &&
        isEqual(arrive, lastArrive) &&
        isEqual(departure, lastDeparture) &&
        isEqual(yoy, lastYoy)
      ) {
        return;
      }
    }
    const tmpQuery = cloneDeep(queryWithExt);
    setLastQuery(tmpQuery);
    console.log("refetch");
    refetch();
  }, [queryWithExt, refetch, isAirport, query, queryExt, lastQuery]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const indicatorArr: RadarIndicator[] = [];
      const valueArr: number[] = [];
      data.forEach((item: RadarData) => {
        const tmp = {
          name: item.scoreName,
          max: 10,
        };
        indicatorArr.push(tmp);
        valueArr.push(_.round(item.value, 1));
      });
      setRadarObj({
        indicator: indicatorArr,
        radarValue: valueArr,
      });
    } else {
      setRadarObj(null);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (isLoading) {
    return <Spin />;
  }
  if (!radarObj) {
    return <Empty />;
  }

  const onChartClick = () => {
    console.log("history.push link: ", link);
    navigate(link);
  };

  const onEvents = {
    click: onChartClick,
  };

  const option = {
    radar: {
      name: {
        textStyle: {
          fontSize: 13,
        },
      },
      indicator: radarObj.indicator,
    },
    series: {
      name: getSharkText("config_page_competitiveness_score"),
      type: "radar",
      data: [
        {
          value: radarObj.radarValue,
          name: getSharkText("config_page_competitiveness_score"),
          label: {
            show: true,
            position: "top",
            color: "#fff",
            backgroundColor: "#177DFE",
            padding: [2, 4],
            borderRadius: 3,
          },
        },
      ],
      emphasis: {
        areaStyle: {
          opacity: 0.1,
        },
        lineStyle: {
          width: 4,
        },
      },
    },
  };
  console.log(option, height, onEvents);

  return (
    <EchartsReactBase
      className="radar-charts"
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={{ height }}
      theme="default"
      onEvents={onEvents}
    />
  );
};

export default RadarCharts;

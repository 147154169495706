import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import useRefFunc from "Utils/useRefFunc";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import {
  APORT_NAME,
  CLASS_NAME,
  DATASET_NAME,
  DPORT_NAME,
  PERSONS_NAME,
  TAKEOFFDATE_NAME,
} from "./datasetCols";
import { showNum, useFetch } from "Utils";
import { IFlightRecord } from "../../FlightManageInterface";
import {
  Dimension,
  Filter,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import { Spin } from "antd";
import {
  CompareMean,
  StandardFilter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import "./index.scss";
import Refetch from "Components/Refetch";

const CLASSES = [
  {
    value: 11,
    label: "出发到达均为常住地",
    color: "#1890ff",
  },
  {
    value: 12,
    label: "仅出发为常住地",
    color: "#46a6ff",
  },
  {
    value: 13,
    label: "仅到达为常住地",
    color: "#74bcff",
  },
  {
    value: 21,
    label: "出发到达均为周边",
    color: "#10d1e7",
  },
  {
    value: 22,
    label: "仅出发为周边",
    color: "#40daec",
  },
  {
    value: 23,
    label: "仅到达为周边",
    color: "#70e3f1",
  },
  {
    value: 3,
    label: "前序中转",
    color: "#f8b865",
  },
  {
    value: 4,
    label: "其他",
    color: "#facd91",
  },
];

interface ResData {
  class: number;
  SUM_persons: number | null;
  com_abs_SUM_persons: number | null;
}

export interface PassengerClassProps {
  record: IFlightRecord;
}

/** 航班管理-客源类型浮窗 */
const PassengerClass = (props: PassengerClassProps): ReactElement => {
  const { record } = props;
  const [datasetId, setDatasetId] = useState<number | null>(null);
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<ResData[]>([]);
  const services = useServices();
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(undefined, DATASET_NAME)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
            if (r.data?.length) {
              setDatasetId(r.data[0].datasetId);
            }
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    useCache: true,
    debugId: "PassengerClass",
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers) as unknown as ResData[];
      let allPersons = 0;
      let allComPersons = 0;
      source.forEach((s) => {
        allPersons += s.SUM_persons || 0;
        allComPersons += s.com_abs_SUM_persons || 0;
      });
      const rst = source.map((s) => {
        return {
          ...s,
          SUM_persons: allPersons ? (s.SUM_persons || 0) / allPersons : null,
          com_abs_SUM_persons: allComPersons
            ? (s.com_abs_SUM_persons || 0) / allComPersons
            : null,
        };
      });
      setResData(rst);
    },
  });

  const refetch = useCallback(() => {
    const flightNo = record.flightNo;
    if (!flightNo || !record || !datasetCols.length || datasetId == null) {
      return;
    }
    const dimensions: Dimension[] = [
      {
        columnName: CLASS_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    const measures: Measure[] = [
      {
        id: PERSONS_NAME,
        columnName: PERSONS_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ];
    const filters: Filter[] = [
      {
        columnName: DPORT_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.route.substring(0, 3) || ""],
          },
        ],
      },
      {
        columnName: APORT_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.route.substring(3, 6) || ""],
          },
        ],
      },
    ];
    const sorters: Sorter[] = [
      {
        chartUsedColId: PERSONS_NAME,
        columnName: PERSONS_NAME,
        sorter: "DESC",
        statistical: "SUM",
      },
    ];
    const arg: {
      key: string;
      op: "REPLACE" | "DISABLE";
      filter: StandardFilter;
    } = {
      key: TAKEOFFDATE_NAME,
      op: "REPLACE",
      filter: {
        range: {
          field: `dimension.${TAKEOFFDATE_NAME}`,
          strRange: {
            upper: moment().add(-1, "d").format(DATE_FORMAT),
            lower: moment().add(-28, "d").format(DATE_FORMAT),
          },
        },
      },
    };
    const requestBuild = new RequestBuilder({
      datasetId,
      columns: datasetCols,
      dimensions,
      measures,
      chartFilters: filters,
      sorters,
      containerFilters: [],
      oriFilters: [
        {
          in: {
            field: `dimension.${TAKEOFFDATE_NAME}`,
            key: TAKEOFFDATE_NAME,
            values: [record.takeoffdate || ""],
          },
        },
      ],
      comparators: [
        {
          name: "filterKey",
          comparisonName: "com",
          means: [CompareMean.abs],
          args: ["", JSON.stringify(arg)],
        },
      ],
      preserveComparatorGroups: true,
    });
    const { encrypted } = requestBuild.getRequestBody();
    doFetch({
      ext: {
        datasetId,
        colIds: [],
        req: encrypted,
      },
    });
  }, [datasetCols, datasetId, doFetch, record]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getItem = useRefFunc(
    (percentage: number | null | undefined, color: string) => {
      return (
        <div style={{ display: "flex" }}>
          <div style={{ flex: "0 0 50px", marginRight: 5 }}>
            {percentage === null || percentage === undefined
              ? "-"
              : showNum(percentage * 100, "percentage", 0)}
          </div>
          {percentage === null || percentage === undefined ? undefined : (
            <div
              style={{
                flex: `0 0 ${percentage > 1 ? 150 : percentage * 150}px`,
                background: color,
              }}
            ></div>
          )}
        </div>
      );
    }
  );

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <div className="flight-manage_passenger-class">
      <div style={{ display: "flex", width: 550, marginBottom: 5 }}>
        <div style={{ width: 150 }}></div>
        <div style={{ width: 200, textAlign: "center" }}>
          <div style={{ width: 110, fontWeight: "bold" }}>
            历史28天该航线客源类型占比
          </div>
        </div>
        <div style={{ width: 200, textAlign: "center" }}>
          <div style={{ width: 120, fontWeight: "bold" }}>
            {record.takeoffdate}该航线客源类型占比
          </div>
        </div>
      </div>
      {CLASSES.map((c) => {
        const item = resData.find((r) => r.class === c.value);
        return (
          <div
            className="passenger-class-item"
            key={c.value}
            style={{ display: "flex", width: 550, padding: "3px 0px" }}
          >
            <div style={{ width: 150, textAlign: "left" }}>{c.label}</div>
            <div style={{ width: 200, textAlign: "right" }}>
              {getItem(item?.com_abs_SUM_persons, c.color)}
            </div>
            <div style={{ width: 200, textAlign: "right" }}>
              {getItem(item?.SUM_persons, c.color)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default PassengerClass;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { AggCode, IDownloadHeader } from "Interface";
import { COMPARE_TYPE_VALUE_NAME } from "Constants";
import useGlobal from "Store";
import {
  durationFormatter,
  fillCharts,
  getBetweenDateArr,
  getComparedPercentage,
  getCompareVal,
  openDownloadDialog,
  showRawNum,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import round from "lodash/round";
import indexOf from "lodash/indexOf";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import Refetch from "Components/Refetch";
import AreaCharts from "./AreaCharts";
import BarLineCharts from "./BarLineCharts";
import {
  DURATION_VALUES,
  ITransferAnalysisExt,
  PERCENT_VALUES,
} from "Page/AI/TransferPolicy/TransferPolicyInterface";
import { getServer } from "Service/server";
import DownloadBtn from "Components/DownloadBtn";
import { ILegend, ITransferBarLineChartsChartConfig } from "./common";
import { getDownloadColumnHeader } from "Utils/downloadXLSX";

interface ChartsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  cardCode: string;
  transferAnalysisExt: ITransferAnalysisExt;
  chartType: string;
  setChartType: (type: string) => void;
  height?: number;
  style?: React.CSSProperties;
}

interface Data {
  day: string;
  value: number;
  compareValue: number;
  otherValue: number;
}

const TransferBarLineCharts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    chartType,
    transferAnalysisExt,
    cardCode,
  } = props;
  const { query, filter, riseControl } = transferAnalysisExt;
  const [aggCode, setAggCode] = useState<AggCode>(0);
  // 每日
  const [dayData, setDayData] = useState<Data[]>([]);

  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { startDate, endDate, airport } = queryCondition;
  const isPercentVal = indexOf(PERCENT_VALUES, cardCode) > -1;
  const isDurationVal = indexOf(DURATION_VALUES, cardCode) > -1;
  const server = getServer(systemType);
  const [chartOption, setChartOption] = useState<
    ITransferBarLineChartsChartConfig | undefined
  >();

  const queryWithExt = useMemo(
    () => ({ ...queryCondition, ...query }),
    [query, queryCondition]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext: {
      filter: {
        ...filter,
      },
      cardCode,
      aggCode,
      rate: 0,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      url: queryUrl,
      head: {
        moduleCode,
        chartTableCode,
      },
      query: queryWithExt,
      ext: {
        filter: {
          ...filter,
        },
        cardCode,
        aggCode,
        rate: 0,
      },
    });
  }, [
    aggCode,
    cardCode,
    chartTableCode,
    doFetch,
    filter,
    moduleCode,
    queryUrl,
    queryWithExt,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (isEmpty(data)) {
      setDayData([]);
    } else {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value
          ? isPercentVal
            ? round(item.value, 2)
            : round(item.value)
          : 0;
        item.yoyValue = item.yoyValue
          ? isPercentVal
            ? round(item.yoyValue, 2)
            : round(item.yoyValue)
          : 0;
        item.yoyPer =
          item.value && item.yoyValue !== 0
            ? round(
                isPercentVal
                  ? getComparedPercentage(item.value, item.yoyValue)
                  : getCompareVal(item.value, item.yoyValue),
                2
              )
            : 0;
        if (query.wow !== 0) {
          item.compareValue = item.compareValue
            ? isPercentVal
              ? round(item.compareValue, 2)
              : round(item.compareValue)
            : 0;
          item.comparePer =
            item.value && item.compareValue !== 0
              ? round(
                  isPercentVal
                    ? getComparedPercentage(item.value, item.compareValue)
                    : getCompareVal(item.value, item.compareValue),
                  2
                )
              : 0;
        }
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      setDayData(fillData);
    }
  }, [aggCode, data, endDate, isPercentVal, query.wow, startDate]);

  useEffect(() => {
    const legend: ILegend = { selected: {} };
    const lastName = riseControl.includes("月环比")
      ? getSharkText("key.last_month.checkbox")
      : getSharkText("key.last_week.checkbox");
    const chainName = riseControl.includes("月环比")
      ? getSharkText("key.mom.indicator")
      : getSharkText("key.wow.indicator");
    if (query.wow !== 0) {
      legend.selected[lastName] = false;
      legend.selected[chainName] = false;
    }
    const tmpOption: ITransferBarLineChartsChartConfig = {
      yAxis: [
        {
          position: "left",
          type: "value",
          splitLine: false,
          splitNumber: 5,
          axisLine: {
            show: false,
          },
          axisLabel: {
            formatter: (value: number) =>
              `${
                isPercentVal
                  ? value + "%"
                  : isDurationVal
                  ? durationFormatter(value * 60)
                  : value
              } `,
          },
          axisTick: {
            show: false,
          },
        },
        {
          position: "right",
          type: "value",
          splitLine: false,
          splitNumber: 5,
          axisLine: {
            show: false,
          },
          axisLabel: {
            formatter: isPercentVal
              ? (value: number) => `${value.toFixed(1)}%`
              : (value: number) => `${(value * 100).toFixed(2)}%`,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      legend,
      series: [
        {
          type: "line",
          encode: { x: "day", y: "value" },
          barMaxWidth: 20,
          lineStyle: { width: 3 },
          showSymbol: false,
          symbol: "circle",
          name: airport,
          yAxisIndex: 0,
        },
        {
          type: "line",
          encode: { x: "day", y: "yoyValue" },
          lineStyle: { width: 3 },
          showSymbol: false,
          symbol: "circle",
          name: COMPARE_TYPE_VALUE_NAME[query.yoy === 1 ? 0 : query.yoy],
          yAxisIndex: 0,
        },
        {
          type: "bar",
          encode: { x: "day", y: "yoyPer" },
          barMaxWidth: 20,
          barGap: 0,
          name: getSharkText("key.yoy.button"),
          yAxisIndex: 1,
          selected: false,
        },
        {
          type: "line",
          encode: { x: "day", y: "compareValue" },
          lineStyle: { width: 3 },
          showSymbol: false,
          symbol: "circle",
          name: lastName,
          yAxisIndex: 0,
        },
        {
          type: "bar",
          encode: { x: "day", y: "comparePer" },
          barMaxWidth: 20,
          barGap: 0,
          name: chainName,
          yAxisIndex: 1,
          selected: false,
        },
      ],
    };
    setChartOption(tmpOption);
  }, [
    transferAnalysisExt,
    isPercentVal,
    riseControl,
    query.wow,
    query.yoy,
    airport,
    isDurationVal,
  ]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleChangeAggCode = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  const handleDownload = () => {
    console.log("download");
    const baseColumns: IDownloadHeader[] = [
      { title: "date", dataIndex: "day" },
      {
        title: airport,
        dataIndex: "value",
        downloadFormatter: (val: number) =>
          isPercentVal
            ? showRawNum(val, "percentage")
            : isDurationVal
            ? showRawNum(val, "minute")
            : showRawNum(val),
      },
    ];
    let columns: IDownloadHeader[] = baseColumns;
    if (query.yoy !== 0) {
      columns = columns.concat([
        {
          title:
            query.yoy === 3
              ? getSharkText("compare_year_before_last_value_name")
              : query.yoy === 5
              ? getSharkText("compare_year_last_last_value_name")
              : getSharkText("compare_year_value_name"),
          dataIndex: "yoyValue",
          downloadFormatter: (val: number) =>
            isPercentVal
              ? showRawNum(val, "percentage")
              : isDurationVal
              ? showRawNum(val, "minute")
              : showRawNum(val),
        },
        {
          title: "年同比",
          dataIndex: "",
          downloadFormatter: (val: number, record: any) =>
            isPercentVal
              ? showRawNum(record.yoyPer, "percentage")
              : showRawNum(record.yoyPer * 100, "percentage"),
        },
      ]);
    }
    if (query.wow !== 0) {
      columns = columns.concat([
        {
          title: `${
            query.wow === 1
              ? getSharkText("key.last_week.checkbox")
              : getSharkText("key.last_month.checkbox")
          }`,
          dataIndex: "compareValue",
          downloadFormatter: (val: number) =>
            isPercentVal
              ? showRawNum(val, "percentage")
              : isDurationVal
              ? showRawNum(val, "minute")
              : showRawNum(val),
        },
        {
          title: `${
            query.wow === 1
              ? getSharkText("key.wow.indicator")
              : getSharkText("key.mom.indicator")
          }`,
          dataIndex: "",
          downloadFormatter: (val: number, record: any) =>
            isPercentVal
              ? showRawNum(record.comparePer, "percentage")
              : showRawNum(record.comparePer * 100, "percentage"),
        },
      ]);
    }

    const sheetData1: any = [];
    const sheetDataFn = (record: any, i: number) => {
      const obj1: any = {};
      columns.forEach((c: IDownloadHeader) => {
        const title = getDownloadColumnHeader(c);
        obj1[title] = c.downloadFormatter
          ? c.downloadFormatter(record[c.dataIndex], record, i)
          : record[c.dataIndex];
      });
      return obj1;
    };

    data.forEach((record: any, i: number) => {
      sheetData1.push(sheetDataFn(record, i));
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("menu_transfer_analysis_airlines")
    );
    const workbookBlob = workbook2blob(wb);

    openDownloadDialog(
      workbookBlob,
      getSharkText("config_page_transit_analysis_trend_chart") + ".xlsx"
    );
  };

  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Radio.Group value={aggCode} onChange={handleChangeAggCode}>
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : chartType === "barline" ? (
        <BarLineCharts
          aggCode={aggCode}
          isPercentVal={isPercentVal}
          isDurationVal={isDurationVal}
          loading={isLoading}
          data={dayData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airport={airport}
          chartOptions={chartOption}
        />
      ) : (
        <AreaCharts
          aggCode={aggCode}
          isPercentVal={true}
          loading={isLoading}
          data={dayData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airport={airport}
        />
      )}
    </Card>
  );
};

export default TransferBarLineCharts;

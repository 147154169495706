import React, { useEffect, useState } from "react";
import { Radio, Spin, Empty, Card, Row, Col } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import Charts from "./charts";
import { AggCode, ISeries } from "Interface";
import useGlobal from "Store";
import {
  isAirportMode,
  getComparedPercentage,
  getCompareVal,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { merge, round } from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { ICard } from "Page/AI/IndustryTrend/Interface";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: React.CSSProperties;
  ext?: any;
  card: ICard;
  totalSeries: ISeries[];
}

const LinesCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext: otherExt,
    card,
    totalSeries,
  } = props;
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [barData, setBarData] = useState([]);
  const isAirport = isAirportMode(systemType);
  const isPercentVal = card.cardCode === "order_index";
  const ext = isAirport
    ? merge({}, { aggCode, cardCode: card.cardCode }, otherExt)
    : merge({}, { aggCode, cardCode: card.cardCode }, otherExt);
  const targetName = isAirport
    ? queryCondition.area.areaName
    : airlinesQueryCondition.airlinesName;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: isAirport ? queryCondition : airlinesQueryCondition,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      ext: {
        ...ext,
      },
      query: isAirport ? queryCondition : airlinesQueryCondition,
    });
  };

  useEffect(() => {
    if (card && card.cardCode) {
      refetch();
    }
  }, [aggCode, otherExt, airlinesQueryCondition, card, queryCondition]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.yoy =
          item.value && item.compareValue !== 0
            ? round(
                isPercentVal
                  ? getComparedPercentage(item.value, item.compareValue)
                  : getCompareVal(item.value, item.compareValue),
                2
              )
            : 0;
        item.marketYoy =
          item.marketValue && item.marketCompareValue !== 0
            ? round(
                isPercentVal
                  ? getComparedPercentage(
                      item.marketValue,
                      item.marketCompareValue
                    )
                  : getCompareVal(item.marketValue, item.marketCompareValue),
                2
              )
            : 0;

        return item;
      });
      // console.log(compareData)
      setBarData(compareData);
    } else {
      setBarData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  // if (isLoading) {
  //   return <Spin />;
  // }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  return (
    <Card className="barline-charts" style={style}>
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={AggCode.day}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={AggCode.total}>
              {getSharkText("config_page_total")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {!isLoading && barData && barData.length === 0 ? (
        <Empty style={{ marginTop: 30 }} />
      ) : (
        <Spin spinning={isLoading}>
          <Charts
            seriesName={targetName}
            cardName={card.cardLabel}
            data={barData}
            height={height}
            aggCode={aggCode}
            isPercentVal={isPercentVal}
            card={card}
            totalSeries={totalSeries}
          />
        </Spin>
      )}
    </Card>
  );
};

export default LinesCharts;

import React, { useCallback, useEffect } from "react";
import { openDownloadDialog, useFetch, workbook2blob, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Empty, Popover, Spin, Table, Typography } from "antd";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import "./index.css";
import _ from "lodash";
import { IDownloadHeader, RoleType, UserInfo, UserType } from "Interface";
import useRefFunc from "Utils/useRefFunc";
import { DEFAULT_AIRLINE } from "Constants";

interface ScoreTableProps {
  server: string;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
}

const { Text } = Typography;

const ScoreTable: React.FC<ScoreTableProps> = (props: ScoreTableProps) => {
  const { server, queryUrl, moduleCode, chartTableCode } = props;
  const [globalState] = useGlobal();
  const { userInfo, airlinesQueryCondition } = globalState;
  const query = airlinesQueryCondition;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
    },
    query,
    ext: {
      type: 1,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      head: {
        moduleCode,
      },
      query,
      ext: {
        type: 1,
      },
    });
  }, [doFetch, moduleCode, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getUserRoleAirline = useRefFunc((user: UserInfo) => {
    if (
      user.roleList?.length &&
      user.roleList[0].roleType === RoleType.airline
    ) {
      return user.roleList[0].permissionCode;
    }
    return null;
  });

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (_.isEmpty(data)) {
    return <Empty />;
  }

  const columns: IDownloadHeader[] = [
    {
      title: getSharkText("config_page_seat_rank"),
      dataIndex: "rank",
      key: "rank",
      align: "center",
      sorter: (a: any, b: any) => a.rank - b.rank,
    },
    {
      title: getSharkText("system_type_Airline"),
      dataIndex: "airline",
      key: "airline",
      render: (value: string, record: any) =>
        `${record.airlineName}|${record.airline}`,
    },
    {
      title: () => (
        <>
          <span>
            {getSharkText("config_page_flight_selection_probability_score")}
          </span>
          &nbsp;
          <Popover
            content={getSharkText("config_page_each_airline_calculation")}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "selectScore",
      key: "selectScore",
      align: "center",
      sorter: (a: any, b: any) => a.selectScore - b.selectScore,
    },
    {
      title: () => (
        <>
          <span>{getSharkText("config_page_price_competitiveness_score")}</span>
          &nbsp;
          <Popover
            content={getSharkText(
              "config_page_airline_ticket_price_calculation"
            )}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "priceScore",
      key: "priceScore",
      align: "center",
      // defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.priceScore - b.priceScore,
    },
    {
      title: () => (
        <>
          <span>
            {getSharkText("config_page_seat_kilometer_revenue_score")}
          </span>
          &nbsp;
          <Popover
            content={getSharkText(
              "config_page_seat_kilometer_revenue_score_description"
            )}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "revenueScore",
      key: "revenueScore",
      align: "center",
      sorter: (a: any, b: any) => a.revenueScore - b.revenueScore,
    },
    {
      title: () => (
        <>
          <span>{getSharkText("config_page_load_factor_score")}</span>
          &nbsp;
          <Popover
            content={getSharkText("config_page_load_factor_score_description")}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "lfScore",
      key: "lfScore",
      align: "center",
      sorter: (a: any, b: any) => a.lfScore - b.lfScore,
    },
    {
      title: () => (
        <>
          <span>{getSharkText("config_page_brand_loyalty_score")}</span>
          &nbsp;
          <Popover
            content={getSharkText(
              "config_page_brand_loyalty_score_description"
            )}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "brandScore",
      key: "brandScore",
      align: "center",
      sorter: (a: any, b: any) => a.brandScore - b.brandScore,
    },
  ];

  const handleDownload = () => {
    const sheetData: any = [];
    data.forEach((item: any) => {
      const obj: any = {
        [getSharkText("config_page_seat_rank")]: item.rank,
        [getSharkText(
          "system_type_Airline"
        )]: `${item.airlineName}|${item.airline}`,
        [getSharkText("config_page_flight_selection_probability_score")]:
          item.selectScore,
        [getSharkText("config_page_price_competitiveness_score")]:
          item.priceScore,
        [getSharkText("config_page_seat_kilometer_revenue_score")]:
          item.revenueScore,
        [getSharkText("config_page_load_factor_score")]: item.lfScore,
        [getSharkText("config_page_brand_loyalty_score")]: item.brandScore,
      };
      sheetData.push(obj);
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("config_page_airline_score")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(workbookBlob, "航司得分.xlsx");
  };

  const targetAirline =
    userInfo.user.userType === UserType.internal
      ? DEFAULT_AIRLINE
      : getUserRoleAirline(userInfo);
  const setClassName = (record: any) =>
    record.airline === targetAirline ? "target-row" : "";
  const getAirlineRank = (record: any, target: any) => {
    for (let i = 0; i < record.length; i++) {
      if (record[i].airline === target) {
        return record[i].rank;
      }
    }
  };

  const targetCurrentPage =
    targetAirline && getAirlineRank(data, targetAirline)
      ? Math.ceil(getAirlineRank(data, targetAirline) / 10)
      : 1;

  return (
    <div id="scoreTable">
      <div style={{ marginTop: 16 }}>
        <Table
          showSorterTooltip={false}
          dataSource={data}
          columns={columns}
          rowKey="rank"
          rowClassName={setClassName}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: targetCurrentPage,
          }}
        />
      </div>
      <div style={{ marginTop: -50 }}>
        <DownloadBtn
          handleDownload={handleDownload}
          moduleCode={moduleCode}
          chartTableCode={chartTableCode}
        />
      </div>
    </div>
  );
};

export default ScoreTable;

import { Card, Empty, Row, Spin, Typography } from "antd";
import Refetch from "Components/Refetch";
import { EMPTY_ARRAY } from "Constants";
import { SystemType } from "Interface";
import { isEmpty } from "lodash";
import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { useBatchEffect } from "Utils/useBatchEffect";
import { IProps, Item } from "./RankingV2Interface";
import RankingV2View from "./RankingV2View";

const { Title } = Typography;

export interface IRankingV2Props extends IProps {
  title?: string;
  /**
   * 额外查询参数, 比如说类型, 排序方式, 依据接口传递
   */
  ext?: Record<string, any>;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  style?: CSSProperties;
  /**
   * 当远程数据请求完毕且完成数据转换后会调用此接口
   */
  onDataReady?: (data: Item[]) => void;
  /**
   * 数据格式转换, 后端返回数据后, 需要转换为Item接口类型才能在列表中正常显示
   */
  dataTransFn?: (source?: any[]) => Item[];
  /**
   * 扩展控制栏, 比如类型, 排序等
   */
  exTools?: ReactNode;
  /**
   * 默认数据, 当数据请求成功且未空时展示
   */
  defaultData?: Item[];
}

/**
 * 因为旧版Ranking和AirlineRanking与既有业务有较强的耦合, 难以复用, 新建V2旨在明确输入输出, 同时便利调用
 * 1. 组件RankingV2为业务组件包含部分globalQuery的逻辑, 可以更简单的复用。
 * 2. 组件RankingV2View为视图组件, 不与任何业务逻辑关联
 */
const RankingV2 = (props: IRankingV2Props): ReactElement => {
  const {
    ext,
    queryUrl,
    moduleCode,
    chartTableCode,
    title,
    style,
    dataTransFn,
    exTools,
    defaultData,
    onDataReady,
    ...rest
  } = props;
  const [globalState] = useGlobalState();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const query =
    systemType === SystemType.airport ? queryCondition : airlinesQueryCondition;
  const [itemList, setItemList] = useState<Item[]>(EMPTY_ARRAY);

  const [{ isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext: {},
    lazey: true,
    onSuccess: (res) => {
      const source = res?.data || [];
      const list: Item[] = dataTransFn ? dataTransFn(source) : source;
      if ((!list || !list.length) && defaultData) {
        setItemList(defaultData);
      } else {
        setItemList(list);
      }
      if (onDataReady) onDataReady(list);
    },
  });

  const refetch = useCallback(() => {
    doFetch({
      query,
      ext,
    });
  }, [doFetch, ext, query]);
  useBatchEffect(() => {
    refetch();
  }, [refetch]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        <Row justify="space-between" data-ut-id="ranking-v2-tab">
          {exTools}
        </Row>
        {isEmpty(itemList) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <RankingV2View data={itemList} {...rest} />
        )}
      </Card>
    </Spin>
  );
};
export default RankingV2;

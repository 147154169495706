// Created by xh_zhu on 2021-03-04

import React, { useState, useEffect, ReactElement, useCallback } from "react";
import {
  InfoCircleOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Card,
  Radio,
  List,
  Button,
  Row,
  Spin,
  Empty,
  Popover,
  Col,
} from "antd";
import useGlobal from "Store";
import { FlightArea, City, Tab } from "Interface";
import _ from "lodash";
import {
  getComparePercentageVal,
  getComparedPercentage,
  useFetch,
  U,
} from "Utils";
import { PERCENT_VALUES } from "Constants";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./Ranking.css";
import { getServer } from "Service/server";
import RankSortBtn from "../../../../Components/RankSortBtn";

const { Title, Text } = Typography;

interface IAirportRankingProps {
  title?: string;
  tab: Tab[];
  style?: React.CSSProperties;
  queryUrl: string;
  moduleCode: string;
  area?: object;
  sortable?: boolean;
  selectItem?: (item: FlightArea | null) => void;
  setChartsType?: (type: number) => void;
  cardCode?: string;
  setHasCityData?: (has: boolean) => void;
}

interface AirportCity extends City {
  compareValue?: number;
}

const AirportRanking = (props: IAirportRankingProps): ReactElement => {
  const {
    title = "",
    tab,
    style,
    queryUrl,
    moduleCode,
    area,
    sortable = false,
    selectItem,
    cardCode,
    setChartsType,
    setHasCityData,
  } = props;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [sort, setSort] = useState(1);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [, setCurCityIndex] = useState<number | null>(null);

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode: tab[0].chartTableCode,
    },
    query: queryCondition,
    ext: {
      area,
      orderSort: 0,
      orderByType: 0,
      cardCode,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (currentTab) {
      doFetch({
        head: {
          moduleCode,
          chartTableCode: currentTab.chartTableCode,
        },
        query: queryCondition,
        ext: {
          orderSort: sort,
          orderByType: currentTab.compare,
          cardCode,
          area,
        },
      });
    }
  }, [area, cardCode, currentTab, doFetch, moduleCode, sort, queryCondition]);

  useEffect(() => {
    setCurrentTab(tab[0]);
  }, [tab]);

  useEffect(() => {
    setCurCityIndex(null);
    if (selectItem) {
      selectItem(null);
    }
    if (setHasCityData) {
      setHasCityData(false);
    }
    refetch();
  }, [refetch, selectItem, setHasCityData]);

  useEffect(() => {
    if (data && setHasCityData) {
      setHasCityData(true);
    }
  }, [data, setHasCityData]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleModeChange = (e: any) => {
    setCurrentTab(e.target.value);
    if (setChartsType && selectItem) {
      setChartsType(e.target.value.type);
    }
  };

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };

  const renderRecord = (item: AirportCity, index: number) => {
    let comparePercentage;
    if (cardCode) {
      comparePercentage = isPercentVal
        ? getComparedPercentage(item.value, item.compareValue || 0)
        : getComparePercentageVal(item.value, item.compareValue || 0);
    }
    return (
      <List.Item className="airline-analysis-ranking-item ranking-item">
        <div className="ranking-badge">{++index}</div>
        <div className="ranking-item-content">
          <Text>{item.cityName}</Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            {["kilo_passenger_traffic", "kilo_transport_capacity"].includes(
              cardCode || ""
            ) && Math.abs(item.value) > 10 * 1000 * 1000 ? (
              U.abbreviateNumber(item.value)
            ) : (
              <ValueNum
                type={isPercentVal ? "percentage" : "num"}
                value={item.value}
              />
            )}
          </Text>
        </div>
        <div className="ranking-item-value-yoy">
          <Text>
            <ValueNum type={"percentage"} value={comparePercentage || 0} />
          </Text>
        </div>
        <div className="ranking-item-value-yoy">
          <Text>
            {["kilo_passenger_traffic", "kilo_transport_capacity"].includes(
              cardCode || ""
            ) && Math.abs(item.value) > 10 * 1000 * 1000 ? (
              U.abbreviateNumber(item.value - (item.compareValue || 0))
            ) : (
              <ValueNum
                type={isPercentVal ? "percentage" : "num"}
                value={item.value - (item.compareValue || 0)}
              />
            )}
          </Text>
        </div>
      </List.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="airline-analysis ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        <div style={{ position: "relative" }}>
          <Row
            justify="space-between"
            style={{ alignContent: "flex-end" }}
            data-ut-id="ranking-tab"
          >
            <Col></Col>
            <Col></Col>
            <Radio.Group
              onChange={handleModeChange}
              value={currentTab}
              id="rankTab"
            >
              {tab.map((item: any, index: any) => (
                <Radio.Button value={item} key={index}>
                  {item.title}
                  {item.titleTips ? (
                    <Popover content={item.titleTips}>
                      <InfoCircleOutlined style={{ marginLeft: 5 }} />
                    </Popover>
                  ) : undefined}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Row>
          {sortable && (
            <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
          )}
        </div>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            style={{ margin: "5px 0 -10px" }}
            renderItem={renderRecord}
          />
        )}
      </Card>
    </Spin>
  );
};
export default AirportRanking;

import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Select, SelectProps, Spin } from "antd";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { IFlightItem } from "Page/AI/FlightManage/FlightManageInterface";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import { EMPTY_ARRAY } from "Constants";
import { useBatchEffect } from "Utils/useBatchEffect";
import useRefFunc from "Utils/useRefFunc";
import { stringContains, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { groupBy } from "lodash";
import Refetch from "Components/Refetch";

export interface SelectMultFlightProps extends SelectProps<any> {
  title?: ReactNode;
  queryUrl?: string;
  moduleCode?: string;
  flightNO: string | string[];
  setFlightNO: (list: string[]) => void;
  onDataSourceChange?: (list: IFlightItem[]) => void;
  routes: string;
  /** select by route */
  isDemo: boolean;
  width?: number | "auto";
  /** 默认为2=航班, 对比航班传3 */
  type?: number;
  /**
   * 额外查询参数
   */
  ext?: Record<string, unknown>;
  /**
   * 是否在下拉列表更新后默认选中第一个航班, 默认否
   */
  autoSelectFirst?: boolean;
  /**
   * 调试ID
   */
  debugId?: string;
}

const defaultFlightNo: string[] = [];

const SelectMultiFlight = (props: SelectMultFlightProps): ReactElement => {
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const {
    title,
    queryUrl = QUERY_URL.routeFlight,
    moduleCode = MODULE_CODE,
    flightNO: propFlightNO,
    setFlightNO: setVal,
    onDataSourceChange,
    routes,
    isDemo,
    width = 334,
    type = 2,
    ext,
    autoSelectFirst = false,
    debugId,
    ...otherProps
  } = props;
  /**
   * 统一转为数组, 并且字符串为空时排除
   */
  const flightNO = useMemo(() => {
    return typeof propFlightNO === "string"
      ? propFlightNO
        ? [propFlightNO]
        : defaultFlightNo
      : propFlightNO;
  }, [propFlightNO]);
  const [rawFlightList, setRawFlightList] =
    useState<IFlightItem[]>(EMPTY_ARRAY);
  const [searchText, setSearchText] = useState<string>();

  const queryParam = {
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {
      type,
      permissionType: "group",
      ...ext,
    },
    debugId,
  };

  const setFlightNO = useRefFunc(setVal);

  /**
   * 数据返回时执行操作, 处理流程见SelectMultiFlight.dot
   * @param res 返回的数据
   */
  const onDataResponse = useRefFunc((res: any) => {
    if (res.ResponseStatus?.Ack === "Success") {
      const rData = res.data as IFlightItem[];
      if (rData) {
        setRawFlightList(rData);
        const flights = rData.map((f) => f.flightNo);
        if (
          autoSelectFirst &&
          flights.length &&
          (!flightNO.length || flightNO.some((f) => !flights.includes(f)))
        ) {
          setFlightNO([rData[0].flightNo]);
        }
      } else {
        setRawFlightList(EMPTY_ARRAY);
        console.info("setFlightNO for empty");
        setFlightNO(defaultFlightNo);
      }
      if (onDataSourceChange) {
        onDataSourceChange(rData || EMPTY_ARRAY);
      }
    }
  });

  const [{ isLoading, error }, doFetch] = useFetch<IFlightItem[]>({
    ...queryParam,
    debugId: `${debugId}_all`,
    lazey: true,
    onSuccess: (res) => onDataResponse(res),
  });

  const refetch = useCallback(() => {
    if (isDemo || !airlinesQueryCondition.airlines) {
      return;
    }
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        type,
        permissionType: "group",
        code: null,
        ...ext,
      },
    });
  }, [isDemo, airlinesQueryCondition, doFetch, type, ext]);

  useBatchEffect(() => {
    refetch();
  }, [refetch]);

  const itemList = useMemo(() => {
    const filterRawFlightList = rawFlightList.filter((f) =>
      stringContains(f.flightNo, searchText || "")
    );
    const propsRoutes = routes ? routes.split(",") : [];
    const byRouteList = filterRawFlightList
      .filter((f) => propsRoutes.some((p) => f?.line?.includes(p)))
      .map((f) => f.flightNo);
    const groupByFlightList = groupBy(filterRawFlightList, (f) => f.group);
    // console.log(groupByFlightList)
    const keys = Object.keys(groupByFlightList);
    const air = keys.filter((s) => s !== "other")[0];
    const airName = air === "null" ? "其他" : air;
    // console.log(air)
    const otherFlight =
      groupByFlightList[air]
        ?.filter((f) => !byRouteList.includes(f.flightNo))
        .map((d) => d.flightNo) || [];
    const otherGroupFlightList =
      groupByFlightList.other?.map((d) => d.flightNo) || [];
    const total = 20;
    const byRouteOptions = byRouteList
      .slice(0, total)
      .map((b) => ({ label: b, value: b }));
    const byRouteArr = byRouteOptions.length
      ? [
          {
            label: routes || "Route",
            options: byRouteOptions,
          },
        ]
      : [];
    const otherFlightOptions = otherFlight
      .slice(0, Math.max(0, total - byRouteList.length))
      .map((o) => ({ label: o, value: o }));
    const otherFlightArr = otherFlight.length
      ? [
          {
            label: airName,
            options: otherFlightOptions,
          },
        ]
      : [];
    const otherGroupOptions = otherGroupFlightList
      .slice(0, Math.max(0, total - byRouteList.length - otherFlight.length))
      .map((o) => ({ label: o, value: o }));
    const otherGroupArr = otherGroupFlightList.length
      ? [
          {
            label: "其他",
            options: otherGroupOptions,
          },
        ]
      : [];
    const tmpRst = [...byRouteArr, ...otherFlightArr, ...otherGroupArr];
    return tmpRst.length === 1 ? tmpRst[0].options : tmpRst;
  }, [rawFlightList, routes, searchText]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleChange = (value: string[]) => {
    if (Array.isArray(value)) {
      setFlightNO(value.filter((f) => !!f));
    } else {
      setFlightNO(value ? [value] : []);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {title && <span className="search-area-filter-title">{title}</span>}
        <Select
          className="search-airlines"
          disabled={isDemo}
          notFoundContent="NO Data"
          placeholder={getSharkText("key.mutiple_flightno.query")}
          value={flightNO}
          onChange={handleChange}
          onSearch={handleSearch}
          style={{ width, minWidth: 100 }}
          showSearch
          options={itemList}
          {...otherProps}
        ></Select>
      </Spin>
    </>
  );
};
SelectMultiFlight.displayName = "SelectMultiFlight";
export default SelectMultiFlight;

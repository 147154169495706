import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Empty, Popover, Spin } from "antd";
import useGlobal from "Store";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import { cloneDeep, isEqual } from "lodash";
import "./index.css";
import DashGauge from "./DashGauge";
import { IQueryExt } from "Page/AI/Dashboard/Interface";

interface TrendsPieProps {
  server: string;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  type: number;
  color: string;
  link: string;
  style?: any;
  helpString?: string | ReactNode;
  queryExt?: IQueryExt;
}

const defaultArea = {};

const TrendsPie: React.FC<TrendsPieProps> = (props: TrendsPieProps) => {
  const {
    server,
    queryUrl,
    moduleCode,
    chartTableCode,
    type,
    color,
    helpString,
    queryExt,
  } = props;
  const [globalState] = useGlobal();
  const [lastQuery, setLastQuery] = useState<any>(null);
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const { arrive, departure } = queryCondition;
  const query = isAirport ? queryCondition : airlinesQueryCondition;

  const queryWithExt = useMemo(
    () => ({
      ...query,
      ...queryExt,
    }),
    [query, queryExt]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch<any>({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext: {
      type,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (queryWithExt.startDate && queryWithExt.endDate) {
      doFetch({
        query: queryWithExt,
        ext: { type },
      });
    }
  }, [doFetch, queryWithExt, type]);

  useEffect(() => {
    if (
      isAirport &&
      lastQuery &&
      query.startDate &&
      query.endDate &&
      queryExt
    ) {
      // TODO 需要修复的代码
      // @ts-ignore 历史代码写法有问题, 但目前不影响线上运行, 稍后修复
      const { flightClass, arrive, departure } = query;
      const { area = defaultArea, yoy } = queryExt;
      const {
        flightClass: lastFlightClass,
        area: lastArea = defaultArea,
        arrive: lastArrive,
        departure: lastDeparture,
        yoy: lastYoy,
      } = lastQuery;
      // 机场模式, 能刷新的情况下, 如果flightClass,区域,进出港选项都相同则不刷新
      if (
        isEqual(flightClass, lastFlightClass) &&
        isEqual(area, lastArea) &&
        isEqual(arrive, lastArrive) &&
        isEqual(departure, lastDeparture) &&
        isEqual(yoy, lastYoy)
      ) {
        return;
      }
    }
    const tmpQuery = cloneDeep({ ...query, ...queryExt });
    setLastQuery(tmpQuery);
    refetch();
  }, [query, queryExt, arrive, departure, isAirport, refetch]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (isLoading) {
    return <Spin />;
  }
  if (!data) {
    return <Empty />;
  }

  const currentValue = data.currentValue ? data.currentValue : 0;
  const marketCurrentValue = data.marketCurrentValue
    ? data.marketCurrentValue
    : 0;

  return (
    <div className="trends-mod">
      {isAirport ? (
        <>
          <div className="trends-header">
            <span>{data.indexName}</span>
            {helpString ? (
              <Popover
                content={helpString}
                overlayClassName="dashboard-val-control-popover"
              >
                <InfoCircleOutlined />
              </Popover>
            ) : undefined}
          </div>
        </>
      ) : undefined}
      <DashGauge
        color={color}
        max={200}
        value={currentValue / 200}
        size={isAirport ? "small" : "middle"}
      ></DashGauge>
      {isAirport ? (
        <div className="market">
          <span>{getSharkText("key.compare_to_market")}: </span>
          <span>
            {marketCurrentValue === 0 ? "-" : `${marketCurrentValue}%`}
          </span>
          {marketCurrentValue === 0 ? undefined : (
            <span className="icon-caret">
              {marketCurrentValue >= 0 ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              )}
            </span>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

export default TrendsPie;

import React, { useEffect, useState } from "react";
import { Col, Empty, Row, Spin } from "antd";
import {
  AggCode,
  AirlinesQueryCondition,
  ECompareTypeV2,
  QueryCondition,
} from "Interface";
import useGlobal from "Store";
import {
  getComparedPercentage,
  getCompareVal,
  isAirportMode,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { merge, round } from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";

import { ICard } from "../../Interface";
import BarCharts from "./BarCharts";
import { COMPARE_TYPE_PLC_NAME } from "Constants";

interface TopChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: object;
  query: AirlinesQueryCondition | QueryCondition;
  ext?: any;
  card: ICard;
  cardCode: string;
  title: string;
  type: number;
  panelType?: 0 | 1;
  compareType: ECompareTypeV2;
}

const TopCharts: React.FC<TopChartsProps> = (props: TopChartsProps) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    ext: otherExt,
    panelType,
    query,
    card,
    compareType,
    type,
    title,
    cardCode,
  } = props;
  const [aggCode] = useState<AggCode>(0);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [barData, setBarData] = useState([]);
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const isAirport = isAirportMode(systemType);
  const ext = isAirport
    ? merge({}, { aggCode, type, cardCode }, otherExt)
    : merge({}, { aggCode, type, cardCode }, otherExt);
  const targetName = isAirport
    ? queryCondition.airportName
    : airlinesQueryCondition.airlinesName;
  const isPercentVal = card.isPercentVal;
  const prefix = COMPARE_TYPE_PLC_NAME[compareType];
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
        type,
        cardCode,
      },
    });
  };
  useEffect(() => {
    if (panelType === 0) {
      if (type === 0) {
        const fields = [
          `${getSharkText("charts.market.seriesvaluename")}${card.cardLabel}`,
          prefix,
        ];
        setFieldNames(fields);
      } else {
        const fields = [`${targetName}${card.cardLabel}`, prefix];
        setFieldNames(fields);
      }
    } else {
      if (type === 0) {
        const fields = [getSharkText("config_page_search_volume"), prefix];
        setFieldNames(fields);
      } else {
        const fields = [getSharkText("config_page_ticket_volume"), prefix];
        setFieldNames(fields);
      }
    }
  }, [card, panelType, compareType, type, airlinesQueryCondition]);
  useEffect(() => {
    refetch();
  }, [aggCode, query, otherExt, airlinesQueryCondition]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.value = round(item.value);
        item.compareValue = round(item.compareValue);
        item.yoy =
          item.value && item.compareValue !== 0
            ? round(
                isPercentVal
                  ? getComparedPercentage(item.value, item.compareValue)
                  : getCompareVal(item.value, item.compareValue),
                2
              )
            : 0;
        return item;
      });
      // console.log(compareData)
      setBarData(compareData);
    } else {
      setBarData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ flexFlow: "column" }}
      >
        <Col xs={24}>
          <h3 style={{ color: "#000000d9", fontWeight: "bold" }}>
            {title} &nbsp;
          </h3>
        </Col>
      </Row>
      <Row style={{ flexFlow: "column" }}>
        {barData && barData.length === 0 ? (
          <Empty style={{ marginTop: 30 }} />
        ) : (
          <Spin spinning={isLoading}>
            <BarCharts
              data={barData}
              loading={isLoading}
              fieldNames={fieldNames}
              isPercentVal={isPercentVal}
            />
          </Spin>
        )}
      </Row>
    </>
  );
};

export default TopCharts;

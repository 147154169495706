import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { useLocation } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "./charts";
import { AggCode, ISeries } from "Interface";
import useGlobal from "Store";
import {
  fillCharts,
  getBetweenDateArr,
  getComparePercentageVal,
  getModule,
  getModuleNameFromPath,
  isAirportMode,
  useFetch,
} from "Utils";
import _ from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import DownloadBtn from "Components/DownloadBtn";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: CSSProperties;
  handleDownload?: () => void;
  ext?: Record<string, unknown>;
  defaultSeries: ISeries[];
  useGranularity?: boolean;
  server?: string;
  chartConfig?: Record<string, unknown>;
  startDate: string;
  endDate: string;
  compareType: number;
  aggCode?: AggCode;
  // 图表保留几位小数
  dec?: number;
}

// 新版的线图组件, 组件内不与任何业务组件关联

const BarLineCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext,
    defaultSeries,
    useGranularity = true,
    server,
    chartConfig,
    startDate,
    endDate,
    compareType,
    aggCode: parentAggCode,
    dec = 1,
  } = props;

  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);
  const tableRef = useRef<any>();

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: server || getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });
  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: { ...ext, aggCode },
    });
  }, [aggCode, doFetch, ext, query]);

  useEffect(() => {
    if (typeof parentAggCode !== "undefined") {
      setAggCode(parentAggCode);
    }
  }, [parentAggCode]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      console.log(moment().utcOffset());
      console.log(moment(moment("/Date(1609430400000)/").format()));
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        const keys = Object.keys(item);
        keys.forEach((key: string) => {
          const compareKey = `${key}Compare`;
          if (item[compareKey]) {
            item[`${compareKey}Per`] = getComparePercentageVal(
              item[key],
              item[compareKey]
            );
          }
        });
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [IsDemo, aggCode, data, endDate, startDate]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  const handleDownload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.download();
    }
  };

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between" style={{ flexDirection: "column" }}>
        {useGranularity ? (
          <Col>
            <Radio.Group
              value={aggCode}
              onChange={handleChange}
              id="chartsAggCode"
            >
              <Radio.Button value={0}>Day</Radio.Button>
              <Radio.Button value={1}>Week</Radio.Button>
              <Radio.Button value={2}>Month</Radio.Button>
              <Radio.Button value={3}>Year</Radio.Button>
            </Radio.Group>
          </Col>
        ) : undefined}
        <Col style={{ alignSelf: "flex-end" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            en
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          ref={tableRef}
          aggCode={aggCode}
          loading={isLoading}
          systemType={systemType}
          compareType={compareType}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airlines={airlinesQueryCondition.airlines}
          isDemo={IsDemo}
          defaultSeries={defaultSeries}
          chartConfig={chartConfig}
          dec={dec}
        />
      )}
    </Card>
  );
};

export default BarLineCharts;

import React, { useEffect, useState } from "react";
import { Card, Empty, Row, Spin } from "antd";
import Charts from "./charts";
import { AggCode, AirlinesQueryCondition, QueryCondition } from "Interface";
import useGlobal from "Store";
import {
  getComparedPercentage,
  getCompareVal,
  isAirportMode,
  isNotZeroNumeric,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { merge, round } from "lodash";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";

import { ICard } from "../../Interface";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: object;
  query: AirlinesQueryCondition | QueryCondition;
  ext?: any;
  card: ICard;
  panelType?: 0 | 1;
  compareType: number;
}

const Top20Charts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext: otherExt,
    query,
    card,
    compareType,
    panelType,
  } = props;
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [barData, setBarData] = useState([]);
  const isAirport = isAirportMode(systemType);
  const ext = isAirport
    ? merge({}, { aggCode, type: panelType }, otherExt)
    : merge({}, { aggCode }, otherExt);
  const targetName = isAirport
    ? queryCondition.airportName
    : airlinesQueryCondition.airlinesName;
  const isPercentVal = card.isPercentVal;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
        type: panelType,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [aggCode, query, otherExt, airlinesQueryCondition, card]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        a.value > b.value ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = item.dcityName;
        item.yoy =
          item.value && isNotZeroNumeric(item.compareValue)
            ? round(
                isPercentVal
                  ? getComparedPercentage(item.value, item.compareValue)
                  : getCompareVal(item.value, item.compareValue),
                2
              )
            : 0;
        item.marketYoy =
          item.marketValue && isNotZeroNumeric(item.marketCompareValue)
            ? round(
                isPercentVal
                  ? getComparedPercentage(
                      item.marketValue,
                      item.marketCompareValue
                    )
                  : getCompareVal(item.marketValue, item.marketCompareValue),
                2
              )
            : 0;

        return item;
      });
      // console.log(compareData)
      setBarData(compareData);
    } else {
      setBarData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Card className="barline-charts" style={style}>
      <Row justify="space-between">
        <h2>{getSharkText("config_page_top20_airports")}</h2>
      </Row>
      {!isLoading && barData && barData.length === 0 ? (
        <Empty style={{ marginTop: 30 }} />
      ) : (
        <Spin spinning={isLoading}>
          <Charts
            seriesName={targetName}
            cardName={card.cardLabel}
            data={barData}
            height={height}
            aggCode={aggCode}
            startDate={query.startDate}
            endDate={query.endDate}
            isPercentVal={isPercentVal}
            compareType={compareType}
          />
        </Spin>
      )}
    </Card>
  );
};

export default Top20Charts;

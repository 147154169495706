import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Area } from "Interface";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { Col, Empty, Input, Row, Spin, Table } from "antd";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import _ from "lodash";
import { getServer } from "Service/server";
import { getColumns } from "./columns";
import { TouristQueryExt } from "../../TouristInterface";
import { downloadExcel } from "Utils/downloadXLSX";

interface TouristTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesQueryExt: TouristQueryExt;
}

const TouristTable: React.FC<TouristTableProps> = (
  props: TouristTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode, area, airlinesQueryExt } =
    props;
  const [searchText, setSearchText] = useState<string>("");
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = useMemo(
    () => (isAirport ? { area } : { filter: { ...airlinesQueryExt } }),
    [airlinesQueryExt, area, isAirport]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: { ...ext },
    });
  }, [doFetch, query, ext]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns = useMemo(() => {
    const flightNO =
      ext.filter?.filterType === 2 ? ext.filter.flightNO?.join(",") : undefined;
    return getColumns(
      isAirport,
      airlinesQueryExt.indexes,
      airlinesQueryCondition.airlines,
      flightNO
    );
  }, [
    airlinesQueryCondition.airlines,
    airlinesQueryExt.indexes,
    ext.filter?.filterType,
    ext.filter?.flightNO,
    isAirport,
  ]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (_.isEmpty(data)) {
    return <Empty />;
  }

  const tableData = data.map((item: any, idx: number) => {
    item.key = idx;
    return item;
  });

  const getFilterData = () => {
    const cloneData = _.cloneDeep(tableData);
    const filter = cloneData.filter((item: any) => {
      let isPass = false;
      for (const key of ["dportName", "aportName", "dport", "aport"]) {
        if (item[key] && item[key].includes(searchText.toUpperCase())) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = getFilterData();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownload = () => {
    downloadExcel(
      columns,
      filterData,
      getSharkText("menu_tourist_source_airlines")
    );
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col style={{ width: 300 }}>
            <Input.Search
              value={searchText}
              placeholder={getSharkText("key.input_to_search_dep_arr")}
              onChange={handleSearch}
            />
          </Col>
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        </Row>
      </Col>
      <Col style={{ marginTop: 16 }}>
        <Table
          showSorterTooltip={false}
          tableLayout="fixed"
          dataSource={filterData}
          columns={columns}
        />
      </Col>
    </Row>
  );
};

export default TouristTable;

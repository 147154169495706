import React, { useEffect, useCallback, useMemo } from "react";
import { Spin, Row, Col, Empty } from "antd";
import { CardItemData } from "Interface";
import { useFetch } from "Utils";
import useGlobal from "Store";
import Refetch from "Components/Refetch";
import _ from "lodash";
import "./index.css";
import TransferCardItem from "./TransferCardItem";
import { ITransferAnalysisExt } from "Page/AI/TransferPolicy/TransferPolicyInterface";
import { getServer } from "Service/server";

interface TransferCardProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  transferAnalysisExt: ITransferAnalysisExt;
  setCurCard: (card: CardItemData) => void;
  selectedCode: string;
}

const TransferCard: React.FC<TransferCardProps> = (
  props: TransferCardProps
) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    transferAnalysisExt,
    setCurCard,
    selectedCode,
  } = props;
  const { query, filter } = transferAnalysisExt;

  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const server = getServer(systemType);
  const queryWithExt = useMemo(
    () => ({ ...airlinesQueryCondition, ...query }),
    [airlinesQueryCondition, query]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext: {
      filter: { ...filter },
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query: queryWithExt,
      ext: { filter: { ...filter } },
    });
  }, [doFetch, filter, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const groupData = !_.isEmpty(data) ? _.groupBy(data, "cardCode") : {};

  const onCardChange = (newData: CardItemData) => {
    setCurCard(newData);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="compare-cards-carousel">
        <div className="compare-carousel-con">
          {!_.isEmpty(groupData) ? (
            // <Row gutter={[{ xs: 6, sm: 12, md: 18 }, 20]} className='compare-cards-row'>
            <div style={{ margin: "10px 10px 0" }}>
              <Row
                gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
                style={{ flexWrap: "nowrap" }}
                justify="space-between"
              >
                {data.map((d: any, idx: number) => (
                  <Col
                    className="transfer-analyze-card"
                    xxl={3}
                    xl={4}
                    lg={6}
                    md={8}
                    sm={8}
                    xs={12}
                    key={idx}
                  >
                    <TransferCardItem
                      data={d}
                      selectedCode={selectedCode}
                      onCardChange={onCardChange}
                      queryExt={query}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default TransferCard;

import React, { useEffect } from "react";
import { isAirportMode, useFetch } from "Utils";
import useGlobal from "Store";
import { Spin, Row, Col, Card } from "antd";
import Refetch from "Components/Refetch";
import SimplePictorialBarCharts from "../SimplePictorialBarCharts";
import SimpleBarLineCharts from "../SimpleBarLineCharts";
import SimpleTreeMapCharts from "../SimpleTreeMapCharts";
import SimplePieCharts from "../SimplePieCharts";
import { getServer } from "Service/server";

interface BlockProps {
  queryUrl: string;
  moduleCode: string;
  title: string;
  subtitle: string;
  chartTableCode: string;
  chartType: string;
  ext?: any;
  seriesMap: any;
  chartConfig: any;
  dataSort?: (a: any, b: any) => number;
  type: number;
}

const UserProfileBlock: React.FC<BlockProps> = (props: BlockProps) => {
  const {
    queryUrl,
    moduleCode,
    title,
    subtitle,
    chartTableCode,
    chartType,
    ext,
    seriesMap,
    chartConfig,
    dataSort,
    type,
  } = props;
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      ...ext,
      type,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
      ext: { ...ext, type },
    });
  };

  useEffect(() => {
    refetch();
    // TODO filter 逻辑需要完善

    // *************** eslint-disable-next-line react-hooks/exhaustive-deps
    //
  }, [ext, query]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  // if (_.isEmpty(data)) { return <Empty /> }
  if (data && dataSort) {
    data.sort(dataSort);
  }

  const titleContent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{title}</div>&nbsp;&nbsp;&nbsp;&nbsp;
      <div
        className="sub-card-title"
        style={{ fontSize: "8px", color: "#A9A9A9" }}
      >
        {subtitle}
      </div>
    </div>
  );

  return (
    <Card type="inner" title={titleContent}>
      <Row>
        {/* <Col xs={24} className='user-profile-block-header'>
                    <span>{title}</span>
                </Col> */}
        <Col xs={24}>
          {chartType === "pictorialBar" ? (
            <SimplePictorialBarCharts
              data={data}
              loading={isLoading}
              seriesMap={seriesMap}
            ></SimplePictorialBarCharts>
          ) : chartType === "treemap" ? (
            <SimpleTreeMapCharts
              data={data}
              loading={isLoading}
              seriesMap={seriesMap}
            ></SimpleTreeMapCharts>
          ) : chartType === "pie" ? (
            <SimplePieCharts
              data={data}
              loading={isLoading}
              seriesMap={seriesMap}
              chartConfig={chartConfig}
            ></SimplePieCharts>
          ) : (
            <SimpleBarLineCharts
              data={data}
              loading={isLoading}
              seriesMap={seriesMap}
              chartConfig={chartConfig}
            ></SimpleBarLineCharts>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default UserProfileBlock;

import React from "react";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { getSharkText } from "Utils/i18nGlobal";
import useI18Next from "Utils/useI18Next";

interface IProps {
  size?: "small" | "default";
  error?: any;
  refetch: () => void;
  style?: object;
}

const Refetch: React.FC<IProps> = (props: IProps) => {
  const { size = "default", refetch, style, error } = props;
  const code = error ? error.ErrorCode : "";
  const t = useI18Next();
  const message = code ? t(`error.${code}`) : "";
  const msg = code ? code + ":" + message : "";

  return (
    <div style={style}>
      {size === "default" ? (
        <Result
          status="warning"
          subTitle={msg || getSharkText("key.serverbusy.hint")}
          extra={
            <Button onClick={refetch} key="console">
              <ReloadOutlined />
              {getSharkText("key.refresh.button")}
            </Button>
          }
        />
      ) : (
        <Button onClick={refetch} size="small">
          <ReloadOutlined />
          {getSharkText("key.refresh.button")}
        </Button>
      )}
    </div>
  );
};

export default Refetch;

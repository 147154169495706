import React, { useCallback, useEffect, useState } from "react";
import { Col, Empty, Row, Spin, Tooltip } from "antd";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import CardItem, { ICardItemProps } from "./CardItem";
import { CardItemData } from "Interface";
import Refetch from "Components/Refetch";
import _, { cloneDeep } from "lodash";
import { getServer } from "Service/server";
import { useFetch } from "Utils/useFetch";
import { ICardConfigList } from "./CardsInterface";

interface CardsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  curCardCode: string;
  setCurCard: (card: CardItemData) => void;
  setHasCardData: (has: boolean) => void;
  /**
   * 对卡片组合, 可以将多组数据在同一张卡片上用于切换. 用cardCode组合
   * 每个数组第一项是默认展示的项,后续项为切换项 格式例如
   * [
   *  ["cardCode1", "cardCode3", "code1back4"],
   *  ["cardCode2", "code2back"]
   * ]
   * 假如第一项不存在则跳过该组合
   */
  cardGroup?: string[][];
  ext?: any;
  config?: ICardConfigList;
}

export const getCardGroupKey = (data: CardItemData[]): string => {
  return "card-group-" + data.map((d) => d.cardCode).join("-");
};

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    setCurCard,
    curCardCode,
    setHasCardData,
    cardGroup,
    ext,
    config,
  } = props;
  const [globalState] = useGlobal();
  const { queryCondition, helpVisibility, airlinesQueryCondition, systemType } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const [data, setData] = useState<Array<CardItemData | CardItemData[]>>([]);

  const [{ isLoading, error }, doFetch] = useFetch<CardItemData[]>({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
    onSuccess: (res) => {
      const d = res.data;
      d.forEach((dItem) => {
        if (config && config[dItem.cardCode]) {
          dItem.feCardConfig = config[dItem.cardCode];
        }
      });
      if (cardGroup) {
        const tmp = cloneDeep(d);
        let groupedKeys: string[] = [];
        const rst: CardItemData[][] = [];
        tmp.forEach((item) => {
          if (groupedKeys.includes(item.cardCode)) {
            return;
          }
          const g = cardGroup.find((cg) => cg.includes(item.cardCode));
          if (g) {
            rst.push(tmp.filter((t) => g.includes(t.cardCode)));
            groupedKeys = groupedKeys.concat(g);
          } else {
            rst.push([item]);
          }
        });
        setData(rst);
      } else {
        setData(d);
      }
    },
  });

  const refetch = useCallback(() => {
    doFetch({ query, ext });
  }, [doFetch, ext, query]);

  useEffect(() => {
    setHasCardData(false);
    refetch();
  }, [refetch, setHasCardData]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (!curCardCode) {
        if (Array.isArray(data[0])) {
          if (data[0].length > 1) {
            const key = getCardGroupKey(data[0]);
            const storageIdx = localStorage.getItem(key);
            const selectedIdx =
              storageIdx !== null && data[0][Number(storageIdx)]
                ? Number(storageIdx)
                : 0;
            setCurCard(data[0][selectedIdx]);
          } else {
            setCurCard(data[0][0]);
          }
        } else {
          setCurCard(data[0]);
        }
      }
      setHasCardData(true);
    }
  }, [data]);

  const renderCard = useCallback(
    (item: CardItemData | CardItemData[]) => {
      const tmpProps: ICardItemProps = {
        data: item,
        selectedCode: curCardCode,
        setCurCard,
        systemType,
        compareType: isAirportMode(systemType)
          ? queryCondition.compareType
          : airlinesQueryCondition.compareType,
      };
      return <CardItem {...tmpProps} />;
    },
    [
      curCardCode,
      setCurCard,
      systemType,
      airlinesQueryCondition.compareType,
      queryCondition.compareType,
    ]
  );

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Tooltip
      title={getSharkText("card.switch_card.explaination")}
      getPopupContainer={() => document.getElementById("main") || document.body}
      visible={helpVisibility}
      placement="bottomLeft"
    >
      <Spin spinning={isLoading}>
        <div className="cards-carousel">
          <div className="cards-carousel-content">
            {!_.isEmpty(data) ? (
              <Row
                gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
                className="cards-row"
              >
                {data.map(
                  (item: CardItemData | CardItemData[], index: number) => (
                    <Col
                      xl={4}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={24}
                      key={index}
                      className="cards-column"
                    >
                      {renderCard(item)}
                    </Col>
                  )
                )}
              </Row>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Spin>
    </Tooltip>
  );
};

export default Cards;

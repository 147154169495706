import React, { useState, useEffect, useRef } from "react";
import { Empty, Spin } from "antd";
import useGlobal from "Store";
import _, { isEmpty, max, round } from "lodash";
import { getProportionVal, isAirportMode, showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import BMap from "Components/BMap";
import { getServer } from "Service/server";
import "echarts/extension/bmap/bmap";
import EchartsReactBase from "Components/EchartsReactBase";

const maxPointSize = 30;

interface ChartMapProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  ext: any;
}
interface Data {
  airport: string;
  airportName: string;
  value: number;
  compareValue: number;
  lng: number;
  lat: number;
}

const MapChart: React.FC<ChartMapProps> = (props: ChartMapProps) => {
  const { queryUrl, moduleCode, chartTableCode, ext: otherExt } = props;
  const [option, setOption] = useState<object>({});
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, queryCondition, systemType } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = { ...otherExt };
  const [isBMapReady, setIsBMapReady] = useState<boolean>(false);
  const [isChartReady, setIsChartReady] = useState<boolean>(false);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    // lazey: true,
  });
  const maxVal = data ? max(data.map((d: any) => d.value) as number[]) : 1;
  const chartsRef = useRef<any>(null);
  const refetch = () => {
    doFetch({ query, ext: { ...ext } });
  };
  useEffect(() => {
    // 必须执行这一步才能引入百度地图组件
    try {
      BMap.init().then(() => {
        // @ts-ignore
        console.log("overlap: ", BMap.Overlay);
        // BMAP引入后会自动进行初始化, 这个初始化和echarts的初始化是异步的, 如果echarts先初始化完成,
        // 加载BMap时, 会爆BMap.Overlay is not a constructor的错误, 所以等待一秒, 后续寻找BMap检测init完成的方法后替换这个延时
        setTimeout(() => {
          console.log("BMap init end");
          // @ts-ignore
          console.log("overlap: ", BMap.Overlay);
          setIsBMapReady(true);
        }, 1000);
      });
    } catch (e) {
      console.log(e);
    }
    const resizeFn = () => {
      console.log("sdfdsf", this);
    };
    window.addEventListener("resize", resizeFn);
    return () => window.removeEventListener("resize", resizeFn);
  }, []);

  useEffect(() => {
    if (otherExt && otherExt.holiday) {
      refetch();
    }
  }, [queryCondition, otherExt, airlinesQueryCondition]);

  const convertData = (source: Data[]): any[][] => {
    const loss: any[] = [];
    const equal: any[] = [];
    const grow: any[] = [];
    if (!source) {
      return [loss, equal, grow];
    }
    source.forEach((d: any) => {
      const v = Math.round(d.value);
      const geoCoord = [d.lng, d.lat, v, d.compareValue];
      const per = d.value / d.compareValue;
      if (per <= 0.95) {
        grow.push({
          name: d.cityName,
          value: geoCoord,
          itemStyle: { color: "#e84c3d" },
        });
      } else if (per >= 1.05) {
        loss.push({
          name: d.cityName,
          value: geoCoord,
          itemStyle: { color: "#26af5f" },
        });
      } else {
        equal.push({
          name: d.cityName,
          value: geoCoord,
          itemStyle: { color: "#ff0" },
        });
      }
    });
    return [loss, equal, grow];
  };
  const loadMapData = (source: Data[]) => {
    const [loss, equal, grow] = convertData(source);
    // 地图在全屏和取消全屏时, 由于页面的滚动会导致中心点偏移, 需要设置一个变量在切换全屏时记忆滚动距离, 取消全屏时再设置回来
    let scrollTopInBMap = 0;
    const optionTemp = {
      // backgroundColor: "#fff",
      // 加载 bmap 组件
      bmap: {
        // 百度地图中心经纬度
        center: [106.33066322374, 33.704018034923],
        // 百度地图缩放
        zoom: 5,
        // 是否开启拖拽缩放，可以只设置 'scale' 或者 'move'
        // roam: "move",
        roam: true,
        // // 百度地图的自定义样式，见 http://developer.baidu.com/map/jsdevelop-11.htm
        // mapStyle: { styleJson: [] },
      },
      legend: {
        orient: "vertical",
        y: "bottom",
        x: "right",
        data: [
          {
            name: getSharkText("config_page_inflow"),
            itemStyle: { color: "#e84c3d" },
          },
          {
            name: getSharkText("config_page_outflow"),
            itemStyle: { color: "#26af5f" },
          },
          {
            name: getSharkText("config_page_flat"),
            itemStyle: { color: "#ff0" },
          },
        ],
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: false },
          dataZoom: { show: false },
          restore: { show: false },
          saveAsImage: { show: false },
          myFull: {
            show: true,
            title: "Full Screen View",
            icon: `path://M64.46799 531.124935c34.111289 0 61.929376 25.535468 64.297327 58.814775l0.170664 4.586571-0.042666 217.062144L374.733526 
            573.428054c21.972876-22.399533 56.852149-25.023479 82.344952-8.255828l4.373242 3.157267 3.967917 3.370597c25.044812 23.03952 26.602112 
            61.225391 4.693236 87.251515l-4.159914 4.479907-241.850961 233.744464h218.171455c32.468657 0 59.284098 23.167517 63.785337 54.121539l0.51199 
            4.693235 0.14933 4.586571c0 33.940626-26.346118 60.969396-59.838753 63.252016l-4.607904 0.14933H64.46799c-34.132622 
            0-61.950709-25.535468-64.297327-58.814775L0 
            960.577321v-366.072373c0-35.476594 28.7994-63.380013 64.46799-63.380013zM959.510677 0c34.132622 0 61.950709 25.535468 64.297327 58.814775l0.170663 
            4.586571v366.072373c0 35.476594-28.7994 63.380013-64.46799 63.380013A63.913335 63.913335 0 0 1 895.981334 
            439.542843l-0.682653-4.7999-0.255994-5.290557V211.024937l-248.954814 241.274973c-23.03952 21.183559-58.025458 23.03952-82.259619 
            5.546552l-4.181247-3.263932-3.690589-3.498594a61.929376 61.929376 0 0 1-3.626592-85.395555l3.626592-3.775921L798.575363 126.760026l-216.870149 
            0.021333c-32.468657 0-59.284098-23.146184-63.785337-54.100207l-0.51199-4.693235-0.14933-4.586571c0-33.940626 26.346118-60.969396 
            59.838753-63.252016L581.705214         0h377.805463z`,
            iconStyle: {
              color: "blue",
              background: "#fff",
            },
            onclick: () => {
              const fullscreenElement =
                // 标准
                document.fullscreenElement ||
                // chrome内核
                document.webkitFullscreenElement ||
                // firefox
                document.mozFullScreenElement ||
                // IE;
                document.msFullscreenElement;
              const element = chartsRef.current.ele;
              if (!fullscreenElement) {
                const body = document.querySelector("html");
                if (body) {
                  scrollTopInBMap = body.scrollTop;
                  body.scrollTop = 0;
                }
                // IE 10 and less ActiveXObject
                if (window.ActiveXObject) {
                  // @ts-ignore
                  const WsShell = new ActiveXObject("WScript.Shell");
                  WsShell.SendKeys("{F11}");
                } else if (element.requestFullScreen) {
                  // HTML W3C proposed
                  element.requestFullScreen();
                } else if (element.msRequestFullscreen) {
                  // IE11
                  element.msRequestFullScreen();
                } else if (element.webkitRequestFullScreen) {
                  // Webkit (works in Safari5.1 and Chrome 15)
                  element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                  // Firefox (works in nightly)
                  element.mozRequestFullScreen();
                }
                // isFullScreen = true;
              } else {
                const body = document.querySelector("html");
                if (body) {
                  body.scrollTop = scrollTopInBMap;
                }
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                  document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                  document.msExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                  document.webkitExitFullscreen();
                }
                // isFullScreen = false
              }
            },
          },
        },
      },
      title: {
        show: false,
        text: "",
        left: 0,
      },
      tooltip: {
        trigger: "item",
        formatter: (item: any) => {
          const name = item.name;
          const dot = `<span class='dot' style='background:${item.color};width: 10px;height:10px'></span>`;
          const val = showNum(
            round(getProportionVal(item.value[3], item.value[2]), 2) * 100,
            "percentage"
          );

          return `${name}<br />${dot} ${getSharkText(
            "config_page_inflow_outflow"
          )}: ${val}`;
        },
      },
      series: [
        {
          id: "series1",
          // series名称
          name: getSharkText("config_page_outflow"),
          // series图表类型
          type: "scatter",
          coordinateSystem: "bmap",
          data: loss,
          minOpacity: 0.05,
          pointSize: 3,
          blurSize: 6,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
        },
        {
          name: getSharkText("config_page_outflow"),
          type: "effectScatter",
          coordinateSystem: "bmap",
          data: loss,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
          showEffectOn: "emphasis",
          rippleEffect: {
            brushType: "stroke",
          },
          hoverAnimation: true,
          itemStyle: {
            color: "#f4e925",
            shadowBlur: 10,
            shadowColor: "#333",
          },
          zlevel: 1,
        },
        {
          id: "series3",
          // series名称
          name: getSharkText("config_page_flat"),
          // series图表类型
          type: "scatter",
          coordinateSystem: "bmap",
          data: equal,
          minOpacity: 0.05,
          pointSize: 3,
          blurSize: 6,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
        },
        {
          name: getSharkText("config_page_flat"),
          type: "effectScatter",
          coordinateSystem: "bmap",
          data: equal,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
          showEffectOn: "emphasis",
          rippleEffect: {
            brushType: "stroke",
          },
          hoverAnimation: true,
          itemStyle: {
            color: "#f4e925",
            shadowBlur: 10,
            shadowColor: "#333",
          },
          zlevel: 1,
        },
        {
          id: "series5",
          name: "流入",
          type: "scatter",
          coordinateSystem: "bmap",
          data: grow,
          minOpacity: 0.05,
          pointSize: 3,
          blurSize: 6,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
        },
        {
          name: getSharkText("config_page_inflow"),
          type: "effectScatter",
          coordinateSystem: "bmap",
          data: grow,
          encode: {
            value: 2,
          },
          symbolSize: (val: number[]) => {
            const per = maxVal ? Math.sqrt(val[2]) / Math.sqrt(maxVal) : 1;
            return per > 1 ? maxPointSize : per * maxPointSize;
          },
          showEffectOn: "emphasis",
          rippleEffect: {
            brushType: "stroke",
          },
          hoverAnimation: true,
          itemStyle: {
            color: "#f4e925",
            shadowBlur: 10,
            shadowColor: "#333",
          },
          zlevel: 1,
        },
      ],
      animation: false,
    };
    setOption(optionTemp);
  };

  // useEffect(() => {
  //   if (ref && ref.current.chart && option) {
  //     ref.current.chart.setOption(option);
  //   }
  // }, [ref, option]);

  // hook data
  useEffect(() => {
    if (data && data.length && isBMapReady && isChartReady) {
      loadMapData(data);
    }
  }, [data, isBMapReady, isChartReady]);

  useEffect(() => {
    const reRender = () => {
      console.log("resize");
      if (chartsRef && chartsRef.current) {
        const optionTmp = chartsRef.current.getEchartsInstance().getOption();
        chartsRef.current.getEchartsInstance().clear();
        chartsRef.current.getEchartsInstance().setOption(optionTmp);
      }
    };
    const onResize = _.debounce(reRender, 500);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [chartsRef]);

  const chartReady = () => {
    console.log("ready.....");
    setIsChartReady(true);
  };

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (isEmpty(data)) {
    return <Empty style={{ marginTop: 32 }} />;
  }
  return (
    <div onClick={() => chartsRef && console.log(chartsRef.current)}>
      <Spin spinning={isLoading}>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: 32 }} />
        ) : isBMapReady ? (
          <EchartsReactBase
            className="airlines-map"
            ref={chartsRef}
            option={option}
            style={{ height: 537, width: "100%" }}
            onChartReady={chartReady}
          />
        ) : undefined}
      </Spin>
    </div>
  );
};

export default MapChart;

import React, { useEffect, useState } from "react";
import { Card, Empty, List, Popover, Radio, Row, Spin, Typography } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import useGlobal from "Store";
import _ from "lodash";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AirportCompareQueryExt } from "Interface";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ADVANTAGE_DESC } from "Page/AI/AirportCompare/AirportCompareCommon";

interface RankingProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  airportCompareQueryExt: AirportCompareQueryExt;
  cardCode: string;
  style?: React.CSSProperties;
}

const { Text } = Typography;

// 平均票价模块, 优劣势航线需要取反. 低价为优势, 高价为劣势;
const justifyType = (type: number, cardCode: string): number => {
  return cardCode !== "ow_price" ? type : (type + 1) % 2;
};

const CompareRanking: React.FC<RankingProps> = (props: RankingProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    airportCompareQueryExt,
    cardCode,
    style,
  } = props;
  const [type, setType] = useState<0 | 1>(0);

  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext: {
      ...airportCompareQueryExt,
      cardCode,
      type: justifyType(type, cardCode),
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query: queryCondition,
      ext: {
        ...airportCompareQueryExt,
        cardCode,
        type: justifyType(type, cardCode),
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [queryCondition, airportCompareQueryExt, type, cardCode]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const listItem = (item: any, idx: number) => (
    <List.Item className="ranking-item">
      <div className="ranking-badge">{idx + 1}</div>
      <div className="ranking-item-content">
        <Text>{item.cityName}</Text>
      </div>
    </List.Item>
  );

  const handleTypeChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        <Row>
          <Radio.Group onChange={handleTypeChange} value={type} id="rankTab">
            <Radio.Button value={0}>
              {getSharkText("key.advantageous_routes.name")}
              <Popover content={ADVANTAGE_DESC}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Popover>
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.disadvantageous_routes.name")}
              <Popover content={ADVANTAGE_DESC}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Popover>
            </Radio.Button>
          </Radio.Group>
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: 32 }} />
        ) : (
          <>
            <div
              className="ant-list-item ranking-item"
              style={{ margin: "5px 0 -15px" }}
            >
              <div style={{ width: 20 }}></div>
              <div className="ranking-item-content">
                <Text type="secondary">
                  {getSharkText("key.navigable_city.name")}
                </Text>
              </div>
              {/* <div className='ranking-item-value'><Text type='secondary'>{getSharkText("config_page_difference_rate")}</Text></div> */}
            </div>
            <List
              itemLayout="horizontal"
              dataSource={data}
              style={{ margin: "5px 0 -10px" }}
              renderItem={(item: any, index) => listItem(item, index)}
            />
          </>
        )}
      </Card>
    </Spin>
  );
};

export default CompareRanking;

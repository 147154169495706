import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Empty, List, Popover, Radio, Row, Spin, Typography } from "antd";
import useGlobal from "Store";
import { City, FlightArea, Tab } from "Interface";
import _ from "lodash";
import { getComparedPercentage, getComparePercentageVal } from "Utils";
import { PERCENT_VALUES } from "Constants";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { useFetch } from "Utils/useFetch";
import RankSortBtn from "../RankSortBtn";

const { Title, Text } = Typography;

interface IProps {
  title?: string;
  tab: Tab[];
  style?: Record<string, unknown>;
  queryUrl: string;
  moduleCode: string;
  area?: Record<string, unknown>;
  sortable?: boolean;
  selectItem?: (item: FlightArea | null) => void;
  setChartsType?: (type: number) => void;
  cardCode?: string;
  ext?: any;
  theme?: "radio" | "button" | "solid";
  itemRender?: (
    item: any,
    index: number,
    currentTab: Tab | null,
    other?: any
  ) => ReactNode;
  onTabChange?: (tab: Tab) => void;
}

const Ranking: React.FC<IProps> = (props: IProps) => {
  const {
    title = "",
    tab,
    style,
    queryUrl,
    moduleCode,
    area,
    sortable = false,
    selectItem,
    cardCode,
    setChartsType,
    ext: otherExt,
    theme = "button",
    itemRender,
    onTabChange,
  } = props;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [sort, setSort] = useState(1);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [curCityIndex, setCurCityIndex] = useState<number | null>(null);

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode: tab[0].chartTableCode,
    },
    query: queryCondition,
    ext: {
      area,
      type:
        currentTab && typeof currentTab.type !== "undefined"
          ? currentTab.type
          : 0,
      orderSort: 0,
      cardCode,
      ...otherExt,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (currentTab) {
      doFetch({
        head: {
          moduleCode,
          chartTableCode: currentTab.chartTableCode,
        },
        query: queryCondition,
        ext: {
          area,
          orderSort: sort,
          cardCode,
          type:
            currentTab && typeof currentTab.type !== "undefined"
              ? currentTab.type
              : 0,
          compare: currentTab.compare,
          ...otherExt,
        },
      });
    }
  }, [
    area,
    cardCode,
    currentTab,
    doFetch,
    moduleCode,
    otherExt,
    queryCondition,
    sort,
  ]);

  useEffect(() => {
    setCurrentTab(tab[0]);
  }, [tab]);

  useEffect(() => {
    setCurCityIndex(null);
    if (selectItem) {
      selectItem(null);
    }
    refetch();
  }, [refetch, selectItem]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleModeChange = (e: any) => {
    setCurrentTab(e.target.value);
    if (setChartsType && selectItem) {
      selectItem({
        departArea: {
          areaType: 0,
          areaCode: "",
          areaName: "",
        },
        arriveArea: {
          areaType: 0,
          areaCode: "",
          areaName: "",
        },
      });
      setChartsType(e.target.value.type);
    }
    if (onTabChange) {
      onTabChange(e.target.value);
    }
  };

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };

  const handleChooseItem = (value: any, idx: number) => {
    if (selectItem) {
      if (curCityIndex === idx) {
        setCurCityIndex(null);
        selectItem({
          departArea: {
            areaType: 0,
            areaCode: "",
            areaName: "",
          },
          arriveArea: {
            areaType: 0,
            areaCode: "",
            areaName: "",
          },
        });
      } else {
        setCurCityIndex(idx);
        selectItem({
          departArea: {
            areaType: 4,
            areaCode: value.dcityCode,
            areaName: value.dcityName,
          },
          arriveArea: {
            areaType: 4,
            areaCode: value.acityCode,
            areaName: value.acityName,
          },
        });
      }
    }
  };

  const airlineItem = (item: any, index: any) => {
    const isCompare = currentTab ? currentTab.compare : false;
    let computeValue;
    if (cardCode) {
      if (isCompare) {
        computeValue = isPercentVal
          ? getComparedPercentage(item.value, item.compareValue)
          : getComparePercentageVal(item.value, item.compareValue);
      } else {
        computeValue = item.value;
      }
    }
    return (
      <List.Item className="ranking-item">
        <div className="ranking-badge">{++index}</div>
        <div className="ranking-item-content">
          <Text>{item.cityName}</Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            <ValueNum
              type={isPercentVal || isCompare ? "percentage" : "num"}
              value={computeValue}
            />
          </Text>
        </div>
      </List.Item>
    );
  };

  const searchItem = (item: any, index: any) => {
    const computeValue = _.round(item.value);

    return (
      <List.Item
        className={`ranking-item optional ${
          index === curCityIndex ? "cur" : ""
        }`}
        onClick={() => handleChooseItem(item, index)}
      >
        <div className="ranking-badge">{index + 1}</div>
        <div className="ranking-item-content">
          <Text>
            {item.dcityName} - {item.acityName}
          </Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            <ValueNum type="num" value={computeValue} />
          </Text>
        </div>
      </List.Item>
    );
  };

  const planItem = (item: any, index: any) => (
    <List.Item className="ranking-item">
      <div className="ranking-badge">{index + 1}</div>
      <div className="ranking-item-content">
        <Text>
          {item.dportName} - {item.aportName}
        </Text>
      </div>
      <div className="ranking-item-value">
        <Text>
          <ValueNum type="num" value={item.value} />
        </Text>
      </div>
    </List.Item>
  );

  const listItem = (item: any, index: number) => {
    if (itemRender) {
      return itemRender(item, index, currentTab);
    }
    switch (moduleCode) {
      case "search_index_airport":
        return searchItem(item, index);
      case "flight_plan_airport":
        return planItem(item, index);
      default:
        return airlineItem(item, index);
    }
  };

  const genHeaderItem = (item: Tab, index: number) => {
    const tip = item.titleTips ? (
      <Popover content={item.titleTips}>
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Popover>
    ) : undefined;
    switch (theme) {
      case "radio":
        return (
          <Radio value={item} key={index}>
            {item.title}
            {tip}
          </Radio>
        );
      case "button":
      case "solid":
        // theme的solid样式在Radio外层添加buttonStyle
        return (
          <Radio.Button value={item} key={index}>
            {item.title}
            {tip}
          </Radio.Button>
        );
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        <Row justify="space-between" data-ut-id="ranking-tab">
          <Radio.Group
            onChange={handleModeChange}
            value={currentTab}
            id="rankTab"
            buttonStyle={theme === "solid" ? "solid" : "outline"}
          >
            {tab.map((item: Tab, index: number) => genHeaderItem(item, index))}
          </Radio.Group>
          {sortable && (
            <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
          )}
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            style={{ margin: "5px 0 -10px" }}
            renderItem={(item: City, index) => listItem(item, index)}
          />
        )}
      </Card>
    </Spin>
  );
};

export default Ranking;

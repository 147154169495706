import React, { useCallback, useEffect } from "react";
import { Card, Empty } from "antd";
import useGlobal from "Store";
import { isAirportMode, showNum, useFetch } from "Utils";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import EchartsReactBase from "Components/EchartsReactBase";

interface PieChartsProps {
  server?: string;
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: React.CSSProperties;
  ext: Record<string, unknown>;
  chartTitle: string;
}

const PieCharts: React.FC<PieChartsProps> = (props: PieChartsProps) => {
  const {
    server,
    queryUrl,
    moduleCode,
    chartTableCode,
    height = 500,
    style,
    ext,
    chartTitle,
  } = props;

  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: server || getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query,
      ext,
    });
  }, [doFetch, ext, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (!data) {
    return <Empty />;
  }

  const option = {
    title: {
      text: chartTitle,
      textStyle: {
        fontWeight: "normal",
        fontSize: 14,
        color: "#333",
      },
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {d}%",
    },
    legend: {
      bottom: 0,
      icon: "circle",
      itemGap: 20,
      itemWidth: 8,
      itemHeight: 8,
      textStyle: {
        color: "#666",
      },
    },
    dataset: {
      source: data,
    },
    series: [
      {
        type: "pie",
        radius: [0, "50%"],
        center: ["50%", "40%"],
        label: {
          show: true,
          formatter: (r: any) => {
            return `${r.name} : ${showNum(r.percent, "percentage", 2)}`;
          },
        },
        selectedMode: true,
      },
    ],
  };
  return (
    <Card style={style}>
      <EchartsReactBase
        className="pie-charts"
        option={option}
        style={{ height }}
        theme="default"
        showLoading={isLoading}
        loadingOption={{
          textColor: "#999",
          color: "#1890ff",
        }}
      />
    </Card>
  );
};

export default PieCharts;

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Area, ISeries, SystemType } from "Interface";
import useGlobal from "Store";
import { Card, Col, Divider, Popover, Row, Spin } from "antd";
import Refetch from "Components/Refetch";
import SimplePictorialBarCharts from "../SimplePictorialBarCharts";
import SimpleLineCharts from "../SimpleLineCharts";
import SimpleTreeMapCharts from "../SimpleTreeMapCharts";
import SimplePieCharts, { genChartData } from "../SimplePieCharts";
import moment from "moment";
import { getServer } from "Service/server";
import {
  BarChartOutlined,
  GlobalOutlined,
  LineChartOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import SimpleBarCharts from "../SimpleBarCharts";
import {
  UserProfileChart,
  UserProfileTag,
} from "@ctrip/flt-bi-flightai-airlines";
import { defaultArea, IQuery } from "../../common";
import SimpleCompareBarCharts from "../SimpleCompareBarCharts";
import MapChart from "../MapChart";
import usePageContext from "../../UserProfileContext";
import { cloneDeep, round, uniqBy } from "lodash";
import { useFetch } from "Utils/useFetch";
import { isAirportMode, XLSX, rowToColumn } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { Config } from "../../panelConfig";
import { DATE_FORMAT } from "Constants";
interface BlockProps {
  queryUrl: string;
  moduleCode: string;
  title: string;
  subtitle?: string;
  chartTableCode: string;
  chartType: string[];
  allowTypes: string[];
  seriesConfig: ISeries[];
  chartConfig: any;
  dataSort?: (a: any, b: any) => number;
  tagCode: string;
  queryExt: IQuery;
  config?: Config;
}

const getRequestType = (v: string) => {
  switch (v) {
    case "bar":
    case "pie":
      return 0;
    case "line":
      return 1;
    case "download":
    default:
      return 0;
  }
};

const UserProfileBlock: React.FC<BlockProps> = (props: BlockProps) => {
  const {
    queryUrl,
    moduleCode,
    title,
    subtitle,
    chartTableCode,
    chartType,
    allowTypes,
    seriesConfig,
    chartConfig,
    dataSort,
    tagCode,
    queryExt,
    config,
  } = props;
  const [globalState] = useGlobal();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const [context] = usePageContext();
  const { tagList } = context;
  const query =
    systemType === SystemType.airport ? queryCondition : airlinesQueryCondition;
  const [curType, setCurType] = useReducer(
    (state: any, v: any) => {
      let chart = v;
      const idx = allowTypes.indexOf(v);
      if (chartType.length && idx > -1 && chartType[idx]) {
        chart = chartType[idx];
      }
      return {
        curType: v,
        curChartType: chart,
      };
    },
    {
      curType: allowTypes[0],
      curChartType: chartType.length ? chartType[0] : allowTypes[0],
    }
  );
  const [area, setArea] = useState<Area>(defaultArea);

  const [{ data, isLoading, error }, doFetch] = useFetch<UserProfileChart[]>({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    lazey: true,
    debugId: `${tagCode}_current`,
  });

  const [{ data: cityData }, fetchCity] = useFetch<UserProfileChart[]>({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    lazey: true,
    debugId: `city_ranking_current`,
  });

  const [{ data: dataCompare, isLoading: isLoadingCompare }, fetchCompare] =
    useFetch<UserProfileChart[]>({
      server: getServer(systemType),
      url: queryUrl,
      defaultValue: [],
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
      lazey: true,
      debugId: `${tagCode}_compare`,
    });

  const refetch = useRefFunc(() => {
    const queryExtCP = cloneDeep(queryExt);
    // if (systemType === SystemType.airport) {
    //   if (queryExtCP.current.filter && queryCondition.originalAirport) {
    //     queryExtCP.current.filter.departArea = queryCondition.originalAirport;
    //   }
    // }
    if (
      (isAirportMode(systemType) &&
        !queryExtCP.current.filter.departArea.areaCode) ||
      (!isAirportMode(systemType) && !queryExt.current.query) ||
      !queryExt.loaded
    ) {
      return;
    }
    doFetch({
      query: queryExtCP.current.query,
      ext: {
        ...queryExtCP.current,
        tagCode,
        area,
        type: getRequestType(curType.curType),
      },
    });
    if (config?.isMap) {
      fetchCity({
        query: queryExtCP.current.query,
        ext: {
          ...queryExtCP.current,
          tagCode,
          area,
          type: 3,
        },
      });
    }
    if (queryExt.isCompare && queryExt.compare) {
      fetchCompare({
        query: queryExt.compare.query,
        ext: {
          ...queryExt.compare,
          tagCode,
          area,
          type: getRequestType(curType.curType),
        },
      });
    }
  });

  // #region 可用类型变更时, 更新当前类型
  useEffect(() => {
    if (!curType || !allowTypes.includes(curType.curType)) {
      setCurType(allowTypes[0]);
    }
  }, [allowTypes, curType]);

  const filteredData = useMemo(
    () => data.filter((d) => d.tagCode !== "-1"),
    [data]
  );
  const filteredCompare = useMemo(
    () => dataCompare.filter((d) => d.tagCode !== "-1"),
    [dataCompare]
  );
  // #endregion
  // if (_.isEmpty(data)) { return <Empty /> }
  if (filteredData) {
    // 过滤未知数据
    if (dataSort) {
      filteredData.sort(dataSort);
    } else {
      filteredData.sort((a, b) => {
        if (!a.tagCode || !b.tagCode) {
          return 0;
        } else {
          return a.tagCode.localeCompare(b.tagCode);
        }
      });
    }
  }

  const handleDownload = useCallback(() => {
    const downloadExcel = (downloadData: any, filename: string) => {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(downloadData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const s2ab = (s: any) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };
      const blob = new Blob([s2ab(excelBuffer)], {
        type: "application/octet-stream",
      });
      // 创建下载链接
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };
    console.log("curType.curType", curType.curType);
    if (filteredData.length) {
      if (curType.curType === "pie" || curType.curType === "bar") {
        const chartData = genChartData(filteredData, seriesConfig);
        console.log("chartData", chartData);
        const mappedData = chartData.map((item) => ({
          [title]: item.name,
          百分比: round(item.value * 100, 2) + "%",
        }));
        downloadExcel(mappedData, title + ".xlsx");
      } else {
        const tagNames = uniqBy(filteredData, (d) => d.tagCode).map(
          (t) => t.tagName
        );
        console.log("tagsName", tagNames);
        const chartData = rowToColumn(filteredData, ["day"], "tagCode", [
          "value",
          "compareValue",
        ]);
        const formattedData1 = chartData.map((t: any) => {
          return {
            ...t,
            day: moment(t.day).format(DATE_FORMAT),
          };
        });
        console.log("formattedData1", formattedData1);
        // 将所有数据转为百分比
        chartData.forEach((t: any) => {
          let totalValue = 0;
          let totalCompare = 0;
          Object.keys(t).forEach((key) => {
            if (key.indexOf("value") >= 0) {
              totalValue += t[key] || 0;
            }
            if (key.indexOf("compareValue") >= 0) {
              totalCompare += t[key] || 0;
            }
          });
          chartData.sort((a: any, b: any) => {
            return moment(a.day).valueOf() - moment(b.day).valueOf();
          });
          Object.keys(t).forEach((key) => {
            if (key.indexOf("value") >= 0 && totalValue) {
              t[key] = t[key] / totalValue;
            }
            if (key.indexOf("compareValue") >= 0 && totalCompare) {
              t[key] = t[key] / totalCompare;
            }
          });
        });
        const getProcessedData = (processData: any, names: any[]) => {
          return processData.map((item: any) => {
            const day = moment(item.day).format(DATE_FORMAT);
            // 创建一个对象，用于存储与 tagNames 对应的值
            const valueObject = names.reduce((obj, tag, index) => {
              const valueKey = `${index + 1}value`;
              obj[tag] = round(item[valueKey] * 100, 2) + "%" || 0;
              return obj;
            }, {});
            // 返回包含日期和值的对象
            return { 日期: day, ...valueObject };
          });
        };

        const processedData = getProcessedData(chartData, tagNames);
        console.log("processedData", processedData);
        downloadExcel(processedData, title + ".xlsx");
      }
    }
  }, [filteredData, curType.curType, seriesConfig, title]);

  useEffect(() => {
    refetch();
  }, [queryExt, refetch, area, curType.curType]);

  const extraContent = useMemo(() => {
    if (queryExt.isCompare && !config?.isMap) {
      return undefined;
    }
    return (
      <div>
        {allowTypes.map((a) => {
          let tmp;
          switch (a) {
            case "bar":
              tmp = <BarChartOutlined style={{ color: "#1890ff" }} />;
              break;
            case "pie":
              tmp = <PieChartOutlined style={{ color: "#1890ff" }} />;
              break;
            case "line":
              tmp = <LineChartOutlined style={{ color: "#1890ff" }} />;
              break;
            case "map":
              tmp = <GlobalOutlined style={{ color: "#1890ff" }} />;
              break;
            case "download":
              tmp = <DownloadOutlined style={{ color: "#1890ff" }} />;
              break;
          }
          if (tmp) {
            return (
              <span
                key={a}
                style={{ marginRight: 5, cursor: "pointer" }}
                onClick={() => {
                  if (a === "download") {
                    handleDownload();
                  } else {
                    setCurType(a);
                    console.log("curType.curType", curType.curType);
                    if (a === "map") {
                      setArea(defaultArea);
                    }
                  }
                }}
              >
                {tmp}
              </span>
            );
          }
          return undefined;
        })}
      </div>
    );
  }, [
    allowTypes,
    config?.isMap,
    curType.curType,
    handleDownload,
    queryExt.isCompare,
  ]);

  const getTips = useCallback((tag: UserProfileTag): ReactNode => {
    if (tag.tagTip || tag.children?.find((t) => t.tagTip)) {
      return (
        <>
          {tag.tagTip && (
            <>
              <div>
                {tag.tagName} : {tag.tagTip}
              </div>
            </>
          )}
          {tag.children && tag.children.find((t) => t.tagTip) && (
            <>
              <Divider />
              <ul>
                {tag.children.map(
                  (t) =>
                    t.tagTip && (
                      <li key={t.tagTip}>
                        {t.tagName}: {t.tagTip}
                      </li>
                    )
                )}
              </ul>
            </>
          )}
        </>
      );
    }
    return undefined;
  }, []);

  const titleContent = useMemo(() => {
    const tagObj = tagList?.find((t) => t.tagCode === tagCode);
    let tips: ReactNode = "";
    if (tagObj) {
      if (config?.tagInfo) {
        tips = config.tagInfo;
      } else if (tagObj.tagTip || tagObj.children?.find((c) => c.tagTip)) {
        tips = getTips(tagObj);
      }
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          {title}
          {tips && (
            <Popover content={tips} overlayStyle={{ maxWidth: 450 }}>
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          )}
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div
          className="sub-card-title"
          style={{ fontSize: "8px", color: "#A9A9A9" }}
        >
          {subtitle}
        </div>
      </div>
    );
  }, [config?.tagInfo, getTips, subtitle, tagCode, tagList, title]);

  const getChart = useRefFunc(() => {
    if (curType.curChartType === "map") {
      return (
        <MapChart
          data={filteredData}
          cityData={cityData}
          isLoading={isLoading}
          setArea={setArea}
          area={area}
          queryExt={queryExt}
        />
      );
    }
    // 地图不重新加载
    if (isLoading || isLoadingCompare) {
      return <Spin />;
    }
    if (queryExt.isCompare) {
      return (
        <SimpleCompareBarCharts
          data={filteredData}
          compareData={filteredCompare}
          loading={isLoading}
        />
      );
    }
    switch (curType.curChartType) {
      case "pictorialBar":
        return (
          <SimplePictorialBarCharts
            data={filteredData}
            loading={isLoading}
            seriesConfig={seriesConfig}
          ></SimplePictorialBarCharts>
        );
        break;
      case "treemap":
        return (
          <SimpleTreeMapCharts
            data={filteredData}
            loading={isLoading}
          ></SimpleTreeMapCharts>
        );
        break;
      case "pie":
        return (
          <SimplePieCharts
            data={filteredData}
            loading={isLoading}
            seriesConfig={seriesConfig}
            chartConfig={chartConfig}
          ></SimplePieCharts>
        );
        break;
      case "bar":
        return (
          <SimpleBarCharts
            data={filteredData}
            loading={isLoading}
            seriesConfig={seriesConfig}
            chartConfig={chartConfig}
          ></SimpleBarCharts>
        );
        break;
      default:
        return (
          <SimpleLineCharts
            data={filteredData}
            loading={isLoading}
            chartConfig={chartConfig}
          ></SimpleLineCharts>
        );
        break;
    }
  });

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Card type="inner" title={titleContent} extra={extraContent}>
      <Row>
        <Col xs={24}>{getChart()}</Col>
      </Row>
    </Card>
  );
};

export default UserProfileBlock;

import React, { useEffect, useState } from "react";
import { FlightArea } from "Interface";
import {
  openDownloadDialog,
  showRawNum,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _ from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  Input,
  Popover,
  Radio,
  Row,
  Table,
  Typography,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import { getServer } from "Service/server";
import { flightPlanTypes } from "Page/AI/FlightPlan/FlightPlanAirlineCommon";

interface AirlineTableProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  airlinesOnsaleAirlineExt: FlightArea;
}

const { Text } = Typography;

const AirlinesPlanTable: React.FC<AirlineTableProps> = (
  props: AirlineTableProps
) => {
  const { moduleCode, chartTableCode, airlinesOnsaleAirlineExt, queryUrl } =
    props;
  const [type, setType] = useState(0);
  const [isShowAllAirlines, setIsShowAllAirlines] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState<string>("");
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const query = airlinesQueryCondition;
  const ext = { ...airlinesOnsaleAirlineExt, type };
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [airlinesQueryCondition, airlinesOnsaleAirlineExt, type]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const flightData = data.filter(
        (item: any) =>
          item.airlines !== getSharkText("key.route_flightschedule.checkbox")
      );
      if (flightData) {
        const resultData = flightData.map((item: any, idx: number) => {
          let airline = data.find(
            (d: any) =>
              d.dcityCode === item.dcityCode &&
              d.acityCode === item.acityCode &&
              d.takeoffdate === item.takeoffdate &&
              d.airlines === getSharkText("key.route_flightschedule.checkbox")
          );
          if (airline) {
            airline.key = (idx + 1) * 2;
          } else {
            airline = {
              key: (idx + 1) * 2,
            };
          }
          item.compare = airline;
          item.key = idx;
          return item;
        });
        setTableData(resultData);
      }
    } else {
      setTableData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const getFilterData = () => {
    const cloneData = _.cloneDeep(tableData);
    const filter = cloneData.filter((item: any) => {
      let isPass = false;
      for (const key of ["dcityName", "acityName", "takeoffdate", "airlines"]) {
        if (item[key] && item[key].includes(searchText.toUpperCase())) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = getFilterData();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const columns = [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
      render: (val: string, record: any) =>
        `${record.dcityName}(${record.dcityCode})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
      render: (val: string, record: any) =>
        `${record.acityName}(${record.acityCode})`,
    },
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      sorter: (a: any, b: any) =>
        new Date(a.takeoffdate).getTime() - new Date(b.takeoffdate).getTime(),
    },
    {
      title: getSharkText("key.flights_carried.name"),
      dataIndex: "airlines",
      key: "airlines",
    },
    {
      title: () => (
        <span>{`${flightPlanTypes[type]}${getSharkText(
          "key.flights_count.flightplan"
        )}`}</span>
      ),
      dataIndex: "flightCount",
      key: "flightCount",
      sorter: (a: any, b: any) => a.flightCount - b.flightCount,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: () => (
        <>
          <span>{`${flightPlanTypes[type]}${getSharkText(
            "key.seats_count.flightplan"
          )}`}</span>
          &nbsp;
          <Popover content={getSharkText("key.estimated_based_on_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      sorter: (a: any, b: any) => a.transportCapacity - b.transportCapacity,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    {
      title: `${flightPlanTypes[type]}${getSharkText("key.date.flightplan")}`,
      dataIndex: "effectdate",
      key: "effectdate",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.effectdate).getTime() - new Date(b.effectdate).getTime(),
    },
  ];

  const subColumns = [
    {
      title: "",
      dataIndex: "dcityName",
      key: "dcityName",
      render: () => <span />,
    },
    {
      title: "",
      dataIndex: "acityName",
      key: "acityName",
      render: () => <span />,
    },
    {
      title: "",
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      render: () => <span />,
    },
    {
      title: "",
      dataIndex: "airlines",
      key: "airlines",
      render: () => (
        <span>{getSharkText("key.route_flightschedule.checkbox")}</span>
      ),
    },
    {
      title: "",
      dataIndex: "flightCount",
      key: "flightCount",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "",
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    {
      title: "",
      dataIndex: "effectdate",
      key: "effectdate",
      render: () => <span>-</span>,
    },
  ];

  const expandedRowRender = (record: any) => {
    const expandedSource: any[] = [record.compare];

    return (
      <Table
        showSorterTooltip={false}
        tableLayout="fixed"
        dataSource={expandedSource}
        columns={subColumns}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const handleChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  const handleCheck = (e: CheckboxChangeEvent) => {
    setIsShowAllAirlines(e.target.checked);
  };

  const handleDownload = () => {
    const sheetData1: any = [];
    const sheetData2: any = [];
    filterData.forEach((item: any) => {
      const obj1: any = {
        [getSharkText(
          "key.departure.name"
        )]: `${item.dcityName}(${item.dcityCode})`,
        [getSharkText(
          "key.arrival.name"
        )]: `${item.acityName}(${item.acityCode})`,
        [getSharkText("charts.date_of_departure")]: item.takeoffdate,
        [getSharkText("key.flights_carried.name")]: item.airlines,
      };
      const obj2: any = _.cloneDeep(obj1);
      const typeName = flightPlanTypes[type];
      obj1[`${typeName}${getSharkText("key.flights_count.flightplan")}`] =
        showRawNum(item.flightCount);
      obj1[`${typeName}${getSharkText("config_page_seat_count_estimate")}`] =
        showRawNum(item.transportCapacity);
      obj1[`${typeName}${getSharkText("key.date.flightplan")}`] =
        item.effectdate;
      obj2[`${typeName}{getSharkText("key.flights_count.flightplan")}`] =
        showRawNum(item.compare.flightCount);
      obj2[`${typeName}{getSharkText("config_page_seat_count_estimate")}`] =
        showRawNum(item.compare.transportCapacity);
      sheetData1.push(obj1);
      sheetData2.push(obj2);
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const sheet2 = XLSX.utils.json_to_sheet(sheetData2);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("config_page_flight")
    );
    XLSX.utils.book_append_sheet(
      wb,
      sheet2,
      getSharkText("key.route_flightschedule.checkbox")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("menu_flight_plan_airlines") + ".xlsx"
    );
  };

  return (
    <>
      <Row gutter={16} align="middle" className="airlines_filght_plan_filter">
        <Col id="flightPlanTableOverall">
          <Checkbox checked={isShowAllAirlines} onChange={handleCheck}>
            {getSharkText("key.route_flightschedule.checkbox")}
          </Checkbox>
        </Col>
        <Col id="flightPlanTableType">
          <Radio.Group value={type} onChange={handleChange}>
            {flightPlanTypes.map((item, idx) => (
              <Radio.Button key={idx} value={idx}>
                {item}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
        <Col style={{ width: 350 }}>
          <Input.Search
            value={searchText}
            placeholder={getSharkText("key.input_to_search_relevant")}
            onChange={handleSearch}
          />
        </Col>
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Table
          showSorterTooltip={false}
          className="airlines_filght_plan_table"
          loading={isLoading}
          tableLayout="fixed"
          dataSource={filterData}
          // @ts-ignore
          columns={columns}
          defaultExpandAllRows={true}
          expandIconAsCell={false}
          expandIconColumnIndex={-1}
          expandedRowRender={isShowAllAirlines ? expandedRowRender : undefined}
          expandedRowKeys={filterData.map((item: any) => item.key)}
          scroll={{ y: 540 }}
        />
      </Row>
    </>
  );
};

export default AirlinesPlanTable;

// Created by xh_zhu on 2020-12-17

import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import CustomTable from "./CustomTable";
import useGlobal from "Store";
import { Empty, Spin } from "antd";
import Refetch from "Components/Refetch";
import { isEmpty } from "lodash";

interface IDashTableProps {
  server: string;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  ext?: any;
  valControl: string[];
  queryExt?: any;
  defaultAirport?: string;
}

const DTable = (props: IDashTableProps, ref: any) => {
  const {
    server,
    queryUrl,
    moduleCode,
    chartTableCode,
    valControl,
    queryExt = {},
    defaultAirport,
  } = props;
  const [globalState] = useGlobal();
  const tableRef = useRef<any>();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const queryWithExt = useMemo(
    () => ({ ...query, ...queryExt }),
    [query, queryExt]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    lazey: true,
  });
  useImperativeHandle(ref, () => ({
    download: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.download();
      }
    },
  }));

  const refetch = useCallback(() => {
    if (
      queryWithExt.startDate &&
      queryWithExt.endDate &&
      queryWithExt.wow !== undefined &&
      queryWithExt.yoy !== undefined
    ) {
      doFetch({ query: queryWithExt, ext: { random: Math.random() } });
    }
  }, [doFetch, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (isEmpty(data)) {
    return <Empty />;
  }
  // 给数据上ID
  data.forEach((d: any, i: number) => {
    if (!d.key && !d.rowKey) {
      d.key = `id${i}`;
    }
  });

  return (
    <CustomTable
      ref={tableRef}
      dataSource={data}
      searchkeys={["airport"]}
      searchPlaceholder={getSharkText("key.input_to_search")}
      valControl={valControl}
      queryExt={queryExt}
      defaultAirport={defaultAirport}
    ></CustomTable>
  );
};
const DashTable = React.forwardRef(DTable);
export default DashTable;

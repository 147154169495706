// #region imports
import React, {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Area, IDownloadHeaderPro, QueryCondition } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";

import { Empty, message, Modal, Spin, TablePaginationConfig } from "antd";
import { DATE_FORMAT } from "Constants";
import Refetch from "Components/Refetch";
import CustomTable from "./CustomTable";
import moment from "moment";

import "./index.scss";
import useRefFunc from "Utils/useRefFunc";
import { getColumns } from "./columns";
import { ListToolBarTabs } from "@ant-design/pro-table/es/components/ListToolBar";
import SvgIcon from "Components/SvgIcon";
import {
  AirportRouteParam,
  FlightInfoDetailWithKey,
  OdtConfig,
} from "../../interface";
import { MODULE_CODE } from "../../Constants";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import {
  ACITYCODE,
  ACITYNAME,
  AHEAD_DAY,
  APORT,
  APROVINCENAME,
  AREGIONNAME,
  ARR_AIRPORTNAME,
  CANCEL_RATE,
  CONFIG_KEY,
  CQ_CROWD_DEGREE_FINAL_PRED_50,
  CURRENT_AVGPRICE,
  customCols,
  D,
  DATASET_ID,
  DCITYCODE,
  DCITYNAME,
  DEP_AIRPORTNAME,
  DPORT,
  DPROVINCENAME,
  DREGIONNAME,
  FLIGHTNO,
  IS_SINGLEFLIGHT,
  PRICE_DIFF,
  PROB,
  SEATS,
  SUBSIDY_AMOUNT_FIXED,
  SUBSIDY_NUM_FIXED,
  SUBSIDY_PRICE,
  TAKEOFFDATE,
  TAKEOFFTIME,
  UDF_COMPETE_PRICE,
  UDF_CONFIG,
  UDF_PRICE,
  UDF_SUBSIDY_LOCAL,
  UDF_SUBSIDY_NON_LOCAL,
} from "./cols";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import {
  Dimension,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import DetailTable from "../DetailTable";
import { useDebounce } from "Utils/useDebounce";

interface ITabItem {
  name: string;
  label: ReactNode;
  key: string;
  disabled?: boolean;
  value: string | "all";
}

const D_AREA_NAME: { [key: number]: string } = {
  2: DREGIONNAME,
  3: DPROVINCENAME,
  4: DCITYCODE,
  5: DPORT,
};

const A_AREA_NAME: { [key: number]: string } = {
  2: AREGIONNAME,
  3: APROVINCENAME,
  4: ACITYCODE,
  5: APORT,
};

const defaultTabs = [
  {
    name: getSharkText("config_page_all"),
    // label: getSharkText("config_page_all"),
    label: (
      <span style={{ paddingRight: 10, borderRight: "1px solid #eee" }}>
        <SvgIcon name="zhiding" style={{ position: "relative", top: 4 }} />
      </span>
    ),
    key: "all",
    value: "all",
  },
];
/** 以下AreaType使用code, 其余使用name匹配 */
const CODE_TYPES = [2, 4, 5];
interface FilterGenerator {
  depart: 0 | 1;
  arrive: 0 | 1;
  startDate: string;
  endDate: string;
  /** 选中的机场 */
  port: string;
  departArea: Area;
  arriveArea: Area;
}

export const getFilters = (param: FilterGenerator): StandardFilter[] => {
  const { depart, arrive, startDate, endDate, departArea, arriveArea } = param;
  const oriFilters: StandardFilter[] = [];
  const daArr: StandardFilter[] = [
    ...(departArea?.areaCode
      ? [
          {
            in: {
              field: `dimension.${D_AREA_NAME[departArea.areaType]}`,
              values: [
                CODE_TYPES.includes(departArea.areaType)
                  ? departArea.areaCode
                  : departArea.areaName,
              ],
            },
          },
        ]
      : []),
    ...(arriveArea?.areaCode
      ? [
          {
            in: {
              field: `dimension.${A_AREA_NAME[arriveArea.areaType]}`,
              values: [
                CODE_TYPES.includes(arriveArea.areaType)
                  ? arriveArea.areaCode
                  : arriveArea.areaName,
              ],
            },
          },
        ]
      : []),
  ];
  const adArr: StandardFilter[] = [
    ...(arriveArea?.areaCode
      ? [
          {
            in: {
              field: `dimension.${D_AREA_NAME[arriveArea.areaType]}`,
              values: [
                CODE_TYPES.includes(arriveArea.areaType)
                  ? arriveArea.areaCode
                  : arriveArea.areaName,
              ],
            },
          },
        ]
      : []),
    ...(departArea.areaCode
      ? [
          {
            in: {
              field: `dimension.${A_AREA_NAME[departArea.areaType]}`,
              values: [
                CODE_TYPES.includes(departArea.areaType)
                  ? departArea.areaCode
                  : departArea.areaName,
              ],
            },
          },
        ]
      : []),
  ];
  if (depart && arrive) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: { filters: daArr },
          },
          {
            and: { filters: adArr },
          },
        ],
      },
    });
  } else if (depart) {
    daArr.forEach((f) => oriFilters.push(f));
  } else if (arrive) {
    adArr.forEach((f) => oriFilters.push(f));
  }
  if (startDate && endDate) {
    oriFilters.push({
      range: {
        field: `dimension.${TAKEOFFDATE}`,
        strRange: { lower: startDate, upper: endDate },
      },
    });
  }
  return oriFilters;
};

interface AirlineTableProps {
  queryExt: AirportRouteParam | null;
  query: QueryCondition;
  isDemo: boolean;
}
export interface PCalendarHandler {
  refresh: () => void;
}

const PCalendar = (props: AirlineTableProps, ref: Ref<PCalendarHandler>) => {
  const { queryExt, query } = props;
  const { startDate, endDate, airport, departure, arrive } = query;

  /** 因为ProTable的headerTitle中直接使用tabs会有闪跳问题, 为了解决此问题, 需要计算table的width来固定tabs的大小, 防止闪跳 */
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [dataWithKey, setDataWithKey] = useState<FlightInfoDetailWithKey[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [pageParam, setPageParam] = useState<{ size: number; pageNum: number }>(
    { size: 20, pageNum: 1 }
  );
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<StandardResponse>();
  const services = useServices();
  const [inited, setInited] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // #region 表格查询
  const [{ error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      console.log("res: ", res);
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as FlightInfoDetailWithKey[];
      console.log("map: ", map);
      const tmpWithKey = map.map((d) => {
        return {
          ...d,
          // config_key: d[CONFIG_KEY] || "",
          key: `${d.d}_${d.takeoffdate}_${d.flightno}_${d.dep_airportname}_${d.arr_airportname}`,
        };
      });
      // #region 排序数据
      tmpWithKey.sort((a, b) => {
        if (a.d && b.d && a.d !== b.d) {
          return b.d.localeCompare(a.d);
        }
        if (a.takeoffdate && b.takeoffdate && a.takeoffdate !== b.takeoffdate) {
          return a.takeoffdate.localeCompare(b.takeoffdate);
        }
        if (a.flightno && b.flightno && a.flightno !== b.flightno) {
          return a.flightno.localeCompare(b.flightno);
        }
        if (
          a.dep_airportname &&
          b.dep_airportname &&
          a.dep_airportname !== b.dep_airportname
        ) {
          return a.dep_airportname.localeCompare(b.dep_airportname);
        }
        if (
          a.arr_airportname &&
          b.arr_airportname &&
          a.arr_airportname !== b.arr_airportname
        ) {
          return a.arr_airportname.localeCompare(b.arr_airportname);
        }
        return 0;
      });
      // #endregion
      setDataWithKey(tmpWithKey);
      setResData(res);
    },
    onFinish: () => {
      setIsLoading(false);
    },
  });
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
            setInited(true);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const cols = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const dimensions: Dimension[] = useMemo(
    () => [
      {
        columnName: CONFIG_KEY,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: D,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: TAKEOFFDATE,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: DPORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: APORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: DCITYNAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: ACITYNAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: DEP_AIRPORTNAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: ARR_AIRPORTNAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: FLIGHTNO,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: AHEAD_DAY,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: TAKEOFFTIME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: IS_SINGLEFLIGHT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ],
    []
  );

  const measures: Measure[] = useMemo(
    () => [
      {
        id: SEATS,
        columnName: SEATS,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: CQ_CROWD_DEGREE_FINAL_PRED_50,
        columnName: CQ_CROWD_DEGREE_FINAL_PRED_50,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: CANCEL_RATE,
        columnName: CANCEL_RATE,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: PROB,
        columnName: PROB,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: CURRENT_AVGPRICE,
        columnName: CURRENT_AVGPRICE,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: PRICE_DIFF,
        columnName: PRICE_DIFF,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: SUBSIDY_PRICE,
        columnName: SUBSIDY_PRICE,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: SUBSIDY_NUM_FIXED,
        columnName: SUBSIDY_NUM_FIXED,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: SUBSIDY_AMOUNT_FIXED,
        columnName: SUBSIDY_AMOUNT_FIXED,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: UDF_PRICE,
        columnName: UDF_PRICE,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: UDF_COMPETE_PRICE,
        columnName: UDF_COMPETE_PRICE,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: UDF_CONFIG,
        columnName: UDF_CONFIG,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: UDF_SUBSIDY_NON_LOCAL,
        columnName: UDF_SUBSIDY_NON_LOCAL,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: UDF_SUBSIDY_LOCAL,
        columnName: UDF_SUBSIDY_LOCAL,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: null,
          comparison: null,
        },
      },
    ],
    []
  );
  const sorters: Sorter[] = useMemo(
    () => [
      {
        chartUsedColId: TAKEOFFDATE,
        columnName: TAKEOFFDATE,
        sorter: "ASC",
        statistical: "COUNT",
      },
    ],
    []
  );

  const oriFilters = useMemo(() => {
    if (!datasetCols.length || !airport) {
      return [];
    }
    return getFilters({
      depart: departure ? 1 : 0,
      arrive: arrive ? 1 : 0,
      startDate,
      endDate,
      port: airport,
      departArea: queryExt?.departArea,
      arriveArea: queryExt?.arriveArea,
    });
  }, [
    airport,
    arrive,
    datasetCols.length,
    departure,
    endDate,
    queryExt?.arriveArea,
    queryExt?.departArea,
    startDate,
  ]);

  const postFilter = useMemo(() => {
    if (!queryExt) {
      return null;
    }
    const { cancelRateStart, cancelRateEnd } = queryExt;
    const filters: StandardFilter[] = [];
    if (cancelRateStart != null) {
      filters.push({
        ge: {
          field: "AVG_prob",
          value: cancelRateStart.toString(),
        },
      });
    }
    if (cancelRateEnd != null) {
      filters.push({
        le: {
          // field: `avg(measure.prob)`,
          field: "AVG_prob",
          // @ts-ignore
          value: cancelRateEnd,
        },
      });
    }
    return filters;
  }, [queryExt]);

  const requestBuild = useMemo(() => {
    if (!oriFilters.length) {
      return;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions,
      measures,
      chartFilters: [],
      sorters,
      containerFilters: [],
      oriFilters,
      postFilter: postFilter ?? undefined,
      limit: 100,
    });
  }, [cols, dimensions, measures, oriFilters, postFilter, sorters]);
  const { encrypted, requestBody } = useMemo(() => {
    if (requestBuild) {
      return requestBuild.getRequestBody();
    } else {
      return { encrypted: null, requestBody: null };
    }
  }, [requestBuild]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoading(true);
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  useImperativeHandle(ref, () => ({
    refresh: refetch,
  }));

  // #endregion

  // #region 生成日期Tabs
  /** 日期tabs */
  const tabs = useMemo(() => {
    const sortedSource = _.sortBy(dataWithKey, (d) =>
      d.takeoffdate ? new Date(d.takeoffdate) : 0
    );
    const takeoffTimes = _.sortedUniqBy(sortedSource, (d) =>
      d.takeoffdate ? new Date(d.takeoffdate.substring(0, 10)).getTime() : 0
    );
    const takeoffDays = takeoffTimes.map((d: any) =>
      moment(d.takeoffdate).format(DATE_FORMAT)
    );
    const today = moment().format(DATE_FORMAT);
    const rst: ITabItem[] = _.cloneDeep(defaultTabs);
    takeoffDays.forEach((v) => {
      const vd = moment(v).format(DATE_FORMAT);
      rst.push({
        name: today === vd ? getSharkText("config_page_today") : `${vd}`,
        label: today === vd ? getSharkText("config_page_today") : `${vd}`,
        key: vd,
        value: vd,
      });
    });
    return rst;
  }, [dataWithKey]);
  // #endregion

  const [{}, changeConfig] = useFetch({
    url: "saveOdtConfig",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "none",
    },
    lazey: true,
    onSuccess: (res) => {
      message.success("操作成功");
      // refetch();
    },
    onError: () => {
      message.error("操作失败");
    },
  });

  const handleConfigChange = useRefFunc(
    (v: string, r: FlightInfoDetailWithKey) => {
      const param: OdtConfig = {
        id: null,
        module: MODULE_CODE,
        takeoffdate: r.config_key,
        route: null,
        flightno: null,
        config: v,
      };
      changeConfig({
        ext: { data: param },
      });
      console.log("config", v, r);
    }
  );

  const handleChangeSubsidyNonLocal = useRefFunc(
    (v: string, r: FlightInfoDetailWithKey) => {
      const param: OdtConfig = {
        id: null,
        module: MODULE_CODE + UDF_SUBSIDY_NON_LOCAL,
        takeoffdate: r.config_key,
        route: null,
        flightno: null,
        config: v,
      };
      changeConfig({
        ext: { data: param },
      });
      console.log("config", v, r);
    }
  );

  const handleChangeSubsidyLocal = useRefFunc(
    (v: string, r: FlightInfoDetailWithKey) => {
      const param: OdtConfig = {
        id: null,
        module: MODULE_CODE + UDF_SUBSIDY_LOCAL,
        takeoffdate: r.config_key,
        route: null,
        flightno: null,
        config: v,
      };
      changeConfig({
        ext: { data: param },
      });
      console.log("config", v, r);
    }
  );

  const debounceLocal = useDebounce(handleChangeSubsidyLocal, 1000);
  const debounceNonLocal = useDebounce(handleChangeSubsidyNonLocal, 1000);

  // #region 列定义
  const columns: IDownloadHeaderPro[] = useMemo(
    () => getColumns(handleConfigChange, debounceNonLocal, debounceLocal),
    [debounceLocal, debounceNonLocal, handleConfigChange]
  );
  // #endregion

  const handleRefresh = () => {
    refetch();
  };

  const [activeKey, setActiveKey] = useState<string>("unknown");

  const onChangeTab = useRefFunc((key: string) => {
    setActiveKey(key === "all" ? "unknown" : key);
  });

  const [modal, contextHolder] = Modal.useModal();

  const onDoubleClickRow = useRefFunc((record: FlightInfoDetailWithKey) => {
    console.log("doubleclick: ", record);
    modal.info({
      icon: null,
      content: (
        <DetailTable
          record={record}
          moduleCode={MODULE_CODE}
          chartTableCode={"FlightInfoDetailWithKey"}
        />
      ),
      width: 1280,
      maskClosable: true,
      closable: true,
    });
  });

  const toolbarTabsConfig = useMemo(() => {
    const config: ListToolBarTabs = {
      activeKey,
      onChange: onChangeTab,
      // items: Array.from({ length: 20 }, (v, i) => ({
      //   key: `tab${i}`,
      //   tab: `第${i}个标签页的内容`,
      // })),
      items: tabs,
    };
    return config;
  }, [activeKey, onChangeTab, tabs]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  if (!isLoading && _.isEmpty(dataWithKey)) {
    return <Empty />;
  }

  return (
    <div id="analysisTable">
      <Spin spinning={isLoading}>
        <div ref={tableContainerRef}>
          <CustomTable
            dataSource={dataWithKey}
            columns={columns}
            onDoubleClick={onDoubleClickRow}
            otherProps={{
              id: "flight-manage-main-table",
              pagination: {
                pageSize: 20,
                showSizeChanger: false,
                // total: totalCnt,
              },
              rowKey: "key",
              onChange: (v: TablePaginationConfig) => {
                setPageParam((s) => ({ ...s, pageNum: v.current || 1 }));
              },
              // bordered,
            }}
            // toolbarTabsConfig={toolbarTabsConfig}
            onReload={handleRefresh}
          />
        </div>
      </Spin>
      <div>{contextHolder}</div>
    </div>
  );
};

const PriceCalendar = React.forwardRef(PCalendar);
export default PriceCalendar;

import DateRangePickerV2 from "Components/DateRangePickerV2";
import { IValueLabelOption, RangeObject } from "Interface";
import useGlobalState from "Store";
import { Button, Col, message, Row, Select, Spin } from "antd";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { DEFAULT_RANGE } from "..";
import useRefFunc from "Utils/useRefFunc";
import { GetFlightInfoRequest } from "../interface";
import { DATE_FORMAT, IS_PRO } from "Constants";
import useDimensionEnumValues from "Page/AI/FreeDashboard/useDimensionEnumValues";

const DATASET_ID = IS_PRO ? 323 : 85;

export interface SearchAreaProps {
  onChange: (v: GetFlightInfoRequest) => void;
}

/** 切位运营-过滤栏 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { onChange } = props;
  const [globalState] = useGlobalState();
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [range, setRange] = useState<RangeObject>(DEFAULT_RANGE);
  const [routes, setRoutes] = useState<string | null>(null);
  const [flights, setFlights] = useState<string | null>(null);
  const [routesOptions, setRouteOptions] = useState<IValueLabelOption[]>([]);
  const [flightsOptions, setFlightsOptions] = useState<IValueLabelOption[]>([]);
  const [initialed, setInitialed] = useState(false);
  const dimensionService = useDimensionEnumValues({ datasetId: DATASET_ID });

  useEffect(() => {
    dimensionService.getDimensionEnumValues("route").then((res) => {
      if (res) {
        const options = res.map((r) => ({
          label: r.route,
          value: r.route,
        })) as IValueLabelOption[];
        setRouteOptions(options);
        if (options.length > 0) {
          setRoutes((s) => (s === null ? options[0].value : s));
        }
      }
    });
  }, [dimensionService]);

  useEffect(() => {
    dimensionService.getDimensionEnumValues("flightno").then((res) => {
      if (res) {
        const options = res.map((r) => ({
          label: r.flightno,
          value: r.flightno,
        })) as IValueLabelOption[];
        setFlightsOptions(options);
      }
    });
  }, [dimensionService]);

  const supplierOptions = useMemo(() => {
    const str = globalState.moduleConfig.agencyid;
    try {
      const list = JSON.parse(str);
      if (list.length) {
        setSupplierId((s) => (s === null ? Number(list[0]) : s));
      }
      return list.map((l: any) => {
        return {
          label: l,
          value: Number(l),
        };
      });
    } catch (error) {
      return [];
    }
  }, [globalState.moduleConfig.agencyid]);

  const checkDateRange = useRefFunc(() => {
    if (
      !routesOptions.length ||
      !flightsOptions.length ||
      !range ||
      !range[0] ||
      !range[1]
    ) {
      return false;
    }
    if (!routes && range[1].diff(range[0], "days") >= 3) {
      message.error("不指定航线时查询时间范围不能超过3天");
      return false;
    }
    if (routes && range[1].diff(range[0], "days") >= 30) {
      message.error("指定航线时查询时间范围不能超过30天");
      return false;
    }
    return true;
  });

  useEffect(() => {
    checkDateRange();
  }, [range, routes]);

  const handleSearch = useRefFunc(() => {
    if (!supplierId) {
      message.error("票台为必选项, 航线航班至少选择一项");
      return;
    }
    if (checkDateRange()) {
      const param = {
        supplierId,
        flightNo: flights ?? null,
        dport: routes ? routes.substring(0, 3) : null,
        aport: routes ? routes.substring(3) : null,
        searchStartDate: range && range[0] ? range[0].format(DATE_FORMAT) : "",
        searchEndDate: range && range[1] ? range[1].format(DATE_FORMAT) : "",
        size: 10,
        pageNum: 1,
      };
      onChange(param);
    }
  });

  // #region 触发初次查询
  useEffect(() => {
    if (
      !initialed &&
      supplierId &&
      routesOptions.length &&
      flightsOptions.length
    ) {
      handleSearch();
      setInitialed(true);
    }
  }, [
    flightsOptions.length,
    handleSearch,
    initialed,
    routesOptions.length,
    supplierId,
  ]);
  // #endregion

  return (
    <div className="search-area">
      <div>
        <Select
          options={supplierOptions}
          value={supplierId}
          onChange={setSupplierId}
          style={{ minWidth: 100 }}
          showSearch
          allowClear
        ></Select>
      </div>
      <Row gutter={10}>
        <Col>
          {routesOptions.length > 0 ? (
            <Select
              options={routesOptions}
              value={routes}
              onChange={setRoutes}
              style={{ minWidth: 150 }}
              showSearch
              allowClear
            />
          ) : (
            <Spin />
          )}
        </Col>
        <Col>
          {flightsOptions.length ? (
            <Select
              options={flightsOptions}
              value={flights}
              onChange={setFlights}
              style={{ minWidth: 150 }}
              showSearch
              allowClear
            />
          ) : (
            <Spin />
          )}
        </Col>
        <Col>
          <DateRangePickerV2
            defaultDateMode={range}
            value={range}
            onChange={setRange}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            查询
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default SearchArea;

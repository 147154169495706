import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Area, IDownloadHeader } from "Interface";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { Empty, Spin } from "antd";
import Refetch from "Components/Refetch";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import { getServer } from "Service/server";
import DownloadBtn from "Components/DownloadBtn";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";
import { totalColumns } from "./downloadColumns";
import { TouristQueryExt } from "../../TouristInterface";
import { Index } from "../../TouristConstant";
import EchartsReactBase from "Components/EchartsReactBase";

interface TouristChartsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesQueryExt: TouristQueryExt;
}

interface ChartData {
  day: string;
  lf?: number;
  airlineValue?: number;
  airlinePredValue?: number;
  soldSeats?: number;
  airlineSoldSeats?: number;
  airlinePredSoldSeats?: number;
}

const TouristCharts: React.FC<TouristChartsProps> = (
  props: TouristChartsProps
) => {
  const { queryUrl, moduleCode, chartTableCode, area, airlinesQueryExt } =
    props;
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = useMemo(
    () => (isAirport ? { area } : { filter: { ...airlinesQueryExt } }),
    [airlinesQueryExt, area, isAirport]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: {
        ...ext,
        aggCode: 0,
      },
    });
  }, [doFetch, ext, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: ChartData) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.lf = item.lf || item.lf === 0 ? _.round(item.lf, 1) : undefined;
        item.airlineValue =
          item.airlineValue || item.airlineValue === 0
            ? _.round(item.airlineValue, 1)
            : undefined;
        item.airlinePredValue =
          item.airlinePredValue || item.airlinePredValue === 0
            ? _.round(item.airlinePredValue, 1)
            : undefined;
        item.soldSeats =
          item.soldSeats || item.soldSeats === 0
            ? _.round(item.soldSeats, 1)
            : undefined;
        item.airlineSoldSeats =
          item.airlineSoldSeats || item.airlineSoldSeats === 0
            ? _.round(item.airlineSoldSeats, 1)
            : undefined;
        item.airlinePredSoldSeats =
          item.airlinePredSoldSeats || item.airlinePredSoldSeats === 0
            ? _.round(item.airlinePredSoldSeats, 1)
            : undefined;
        return item;
      });
      setChartData(compareData);
    } else {
      setChartData([]);
    }
  }, [data]);

  const series1 = {
    type: "line",
    encode: { x: "day", y: "lf" },
    name:
      ext.filter?.filterType === 2
        ? getSharkText("config_page_actual_seat_flight")
        : airlinesQueryCondition.airlines +
          getSharkText("config_page_actual_seat_airlines"),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#FC9B4F",
    },
  };
  const series2 = {
    type: "line",
    encode: { x: "day", y: "airlineValue" },
    name: getSharkText("config_page_actual_airline_seats"),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#177DFE",
    },
  };
  const series3 = {
    type: "line",
    encode: { x: "day", y: "airlinePredValue" },
    name: getSharkText("config_page_airline_pred_passenger"),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#01C5DB",
    },
    lineStyle: {
      color: "#01C5DB",
      type: "dashed",
    },
  };
  const series4 = {
    type: "line",
    encode: { x: "day", y: "soldSeats" },
    name:
      ext.filter?.filterType === 2
        ? getSharkText(
            "config_page_PaAiToCoToIn_flight_actual_passenger_volume"
          )
        : airlinesQueryCondition.airlines +
          getSharkText(
            "config_page_PaAiToCoToIn_airline_actual_passenger_volume"
          ),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#e84c3d",
    },
    yAxisIndex: 1,
  };
  const series5 = {
    type: "line",
    encode: { x: "day", y: "airlineSoldSeats" },
    name: getSharkText("config_page_airline_actual_passenger_volume"),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#26af5f",
    },
    yAxisIndex: 1,
  };
  const series6 = {
    type: "line",
    encode: { x: "day", y: "airlinePredSoldSeats" },
    name: getSharkText("config_page_airline_pred_passenger_volume"),
    showSymbol: false,
    symbol: "circle",
    itemStyle: {
      color: "#16a184",
    },
    lineStyle: {
      color: "#16a184",
      type: "dashed",
    },
    yAxisIndex: 1,
  };

  const getSeries = () => {
    const indexes = airlinesQueryExt.indexes;
    const loadSeries = isAirport
      ? [series2, series3]
      : indexes.includes(Index.LF)
      ? [series1, series2, series3]
      : [];
    const seatsSeries = isAirport
      ? [series5, series6]
      : indexes.includes(Index.Seat)
      ? [series4, series5, series6]
      : [];
    return loadSeries.concat(seatsSeries);
  };

  const option = {
    dataset: {
      source: chartData,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
      selected: {
        // 航线实际客运量: false,
        // 航线预测客运量: false,
        // 航班实际客运量: false,
      },
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = params[0].data.day;
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const airlineValue = item.data.airlineValue
            ? item.data.airlineValue + "%"
            : "-";
          const airlinePredValue = item.data.airlinePredValue
            ? item.data.airlinePredValue + "%"
            : "-";
          const lf = item.data.lf ? item.data.lf + "%" : "-";
          const soldSeats = item.data.soldSeats ? item.data.soldSeats : "-";
          const airlineSoldSeats = item.data.airlineSoldSeats
            ? item.data.airlineSoldSeats
            : "-";
          const airlinePredSoldSeats = item.data.airlinePredSoldSeats
            ? item.data.airlinePredSoldSeats
            : "-";
          const val = `${item.seriesName}: ${
            item.seriesName === getSharkText("config_page_actual_airline_seats")
              ? airlineValue
              : item.seriesName ===
                getSharkText("config_page_airline_pred_passenger")
              ? airlinePredValue
              : item.seriesName ===
                getSharkText("config_page_airline_actual_passenger_volume")
              ? airlineSoldSeats
              : item.seriesName ===
                getSharkText("config_page_airline_pred_passenger_volume")
              ? airlinePredSoldSeats
              : item.seriesName.slice(-2) ===
                getSharkText("config_page_PaAiToCoToIn_passenger_seat")
              ? lf
              : soldSeats
          }`;
          dom = dom + "<br />" + dot + val;
        });
        dom = day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
      },
    },
    yAxis: [
      {
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        max: 100,
        axisLabel: {
          formatter: "{value}%",
        },
        splitNumber: 4,
        interval: 25,
      },
      {
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: getSeries(),
  };
  const handleDownload = useRefFunc(() => {
    const series = getSeries();
    // 过滤出当前图表显示的列, 并且将名称应用到下载的列上
    const columns = totalColumns.reduce((t, c) => {
      if (c.dataIndex === "day") {
        t.push(c);
        return t;
      }
      const targetSeries = series.find((s) => s.encode.y === c.dataIndex);
      if (targetSeries) {
        const tmp = cloneDeep(c);
        tmp.title = tmp.title || targetSeries.name;
        t.push(tmp);
      }
      return t;
    }, [] as IDownloadHeader[]);
    downloadExcel(
      columns,
      chartData,
      getSharkText("menu_tourist_source_airlines")
    );
  });

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }
  return (
    <Spin spinning={isLoading}>
      {_.isEmpty(data) ? (
        <Empty style={{ marginTop: 30 }} />
      ) : (
        <div>
          <div style={{ textAlign: "right", padding: "5px 5px 0 0" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </div>
          <EchartsReactBase
            option={option}
            theme="default"
            style={{ height: 450 }}
          />
        </div>
      )}
    </Spin>
  );
};

export default TouristCharts;

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import useGlobal from "Store";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { Area, AirlinesQueryExt } from "Interface";
import { Spin, Card, Select, InputNumber } from "antd";
import Refetch from "Components/Refetch";
import _ from "lodash";
import { getServer } from "Service/server";
import BMap from "Components/BMap";
import { useDebounceState } from "Utils/useDebounceState";
import SortOrder from "Components/SortOrder";
import { AirportCompare } from "@ctrip/flt-bi-flightai-airlines";
import EchartsReactBase from "Components/EchartsReactBase";

const { Option } = Select;

interface AlineChartsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesQueryExt: AirlinesQueryExt;
  setArea: (v: Area) => void;
  setAirlinesQueryExt: (e: AirlinesQueryExt) => void;
}

interface SeriesData {
  airportname: string;
  coords: Array<Array<number | null>>;
  value: Array<number | null>;
}

interface MapData {
  [propName: string]: SeriesData[];
}

const cards = [
  {
    cardCode: "passenger_traffic",
    cardName: getSharkText("card.passenger_traffic.name"),
    cardHint: getSharkText("card.passenger_traffic.hint"),
    cardValue: 13576,
    avgValue: 453,
    compareValue: 1720156,
  },
  {
    cardCode: "kilo_passenger_traffic",
    cardName: getSharkText("card.rpk_estimated.name"),
    cardHint: getSharkText("card.rpk_tips.hint"),
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  },
  {
    cardCode: "transport_capacity",
    cardName: getSharkText("key.available_seats_estimated"),
    cardHint: getSharkText("key.estimated_based_on_ctrip.hint"),
    cardValue: 24521,
    avgValue: 817,
    compareValue: 2052932,
  },
  {
    cardCode: "kilo_transport_capacity",
    cardName: getSharkText("card.ask_estimated.name"),
    cardHint: getSharkText("key.estimated_based_on_ctrip.hint"),
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  },
  {
    cardCode: "flight_sorties",
    cardName: getSharkText("key.number_of_flights"),
    cardHint: getSharkText("key.flightcarried_statistic"),
    cardValue: 136,
    avgValue: 5,
    compareValue: 10321,
  },
  {
    cardCode: "passengers",
    cardName: getSharkText("key.passenger_traffic_per_flight"),
    cardHint: getSharkText("key.actual_traffic_divide_flights"),
    cardValue: 100,
    avgValue: 87,
    compareValue: 167,
  },
  {
    cardCode: "ow_price",
    cardName: getSharkText("key.oneway_average_price"),
    cardHint: getSharkText("key.ow_avg_price_ctrip.hint"),
    cardValue: 1662,
    avgValue: 1657,
    compareValue: 953,
  },
  {
    cardCode: "capacity_production_cost",
    cardName: getSharkText("key.capacity.roi"),
    cardHint: getSharkText("key.capacity_roi_calculation_hint"),
    cardValue: 1.0077918319502457,
    avgValue: 1.0081259083346916,
    compareValue: 1,
  },
  {
    avgValue: 1.0730699457235573,
    cardCode: "income_production_cost",
    cardHint: getSharkText("key.revenue_roi_calculation_hint"),
    cardName: getSharkText("key.revenue.roi"),
    cardValue: 1.0722403088400945,
    compareValue: 1,
  },
];

const AlineCharts: React.FC<AlineChartsProps> = (props: AlineChartsProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    area,
    airlinesQueryExt,
    setArea,
    setAirlinesQueryExt,
  } = props;
  const [option, setOption] = useState<object>({});
  const [globalState] = useGlobal();
  const { queryCondition, airlinesQueryCondition, systemType } = globalState;
  const { airport } = queryCondition;
  const isAirport = isAirportMode(systemType);
  const [isBMapReady, setIsBMapReady] = useState<boolean>(false);
  const [selectedCode, setSelectedCode] = useState<string>(cards[0].cardCode);
  const [topCnt, setTopCnt] = useState<number>(200);
  const [sort, setSort] = useState<"asc" | "desc">("desc");
  const tmpExt = useMemo(() => {
    return {
      cardCode: selectedCode,
      orderSort: sort === "asc" ? 0 : 1,
      top: topCnt,
    };
  }, [selectedCode, topCnt, sort]);
  const mapQueryExt = useDebounceState(tmpExt, 500);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = useMemo(() => {
    return isAirport
      ? { area, top: 200, cardCode: "transport_capacity" }
      : {
          filter: { ...airlinesQueryExt },
          top: 200,
          cardCode: "transport_capacity",
        };
  }, [airlinesQueryExt, area, isAirport]);
  const [{ data, isLoading, error }, doFetch] = useFetch<AirportCompare[]>({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  // #region 百度地图API初始化
  useEffect(() => {
    // 必须执行这一步才能引入百度地图组件
    try {
      BMap.init().then(() => {
        // BMAP引入后会自动进行初始化, 这个初始化和echarts的初始化是异步的, 如果echarts先初始化完成,
        // 加载BMap时, 会爆BMap.Overlay is not a constructor的错误, 所以等待一秒, 后续寻找BMap检测init完成的方法后替换这个延时
        setTimeout(() => {
          setIsBMapReady(true);
        }, 1000);
      });
    } catch (e) {
      console.log(e);
    }
  }, []);
  // #endregion

  const chartsRef = useRef<any>(null);
  const refetch = useCallback(() => {
    doFetch({ query, ext: { ...ext, ...mapQueryExt } });
  }, [doFetch, ext, mapQueryExt, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getMapData = (mapdata: AirportCompare[]) => {
    const obj: MapData = {
      lt20: [],
      gte21lt50: [],
      gte51: [],
    };
    mapdata.forEach((item) => {
      const lng = isAirport
        ? item.dport === airport
          ? item.aportlng
          : item.dportlng
        : item.dportlng;
      const lat = isAirport
        ? item.dport === airport
          ? item.aportlat
          : item.dportlat
        : item.dportlat;
      const tmp = {
        ...item,
        airportname: `${item.dportName} - ${item.aportName}`,
        coords: [
          [item.dportlng, item.dportlat],
          [item.aportlng, item.aportlat],
        ],
        value: [lng, lat, item.value],
      };
      if (item.rnk === null) {
        return;
      }
      if (item.rnk < 21) {
        obj.lt20.push(tmp);
      } else if (item.rnk < 51) {
        obj.gte21lt50.push(tmp);
      } else {
        obj.gte51.push(tmp);
      }
    });
    return obj;
  };

  // 获取下拉框选中的指标名称
  const getSelectedIndexName = useCallback(() => {
    const selectedCardItem = cards.find((c) => c.cardCode === selectedCode);
    if (!selectedCardItem) {
      return "--";
    }
    const name = selectedCardItem.cardName;
    return name;
  }, [selectedCode]);

  const getSeries = (mapData: MapData) => {
    const series: any = [];
    const color = ["#FC9B4F", "#01C5DB", "#177DFE"];
    const name = getSelectedIndexName();
    const nameObj: any = {
      lt20: `${name} ${getSharkText("key.ranking_1to20.hint")}`,
      gte21lt50: `${name} ${getSharkText("key.ranking_21to50.hint")}`,
      gte51: `${name} ${getSharkText("key.ranking_above51.hint")}`,
    };
    Object.keys(mapData).forEach((item, idx) => {
      series.push(
        {
          name: nameObj[item],
          type: "lines",
          coordinateSystem: "bmap",
          zlevel: 2,
          large: true,
          effect: {
            show: true,
            constantSpeed: 30,
            symbol: "pin",
            symbolSize: 3,
            trailLength: 0,
          },
          lineStyle: {
            normal: {
              color: color[idx],
              width: 1,
              opacity: 0.3,
              curveness: 0.1,
            },
          },
          data: mapData[item],
        },
        {
          name: nameObj[item],
          type: "effectScatter",
          coordinateSystem: "geo",
          zlevel: 2,
          rippleEffect: {
            brushType: "stroke",
          },
          symbolSize: (val: any) => val[2] / 30,
          itemStyle: {
            normal: {
              color: color[idx],
            },
          },
          data: mapData[item],
        }
      );
    });
    return series;
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const sortData = data.sort((a: any, b: any) => b.value - a.value);
    const filterData = sortData.slice(0, 200);
    if (!isBMapReady) {
      return;
    }

    const series = getSeries(getMapData(filterData));
    const optionTmp = {
      // backgroundColor: "#fff",
      // title: {
      //   text: "客座率Top200航线",
      //   left: 0,
      // },
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          const selectedCardItem = cards.find(
            (c) => c.cardCode === selectedCode
          );
          if (!selectedCardItem) {
            return "--";
          }
          const name = selectedCardItem.cardName;
          return params.data
            ? params.data.airportname +
                `<br />${name}` +
                params.data.value[2] +
                `<br />${getSharkText("key.click_to_view_route_details")}`
            : "";
        },
      },
      legend: {
        orient: "vertical",
        right: 20,
        bottom: 80,
        zlevel: 3,
        textStyle: {
          color: "#333",
        },
      },
      // 加载 bmap 组件
      bmap: {
        // 百度地图中心经纬度
        center: [106.33066322374, 33.704018034923],
        mapOptions: {
          enableMapClick: false,
          language: "en",
        },
        // 百度地图缩放
        zoom: 4,
        // 是否开启拖拽缩放，可以只设置 'scale' 或者 'move'
        // roam: "move",
        roam: true,
        // // 百度地图的自定义样式，见 http://developer.baidu.com/map/jsdevelop-11.htm
        // mapStyle: { styleJson: [] },
      },
      series,
    };
    setOption(optionTmp);
  }, [data, isBMapReady]);

  useEffect(() => {
    const reRender = () => {
      if (chartsRef && chartsRef.current) {
        const optionTmp = chartsRef.current.getEchartsInstance().getOption();
        chartsRef.current.getEchartsInstance().clear();
        chartsRef.current.getEchartsInstance().setOption(optionTmp);
      }
    };
    const onResize = _.debounce(reRender, 500);
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [chartsRef]);

  const onEvents = useMemo(() => {
    return {
      click: (a: any) => {
        console.log("click :", a);
        const item = a.data as AirportCompare;
        if (!item.dport || !item.dportName || !item.aport || !item.aportName) {
          return;
        }
        const arriveArea: Area = {
          areaType: 5,
          areaName: item.aportName,
          areaCode: item.aport,
        };
        const departArea: Area = {
          areaType: 5,
          areaCode: item.dport,
          areaName: item.dportName,
        };
        if (isAirport) {
          // 全局选中虹桥机场时, 点击航线选择另一机场作为目标
          const globalPortArr = airport.split(",");
          const otherPortArea: Area = globalPortArr.includes(item.dport)
            ? arriveArea
            : departArea;
          setArea(otherPortArea);
        } else {
          const tmpQueryExt = {
            ...airlinesQueryExt,
            departArea,
            arriveArea,
          };
          setAirlinesQueryExt(tmpQueryExt);
        }
      },
    };
  }, [airlinesQueryExt, airport, isAirport, setAirlinesQueryExt, setArea]);

  if (isLoading) {
    return (
      <Card style={{ height: 448 }}>
        <Spin />
      </Card>
    );
  }
  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Card style={{ height: 448 }}>
      <EchartsReactBase
        className="airlines-map"
        ref={chartsRef}
        option={option}
        onEvents={onEvents}
        style={{ height: 400, width: "100%" }}
      />
      <div
        style={{
          position: "absolute",
          top: 30,
          left: 30,
          background: "#fff",
          padding: 5,
        }}
      >
        <Select
          style={{ minWidth: 150 }}
          value={selectedCode}
          onChange={(e: any) => setSelectedCode(e)}
        >
          {cards.map((card) => (
            <Option value={card.cardCode} key={card.cardCode}>
              {card.cardName}
            </Option>
          ))}
        </Select>
        <span style={{ margin: "0 5px" }}>Top</span>
        <InputNumber
          value={topCnt}
          onChange={(e) => setTopCnt(e || 0)}
        ></InputNumber>
        <span style={{ margin: "0 5px" }}>
          {getSharkText("key.route.name")}
        </span>
        <SortOrder value={sort} onChange={(e) => setSort(e)} />
      </div>
    </Card>
  );
};

const areEqual = (
  prevProps: AlineChartsProps,
  nextProps: AlineChartsProps
): boolean => {
  return (
    _.isEqual(prevProps.area, nextProps.area) &&
    _.isEqual(prevProps.airlinesQueryExt, nextProps.airlinesQueryExt)
  );
};

export default React.memo(AlineCharts, areEqual);

import { Area } from "Interface";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import useGlobalState from "Store";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ABRIDGE_NAME,
  ANAME_NAME,
  customCols,
  DATASET_ID,
  DBRIDGE_NAME,
  DNAME_NAME,
  FLIGHTNO_NAME,
  REALARRIVETIME_DIFF_NAME,
  REALTAKEOFFTIME_DIFF_NAME,
  TKZONE,
} from "../AirportRanking/DataSetColumns";
import useRefFunc from "Utils/useRefFunc";
import { useFetch } from "Utils";
import { isDateCol } from "Utils/global";
import {
  Dimension,
  DimensionSchemaEx,
  Measure,
  MeasureSchemaEx,
  Sorter,
} from "Page/AI/FreeDashboard/interface";
import { getFilters } from "./AirportChart";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { ChartView } from "@ctrip/flt-bidw-mytrix-ui";
import { Agg, Sort } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import { isExpCol, isPExpCol } from "Page/AI/FreeDashboard/common";
import { Spin } from "antd";
import Refetch from "Components/Refetch";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

interface ResData {
  takeoffdate: string;
  dport: string;
  dportname: string;
  aport: string;
  aportname: string;
  flightno: string;
  SUM_dbridge: string | number | null;
  SUM_abridge: string | number | null;
  SUM_realtakeofftime_diff: number | null;
  SUM_realarrivetime_diff: number | null;
}

export interface AirportTableProps {
  area?: Area;
  airline: string;
}

/** 正点率/靠廊率表格 */
const AirportTable = (props: AirportTableProps): ReactElement => {
  const { area, airline } = props;
  const [resData, setResData] = useState<ResData[]>([]);
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const {
    departure,
    arrive,
    flightClass,
    startDate,
    endDate,
    originalAirport,
  } = queryCondition;
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const services = useServices();
  const datasetId = DATASET_ID;
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "airportTable",
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers) as unknown as ResData[];
      const rst = source.map((s) => {
        return {
          ...s,
          SUM_dbridge:
            s.SUM_dbridge === -1 ? null : s.SUM_dbridge === 1 ? "是" : "否",
          SUM_abridge:
            s.SUM_abridge === -1 ? null : s.SUM_abridge === 1 ? "是" : "否",
          SUM_realtakeofftime_diff:
            s.SUM_realtakeofftime_diff === -999
              ? null
              : s.SUM_realtakeofftime_diff,
          SUM_realarrivetime_diff:
            s.SUM_realarrivetime_diff === -999
              ? null
              : s.SUM_realarrivetime_diff,
        };
      });
      setResData(rst);
    },
  });

  const columns = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const dimensions = useMemo(() => {
    const tmp: Dimension[] = [
      {
        columnName: TKZONE,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: DNAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: ANAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: FLIGHTNO_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    return tmp;
  }, []);
  const measures: Measure[] = useMemo(
    () => [
      {
        id: DBRIDGE_NAME,
        columnName: DBRIDGE_NAME,
        measureConfig: {
          name: "登机是否靠桥",
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: ABRIDGE_NAME,
        columnName: ABRIDGE_NAME,
        measureConfig: {
          name: "下机是否靠桥",
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: REALTAKEOFFTIME_DIFF_NAME,
        columnName: REALTAKEOFFTIME_DIFF_NAME,
        measureConfig: {
          name: "起飞时间比计划差值(min)",
          tip: "起飞时间比计划差值 = 实际起飞时间-计划起飞时间",
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: REALARRIVETIME_DIFF_NAME,
        columnName: REALARRIVETIME_DIFF_NAME,
        measureConfig: {
          name: "降落时间比计划差值(min)",
          tip: "降落时间比计划差值 = 实际降落时间-计划降落时间",
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ],
    []
  );
  const sorters: Sorter[] = useMemo(() => {
    const tmp: Sorter[] = [
      {
        chartUsedColId: TKZONE,
        columnName: TKZONE,
        sorter: "ASC",
        statistical: "SUM",
      },
    ];
    return tmp;
  }, []);

  const refetch = useCallback(() => {
    if (!datasetCols.length || !originalAirport) {
      return;
    }
    const [dFilters, dOriFilters] = getFilters({
      depart: departure,
      arrive,
      flightClass,
      startDate,
      endDate,
      area,
      airline,
      port: originalAirport,
    });
    const requestBuild = new RequestBuilder({
      datasetId,
      columns,
      dimensions,
      measures,
      chartFilters: dFilters,
      sorters,
      containerFilters: [],
      oriFilters: dOriFilters,
      limit: 100,
    });
    const { encrypted } = requestBuild.getRequestBody();

    doFetch({
      ext: {
        datasetId,
        colIds: [],
        req: encrypted,
      },
    });
  }, [
    airline,
    area,
    arrive,
    columns,
    datasetCols.length,
    datasetId,
    departure,
    dimensions,
    doFetch,
    endDate,
    flightClass,
    measures,
    originalAirport,
    sorters,
    startDate,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const dimCols = useMemo(() => {
    const tmpCols = columns.filter((c) => c.kind === 1);
    const rst: DimensionSchemaEx[] = tmpCols.map((c) => {
      const item: DimensionSchemaEx = {
        name: c.name || "",
        isDate: isDateCol(c),
        displayName: c.displayName || "",
      };
      return item;
    });
    return rst;
  }, [columns]);

  const meaCols = useMemo(() => {
    const tmpCols = columns.filter((c) => c.kind === 0);
    const rst: MeasureSchemaEx[] = tmpCols.map((c) => {
      const item: MeasureSchemaEx = {
        name: c.name || "",
        displayName: c.displayName || "",
        isMExp: isExpCol(c),
        isPExp: isPExpCol(c),
        sort: (c.sort || Sort.LONG) as Sort,
        aggs: (c.aggs ? c.aggs.split(",") : [Agg.sum]) as Agg[],
      };
      return item;
    });
    return rst;
  }, [columns]);

  if (error) {
    <Refetch error={error} refetch={refetch} />;
  }

  if (!datasetCols.length || isLoading) {
    return <Spin />;
  }

  return (
    <div>
      <ChartView
        chartId="airportTable"
        chartType="table"
        dataSource={resData}
        dimensions={dimensions}
        measures={measures}
        schema={{
          name: "table",
          version: "1.0",
          dimensions: dimCols,
          measures: meaCols,
        }}
        granularity="1d"
        isEdit={false}
      />
    </div>
  );
};
export default AirportTable;

let noopPromise = async function(){}
let getStorage = noopPromise;
let setStorage = noopPromise;
let removeStorage = noopPromise;
let fetch = noopPromise;
global.disableAutoReport=false;

const webRegisterFnMap = {
	// 把 Storage 上的方法 改成 Promise 形式
	getStorageFn: function ({key}) {
		return new Promise(function (resolve) {
			try {
				resolve(window.localStorage.getItem(key))
			} catch (error) {
				resolve('')
			}
		})
	},
	setStorageFn: function ({key, value}) {
		return new Promise(function (resolve) {
			try {
				resolve(window.localStorage.setItem(key, value))
			} catch (error) {
				resolve('')
			}
		})
	},
	removeStorageFn: function ({key}) {
		return new Promise(function (resolve) {
			try {
				resolve(window.localStorage.removeItem(key))
			} catch (error) {
				resolve('')
			}
		})
	},
	// 把 Fetch 上的方法 改成 Promise 形式
	fetchFn: function ({url, body, method, header}) {
        try {
            return window.fetch(url, {
                method,
                headers:header,
                body:JSON.stringify(body)
            }).then(async response => {
                if (!response.ok) {
                    return { data: 'Network response was not ok' };
                }
                return { data: await response.json() };
            })
        } catch (error) {
			return Promise.resolve('')
		}
	}
}
registerFn(webRegisterFnMap)
    
// 传入的方法必须支持 Promise 形式调用
function registerFn({
    getStorageFn,
    setStorageFn,
    removeStorageFn,
    fetchFn,
}) {
    getStorage = getStorageFn;
    setStorage = setStorageFn;
    removeStorage = removeStorageFn;
    fetch = fetchFn;
}

const buildVersion = "_700032_flight$flt-dw-flightai_ares_20241212140821_0_7308";

const fnListCacheKey = "700032_flight$flt-dw-flightai_INJECT_FNLIST_CACHE";
const bVerCacheKey = "700032_flight$flt-dw-flightai_BUILD_VERSION_CACHE";
const maxStoreFnKeyLen = 5000; // fnKey 缓存最大个数
const maxMemoryFnKeyLen = 1000; // fnKey 内存最大个数

let sendedFnListMap = {}; //
let sendedFnList = []; // 需要按顺序删除缓存，缓存已经发送过的方法id，避免重复发送
let fnList = {};
let timer = null;
let reportSwitch = true; // 开关
let isInited = false;

(function () {
    global.i = function (fnId) {
        if (!isInited) {
            initBuildVersionCache();
        }

        if (!reportSwitch) {
            //console.log("reportSwitch is off.");
            clearTimer()
            fnList = {};
            return;
        }

        if (addFnId(fnId)) {
            // 如果 fnList 长度超过 maxMemoryFnKeyLen，就 report 一次
            if (Object.keys(fnList).length >= maxMemoryFnKeyLen) {
                reportInjectFnInfo();
            } else {
                createTimer();
            }
        }
    };
})()

async function initBuildVersionCache() {
    isInited = true;
    try {
        let cacheBuildVersion =
            (await getStorage({ key: bVerCacheKey })) || "";
        if (!cacheBuildVersion || cacheBuildVersion !== buildVersion) {
            console.log(
                "buildVersion not match:",
                buildVersion
            );
            await removeStorage({ key: fnListCacheKey });
            await setStorage({
                key: bVerCacheKey,
                value: buildVersion || "",
            });
            return;
        }

        //当前客户端已经发过的fnIds，再打开时也是没必要再发送的
        let sendedFnListCache =
            (await getStorage({ key: fnListCacheKey })) || "";
        // todo? 确认一下 sendFnListCache 的数据结构，是不是存啥样，取啥样？
        if (typeof sendedFnListCache === "string") {
            sendedFnListCache =
                (sendedFnListCache && JSON.parse(sendedFnListCache)) || [];
        }
        // console.log("sendedFnListCache:", sendedFnListCache)
        sendedFnList = sendedFnList.concat(sendedFnListCache);
        sendedFnList.forEach(function (item) {
            sendedFnListMap[item] = 1;
        });
    } catch (e) {
        console.error(e);
    }
}

/**
 * 添加方法Id到队列中
 * @param fnId
 */
function addFnId(fnId) {
    // 已发送数组，修改fnId的位置
    if (fnList[fnId] || sendedFnListMap[fnId]) {
        return false;
    }
    fnList[fnId] = 1;
    return true;
}

function clearTimer () {
    if (timer) {
                clearTimeout(timer);
                timer = null;
            }
}

/**
 * 创建定时器，1分钟发送1次数据
 */
function createTimer() {
    if(global.disableAutoReport){
        //关闭自动上报
        return
    }
    const interval = global.reportInterval || 20000;
    if (!timer && Object.keys(fnList).length > 0) {
                //如果有方法未发送并且也没有创建timer，则创建timer
                timer = setTimeout(function () {
                    // 如果规定时间内有数据，调用服务端接口，发送方法调用数据
                    console.log("timer report.");
                    clearTimer()
                    reportInjectFnInfo();
                }, interval);
            }
}

function reportInjectFnInfo() {
    clearTimer()

    if (!reportSwitch) {
        console.log("reportSwitch is off.");
        return;
    }

    let fnKeyList = Object.keys(fnList);
    // 强依赖 buildVersion
    if (!buildVersion || fnKeyList.length === 0) {
        return;
    }

    console.log("fnKeyList:", fnKeyList.join(""));
    fetch({
        url: "https://m.ctrip.com/restapi/soa2/28967/reportInjectFnInfo",
        method: "POST",
        header: {
            "Content-Type": "application/json",
        },
        body: {
            buildVersion,
            fnInfo: fnKeyList.join("")
        }
    })
        .then((res) => {
            console.log("report request success, res:", res);
            reportSwitch = !!(res && res.data && res.data.injectSwitch);
            if (reportSwitch) {
                createTimer();
            }
        })
        .catch((err) => {
            console.log("report request fail, err:", err);
            createTimer();
        })

    //更新缓存
    updateCache();
    fnList = {};
}

/**
 * 已经发送过的缓存，不管有没有发送成功
 * 目前保持 maxStoreFnKeyLen 个 fnId 当作缓存
 * 缓存会按照调用的时机进行位置更新
 */
async function updateCache() {
    Object.keys(fnList).forEach(function (fnId) {
        const i = sendedFnList.indexOf(fnId);
        if (i !== -1) {
            sendedFnList.splice(i, 1);
            delete sendedFnListMap[fnId];
        }
        sendedFnList.push(fnId);
        sendedFnListMap[fnId] = 1;
        if (sendedFnList.length > maxStoreFnKeyLen) {
            const firstId = sendedFnList.shift();
            delete sendedFnListMap[firstId];
        }
    });

    try {
        await setStorage({
            key: fnListCacheKey,
            value: JSON.stringify(sendedFnList),
        });
    } catch (e) {
        console.error("updateCache error:", e);
    }
}

global["reportInjectFnInfo"] = reportInjectFnInfo;
global["registerFn"] = registerFn;
export default {
    registerFn,
    reportInjectFnInfo
};
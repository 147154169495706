import {
  Affix,
  Button,
  Checkbox,
  Col,
  Empty,
  InputNumber,
  message,
  Modal,
  notification,
  Popover,
  Row,
  Spin,
  Table,
  Tabs,
} from "antd";
import {
  AdjustMainCabinEntity,
  AdjustSubCabinEntity,
  BatchAdjustFlightType,
  FlightImCmdType,
  FlightROInfoType,
  GetRODataRequestType,
  IDownloadHeader,
  IMBatchCmdGenerateRequestType,
  IMBatchCmdGenerateResponseType,
  IMBatchRequestType,
  IMCmdGenerateRequestType,
  IMCmdGenerateResponseType,
  IMRequestType,
  IMResponseType,
  LegEntityExtendsSub,
  SegEntity,
  SubCabinEntity,
} from "Interface";
import {
  IFetchCMDPrice,
  IFlightRecord,
} from "Page/AI/FlightManage/FlightManageInterface";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdjustCabin, { CabinAdjustHandle } from "./AdjustCabin";
import { arrayUpsert, insertIntoString, isSame, ubt } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobalState from "Store";
import { MODULE_CODE } from "Page/AI/FlightManage/fetchCode";
import { cloneDeep } from "lodash";
import {
  CLIENT_APP_ID,
  CLIENT_IP,
  getLimitDays,
  TRACE_ID,
} from "Page/AI/FlightManage/FlightManageCommon";
import usePrevious from "Utils/usePrevious";
import { EMPTY_ARRAY } from "Constants";
import CMDCopy from "./CMDCopy";
import useRefFunc from "Utils/useRefFunc";
import { useFetch } from "Utils/useFetch";
import SubCabin from "./SubCabin";
import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import "./index.scss";
import useServices from "./useServices";
import ubtUtils from "Utils/ubtUtils";
import { genSummaryData } from "Utils/global";
import { LS } from "Constants/localStorage";
import { AdjustCabinRecord, AdjustMainCabinEntityExLeg } from "./interface";
import BatchGenerate, { BatchGenerateRef } from "./BatchGenerate";
import moment from "moment";

const { TabPane } = Tabs;

/**
 * Component description
 * 黑屏面板
 */
const infoColumns: Array<IDownloadHeader<FlightROInfoType>> = [
  {
    title: getSharkText("key.flightno.name"),
    dataIndex: "flightno",
    width: 75,
    render: (val: string) => (
      <span style={{ fontWeight: 500, color: "var(--main)" }}>{val}</span>
    ),
  },
  {
    title: getSharkText("charts.date_of_departure"),
    dataIndex: "takeoffdate",
    width: 100,
  },
  {
    title: getSharkText("config_page_days_before_departure"),
    dataIndex: "daterange",
    render: (val) => `${val}${getSharkText("key.day.button")}`,
    width: 120,
  },
  {
    title: getSharkText("config_page_flight_model"),
    dataIndex: "flighttype",
    width: 60,
  },
  {
    title: getSharkText("config_page_cabin_layout"),
    dataIndex: "layout",
  },
];
const ERROR_TRACE_NAME = "o_flt_flightai_flightmanage_cabinlayout_submit_error";
const ubtTrace = (
  data: any,
  traceName = "o_flt_flightai_flightmanage_cabinlayout_submit"
) => {
  if (process.env.NODE_ENV === "development") {
    console.log(traceName, data);
  }
  ubtUtils.info(traceName, data, traceName);
};

// 需要保持生成的指令的弹窗唯一
const generateNotifyKey = "uniqueKey";
interface IProps {
  // record: AdjustCabinRecord;
  row?: IFlightRecord;
  setRecord?: (record: IFlightRecord) => void;
  param: AdjustCabinRecord;
  closeRoPanel: () => void;
  addFetchCMDPriceList?: (v: IFetchCMDPrice) => void;
}

// 操作成功提示持续时间
const successDuration = 5;
// 操作失败提示持续时间
const errorDuration = 10;

const BlackScreen = (props: IProps): ReactElement => {
  const { row, setRecord, param, closeRoPanel, addFetchCMDPriceList } = props;
  const { route } = param;
  const [globalState] = useGlobalState();
  const { userInfo, moduleConfig } = globalState;
  const [currentSeg, setCurrentSeg] = useState<string>(route);
  const [curSeg, setCurSeg] = useState<SegEntity>();
  const [segList, setSegList] = useState<SubCabinEntity[]>([]);
  const services = useServices();
  // 正在调舱面板中调整的数据
  const [adjustingData] = useState<SubCabinEntity[]>([]);
  const prev = usePrevious<IProps>(props);
  // 每次请求数据完成后, 第一次调舱结束上传trace数据, 用于比对调舱逻辑与航司调舱逻辑是否一致
  // 每次请求数据后设置为true, 上传埋点后设置为false
  const [justLoaded, setJustLoaded] = useState<boolean>(false);
  const notifyContainerRef = useRef(null);
  // 航班调价建议是否异于原价, 仅当建议价不为null并且与原价不同时为true
  const hasDiffSuggestPrice = useMemo(() => {
    return (
      !row || (row.suggestPrice !== null && row.rawPrice !== row.suggestPrice)
    );
  }, [row]);
  const isBatchPage = useMemo(() => !row, [row]);
  const maxDate = useMemo(() => {
    if (isBatchPage) {
      return moment().add(365, "day");
    } else {
      const days = getLimitDays(moduleConfig, param.route);
      return moment().add(days, "day");
    }
  }, [isBatchPage, moduleConfig, param.route]);
  const [instructionDestroy, setInstructionDestroy] = useState<boolean>(false);
  const [showSubCabin, setShowSubCabin] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<LegEntityExtendsSub[]>([]);
  /** 显示建议变化 */
  const [useSuggest, setUseSuggest] = useState<boolean>(() => {
    const lsValue = localStorage.getItem(LS.flightManageUseSuggest);
    return lsValue === null ? true : lsValue === "true";
  });
  const cabinEditorRef = useRef<Record<string, CabinAdjustHandle | null>>({});
  /** 记录生成的cmd, 用于执行, 每次生成cmd前重置 */
  const lastCmd = useRef<string>();
  const lastBatchCmd = useRef<FlightImCmdType[]>([]);
  const [feedBackLoading, setFeedBackLoading] = useState<boolean>(false);

  const handleChangePcfValue = useRefFunc(
    (v: number | null, r: LegEntityExtendsSub) => {
      const newRecord = cloneDeep(r);
      console.log("val, r", v, r);
      newRecord.editingPcfValue = v || 0;
      setSummaryData((s) => {
        const newData = arrayUpsert(
          s,
          newRecord,
          (item) => r.cabin === item.cabin && r.leg === item.leg
        );
        return newData;
      });
    }
  );

  const handleChangeInd = useRefFunc((v: boolean, r: LegEntityExtendsSub) => {
    const newItem = cloneDeep(r);
    newItem.editingInd = v;
    const newSummaryData = arrayUpsert(
      summaryData,
      newItem,
      (item) => r.cabin === item.cabin && r.leg === item.leg
    );
    const checkedInds = newSummaryData.filter((f) => f.editingInd === true);
    if (checkedInds.length === 1) {
      message.warn(getSharkText("config_page_main_cabin_ind_warn"));
    }
    setSummaryData(newSummaryData);
  });

  const handleChangeStopBooking = useRefFunc(
    (v: boolean, r: LegEntityExtendsSub) => {
      const newItem = cloneDeep(r);
      newItem.editingStopBooking = v;
      const newSummaryData = arrayUpsert(
        summaryData,
        newItem,
        (item) => r.cabin === item.cabin && r.leg === item.leg
      );
      setSummaryData(newSummaryData);
    }
  );

  const summaryColumns: Array<IDownloadHeader<LegEntityExtendsSub>> = useMemo(
    () =>
      [
        {
          title: getSharkText("config_page_cabin"),
          dataIndex: "cabin",
        },
        {
          title: getSharkText("config_page_leg"),
          dataIndex: "leg",
        },
        {
          title: getSharkText("config_page_available_sale"),
          dataIndex: "opn",
        },
        {
          title: getSharkText("config_page_max_open"),
          dataIndex: "max",
        },
        {
          title: getSharkText("config_page_layout"),
          dataIndex: "cap",
        },
        {
          title: getSharkText("config_page_sold_seats"),
          dataIndex: "tb",
        },
        {
          /** 超售 */
          title: getSharkText("key.pcfValue"),
          dataIndex: "editingPcfValue",
          render: (v = 0, r: LegEntityExtendsSub) => {
            const isChanged = r?.editingPcfValue !== r?.pcfValue;
            const isSameLeg = r?.leg === currentSeg.substring(0, 3);
            return (
              <InputNumber
                className={isChanged ? "changed" : ""}
                size="small"
                disabled={!isSameLeg}
                style={{
                  width: 50,
                  borderColor: isChanged ? "red" : "#d9d9d9",
                }}
                value={v}
                onChange={(val) => handleChangePcfValue(val, r)}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            );
          },
        },
        {
          /** 销售关闭 */
          title: "销售关闭",
          dataIndex: "editingStopBooking",
          render: (v: boolean, r: LegEntityExtendsSub) => {
            const isChanged = !!r?.editingStopBooking !== !!r?.stopBooking;
            const isSameLeg = r?.leg === currentSeg.substring(0, 3);
            return (
              <div style={{ textAlign: "center" }}>
                <Checkbox
                  className={isChanged ? "changed" : ""}
                  disabled={!isSameLeg}
                  checked={v}
                  onChange={(e) => handleChangeStopBooking(e.target.checked, r)}
                />
              </div>
            );
          },
        },
        {
          /** 物理舱嵌套 */
          title: getSharkText("key.legs.ind"),
          dataIndex: "editingInd",
          render: (v: boolean, r: LegEntityExtendsSub) => {
            const isChanged = r?.editingInd !== (r?.ind || "").includes("L");
            const isSameLeg = r?.leg === currentSeg.substring(0, 3);
            return (
              <div style={{ textAlign: "center" }}>
                <Checkbox
                  className={isChanged ? "changed" : ""}
                  disabled={!isSameLeg}
                  checked={v}
                  onChange={(e) => handleChangeInd(e.target.checked, r)}
                />
              </div>
            );
          },
        },
      ] as Array<IDownloadHeader<LegEntityExtendsSub>>,
    [currentSeg, handleChangeInd, handleChangePcfValue, handleChangeStopBooking]
  );

  useEffect(() => {
    if (instructionDestroy) {
      notification.destroy();
    }
    setInstructionDestroy(false);
  }, [instructionDestroy]);
  useEffect(() => {
    notification.destroy();
  }, [currentSeg, param]);

  const [data, setData] = useState<FlightROInfoType[]>([]);

  const [res, setRes] = useState<any>();

  const updateRecord = useRefFunc((flights: FlightROInfoType[]) => {
    ubtUtils.info("updateRecord", { flights, row, setRecord });
    if (!row || !setRecord) {
      return;
    }
    const newSeg = flights[0].segs.find((s) => s.line === row.route);
    if (!newSeg) return;
    const newPriceOri = parseFloat(newSeg.priceori);
    const newPriceSuggest = parseFloat(newSeg.pricesuggest);
    const newSendTime = newSeg.advisetime || "";
    if (newPriceOri !== row.rawPrice || newPriceSuggest !== row.suggestPrice) {
      setRecord({
        ...row,
        rawPrice: newPriceOri,
        suggestPrice: newPriceSuggest,
        sendTime: newSendTime.substring(0, 19),
      });
    }
  });

  const [{ isLoading }, doFetch] = useFetch({
    url: "soaProxy",
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    ext: {},
    lazey: true,
    onSuccess: (soaRes) => {
      if (soaRes.responseBody) {
        const tmpRes = JSON.parse(soaRes.responseBody);
        ubtUtils.info("soaProxy-getRoData-response: ", tmpRes);
        setRes(tmpRes);
        const tmpData = tmpRes.flights;
        setData(tmpData || []);
        updateRecord(tmpData);
        setSummaryData(genSummaryData(tmpData));
      }
    },
  });

  const logRequest = useRefFunc((ext: any) => {
    ubtTrace(
      {
        request: ext,
      },
      "o_flt_flightai_cabin_request"
    );
  });

  const refetch = useRefFunc(() => {
    setCurSeg(undefined);
    const request: GetRODataRequestType = {
      Head: { ClientAppID: CLIENT_APP_ID, ClientIP: CLIENT_IP },
      flights: [
        {
          flightno: param.flightNo,
          takeoffdate: param.takeoffdate,
          seg: param.route,
        },
      ],
      modelTrigger: true,
      isJob: null,
      userName: "",
      jobBatchNo: null,
      userGroup: userInfo.unitList?.length
        ? userInfo.unitList[0].id.toString()
        : "",
    };
    const ext = {
      serviceCode: "22299",
      operation: "getROData",
      requestBody: JSON.stringify(request),
    };
    logRequest(ext);
    doFetch({
      ext,
    });
  });

  useEffect(() => {
    if (!prev || !isSame(prev.param, param)) {
      refetch();
    }
  }, [prev, refetch, param]);

  // #region 生成和执行指令
  const onGenerateSuccess = useRefFunc(
    (response: any, cmdList: FlightImCmdType[] | string) => {
      if (!response || !response.ResponseStatus) {
        return;
      }
      if (response.ResponseStatus.Ack === "Success") {
        // const cmd = response.imCmd;
        if (typeof cmdList === "string") {
          lastCmd.current = cmdList;
        } else {
          lastBatchCmd.current = cmdList;
        }
        const canExecute = Boolean(res?.canExecute);
        notification.open({
          key: generateNotifyKey,
          message: getSharkText("config_page_request_success"),
          getContainer: () => notifyContainerRef.current || document.body,
          description: (
            <span>
              <span>调仓指令：</span>
              {typeof cmdList === "string" ? (
                <CMDCopy cmd={cmdList}></CMDCopy>
              ) : (
                cmdList.map((cmd) => (
                  <span key={cmd.flightno + cmd.line}>
                    <span>
                      {cmd.flightno} {cmd.line}
                    </span>
                    <CMDCopy cmd={cmd.cmd} />
                  </span>
                ))
              )}
              {/* 可用调仓指令时, 不显示浮层 */}
              <Popover
                visible={canExecute ? false : undefined}
                content={getSharkText(
                  "config_page_PaAiFlCoBlIn_function_not_open_yet"
                )}
              >
                <Button
                  disabled={!canExecute}
                  onClick={handleSubmitCmd}
                  type="primary"
                >
                  {getSharkText("config_page_PaAiFlCoBlIn_execute_instruction")}
                </Button>
              </Popover>
            </span>
          ),
          duration: 0,
        });
      } else if (
        response.ResponseStatus.Errors &&
        response.ResponseStatus.Errors.length
      ) {
        const err = response.ResponseStatus.Errors[0];
        if (err.ErrorCode === "2006") {
          message.error(getSharkText("config_page_insufficient_permissions"));
        } else {
          message.error(getSharkText("config_page_operation_failed"));
        }
      }
    }
  );

  const compareAdjustAndSubmitRes = useRefFunc(
    (adjust: SubCabinEntity[], res: SubCabinEntity[]): boolean => {
      if (!adjust || !adjust.length || !res || !res.length) {
        ubtTrace(
          {
            type: "submitResponse",
            result: "compareEmptyError",
            adjust,
            res,
          },
          ERROR_TRACE_NAME
        );
        return false;
      }
      if (res.length !== adjust.length) {
        ubtTrace(
          {
            type: "submitResponse",
            result: "compareLengthNotMatch",
            adjust,
            res,
          },
          ERROR_TRACE_NAME
        );
        return false;
      }
      // 检查前端的每个舱位在返回数据中是否都存在
      if (
        adjust.filter((a) => res.find((r) => r.cls === a.cls)).length !==
        adjust.length
      ) {
        // 返回数据舱位数量不足
        ubtTrace(
          {
            type: "submitResponse",
            result: "compareLengthNotMatch",
            adjust,
            res,
          },
          ERROR_TRACE_NAME
        );
        return false;
      }
      const diff = res.find((r) => {
        const adjustItem = adjust.find((a) => a.cls === r.cls);
        if (!adjustItem) {
          return true;
        }
        // 如果可售舱位不匹配, 或者L/P状态不匹配, 判定为不同
        if (
          r.ind &&
          adjustItem.ind &&
          (r.lsv !== `${adjustItem.lsv}` ||
            r.ind.includes("L") !== adjustItem.ind.includes("L") ||
            r.ind.includes("P") !== adjustItem.ind.includes("P"))
        ) {
          return true;
        }
        return false;
      });
      if (diff) {
        ubtTrace(
          {
            type: "submitResponse",
            result: "compareItemNotMatch",
            diffResponse: diff,
            diffAdjust: adjust.find((a) => a.cls === diff.cls),
            adjust,
            res,
          },
          ERROR_TRACE_NAME
        );
        console.warn(
          "response submit sold list find diff with adjust cabins:",
          diff
        );
      }
      return !diff;
    }
  );

  const validateSubmitRst = useRefFunc((submitResData: FlightROInfoType[]) => {
    if (!submitResData || !submitResData.length || !submitResData[0].segs) {
      ubtTrace(
        {
          type: "submitResponse",
          result: "submitResponseError",
          submitResData,
        },
        ERROR_TRACE_NAME
      );
      return false;
    }
    const resSoldList = submitResData[0].segs;
    const resSold = resSoldList.find((s) => s.line === currentSeg);
    const resSegList = resSold ? resSold.subcabins || [] : [];
    const curSegList = adjustingData.length
      ? adjustingData
      : curSeg?.subcabins || [];
    return compareAdjustAndSubmitRes(curSegList, resSegList);
  });

  // 执行成功后需要前端校验(因为使用后端调仓接口, 暂时不再做前端校验了)
  const onExecuteSuccess = useRefFunc(() => {
    ubtTrace(
      { type: "submitResponse", result: "executeSuccess" },
      "o_flt_flightai_flightmanage_trace"
    );
    refetch();
    message.success(
      getSharkText("config_page_execute_success"),
      successDuration
    );
  });

  /** 执行指令 */
  const onSubmitSuccess = useRefFunc(
    (
      response: any,
      bodyObj: { adviseId: string; roCacheKey: string; originCmd: string }
    ) => {
      const Errors = response?.ResponseStatus?.Errors;
      let isSuccessful = 0;
      if (Errors && Errors[0]) {
        isSuccessful = Errors[0].ErrorCode === "200" ? 1 : 0;
      }
      const ubtData = {
        userId: userInfo.user.id,
        userName: userInfo.user.name,
        cacheKey: bodyObj.roCacheKey,
        cmd: lastCmd.current,
        executeResult: response,
        adviseId: bodyObj.adviseId,
        originCmd: bodyObj.originCmd,
        isSuccessful,
        resultMessages: response?.ResponseStatus?.Errors,
      };
      ubtTrace({
        type: "submitResponse",
        content: response,
        ...ubtData,
      });
      if (response.ResponseStatus.Ack !== "Success") {
        ubtTrace(
          {
            type: "submitResponse",
            result: "responseError",
            ...ubtData,
            error: response.ResponseStatus,
          },
          ERROR_TRACE_NAME
        );
        message.error(
          getSharkText("config_page_command_execution_failed"),
          errorDuration
        );
        return;
      }
      if (!response.ResponseStatus.Errors) {
        ubtTrace(
          { type: "submitResponse", result: "noResCode", ...ubtData },
          ERROR_TRACE_NAME
        );
        message.error(
          getSharkText("config_page_return_information_error"),
          errorDuration
        );
        return;
      }
      const error = response.ResponseStatus.Errors[0];
      const codeNum = Number(error.ErrorCode);
      const msg = error.Message;
      if (!error || !codeNum) {
        ubtTrace(
          { type: "submitResponse", result: "noResCode" },
          ERROR_TRACE_NAME
        );
        message.error(
          getSharkText("config_page_return_information_error"),
          errorDuration
        );
        return;
      }
      if (codeNum >= 500) {
        ubtTrace(
          { type: "submitResponse", result: "RONotMatched", error },
          ERROR_TRACE_NAME
        );
        refetch();
        Modal.error({
          content:
            getSharkText("config_page_PaAiFlCoBlIn_ro_information_invalid") +
            `, ${msg}`,
        });
      } else if (codeNum >= 300) {
        ubtTrace(
          { type: "submitResponse", result: "executeFailed", error },
          ERROR_TRACE_NAME
        );
        message.error(
          getSharkText("config_page_command_execution_failed") + `, ${msg}`,
          errorDuration
        );
      } else if (codeNum === 200) {
        onExecuteSuccess();
        // 启动刷新实时价格的任务
        if (row && addFetchCMDPriceList) {
          const fetchCMDPriceItem = {
            row,
            cacheKey: bodyObj.roCacheKey || row.id,
          };
          ubtTrace(
            { type: "addFetchCMDPriceList", ...fetchCMDPriceItem },
            "o_flt_flightai_cabin_request"
          );
          addFetchCMDPriceList(fetchCMDPriceItem);
        }
      } else {
        ubtTrace(
          {
            type: "submitResponse",
            result: "responseError",
            error: response.ResponseStatus,
          },
          ERROR_TRACE_NAME
        );
        message.error(
          getSharkText("config_page_command_execution_failed"),
          errorDuration
        );
      }
    }
  );

  // #endregion

  useEffect(() => {
    if (!isLoading) {
      setJustLoaded(true);
    }
  }, [isLoading]);

  useEffect(() => {
    setCurrentSeg(route);
  }, [route]);

  // 根据返回的数据和当前的航段, 筛选现在展示和处理的航段

  /** 调仓数据 */
  const segs: SegEntity[] = useMemo(() => {
    if (data?.length) {
      return data[0].segs.filter((f) => f.line);
    }
    return EMPTY_ARRAY;
  }, [data]);

  /** 如果segs中不包含当前选中航段, 则选中第一段 */
  useEffect(() => {
    if (segs.length) {
      setCurrentSeg((cur) => {
        if (!segs.find((c) => c.line === cur)) {
          return segs[0].line;
        } else {
          return cur;
        }
      });
    }
  }, [data, currentSeg, segs]);

  useEffect(() => {
    if (segs?.length) {
      const matchSeg = segs.find((s) => s.line === currentSeg);
      setCurSeg(matchSeg);
    } else {
      setCurSeg(undefined);
    }
  }, [segs, currentSeg]);

  useEffect(() => {
    if (curSeg) {
      setSegList(curSeg.subcabins || []);
    } else {
      setSegList([]);
    }
  }, [curSeg]);

  const changeReset = useRefFunc((v: boolean) => {
    localStorage.setItem(LS.flightManageUseSuggest, v.toString());
    setUseSuggest(v);
  });

  // 重置调仓面板
  const handleReset = useRefFunc(() => {
    ubt(TRACE_ID.getName("layoutReset"));
    changeReset(false);
    if (cabinEditorRef.current) {
      Object.keys(cabinEditorRef.current).forEach((k) => {
        cabinEditorRef.current[k]?.reset();
      });
    }
    setSummaryData(genSummaryData(data));
  });

  const getChangeList = useRefFunc(() => {
    if (cabinEditorRef.current && curSeg?.line) {
      return cabinEditorRef.current[curSeg.line]?.getAdjusting();
    }
  });

  const getMainCabinChanges = useRefFunc(() => {
    const checkedInds = summaryData.filter((f) => f.editingInd);
    if (checkedInds.length === 1) {
      return null;
    }
    console.log("summaryData is", summaryData);
    const changed = summaryData.reduce((t, d) => {
      const tmp: AdjustMainCabinEntityExLeg = {
        cls: d.cabin,
        leg: d.leg,
        pcfValue: null,
        ind_l: "",
        stopBooking: "",
      };
      if (d.editingPcfValue !== d.pcfValue) {
        tmp.pcfValue = d.editingPcfValue;
      }
      if (d.editingInd !== d.ind.includes("L")) {
        tmp.ind_l = d.editingInd ? "+" : "-";
      }
      if (!!d.editingStopBooking !== !!d.stopBooking) {
        tmp.stopBooking = d.editingStopBooking ? "+" : "-";
      }
      if (tmp.pcfValue != null || tmp.ind_l || tmp.stopBooking) {
        t.push(tmp);
      }
      return t;
    }, [] as AdjustMainCabinEntityExLeg[]);
    return changed;
  });

  const getIMFlights = useRefFunc(() => {
    if (!curSeg || !cabinEditorRef.current || !curSeg.line) {
      return null;
    }
    const changes = cabinEditorRef.current[curSeg.line]?.getAdjusting() || [];
    const mainCabinChanges = getMainCabinChanges();
    if (mainCabinChanges == null) {
      message.error(getSharkText("config_page_main_cabin_ind_warn"));
      return null;
    }
    return [
      {
        flightno: param.flightNo,
        takeoffdate: param.takeoffdate,
        seg: {
          line: curSeg.line,
          subcabins: changes,
          subclassori: curSeg.subclassori,
          subclasssuggest: curSeg.subclasssuggest,
          priceori: curSeg.priceori,
          pricesuggest: curSeg.pricesuggest,
          maincabins: mainCabinChanges.filter(
            (f) => curSeg.line.substring(0, f.leg.length) === f.leg
          ),
        },
        layout: data[0]?.layout,
      },
    ];
  });

  const getIMBatchFlights = useRefFunc(() => {
    if (!curSeg || !cabinEditorRef.current || !curSeg.line) {
      return null;
    }
    const batchConfig = batchGenerateRef.current?.getBatchConfig();
    const lines = Object.keys(cabinEditorRef.current);
    const changes = lines.reduce((t, c) => {
      const change = cabinEditorRef.current[c]?.getAdjusting();
      if (change) {
        t.push({
          line: c,
          subcabins: change,
        });
      }
      return t;
    }, [] as Array<{ line: string; subcabins: AdjustSubCabinEntity[] }>);
    const mainCabinChanges = getMainCabinChanges();
    if (mainCabinChanges == null) {
      message.error(getSharkText("config_page_main_cabin_ind_warn"));
      return null;
    }
    if (!batchConfig?.isBatch || !batchConfig.config) {
      return null;
    }
    const config = batchConfig.config;
    console.log("changes", changes);
    const batchFlight: BatchAdjustFlightType[] = changes.map((c) => ({
      flightno: param.flightNo,
      startdate: config.dateRange[0],
      enddate: config.dateRange[1],
      dow: config.schedule,
      line: c.line,
      seg: {
        line: c.line,
        subcabins: c.subcabins,
        subclassori: curSeg.subclassori,
        subclasssuggest: curSeg.subclasssuggest,
        priceori: curSeg.priceori,
        pricesuggest: curSeg.pricesuggest,
        maincabins: mainCabinChanges.filter(
          (f) => c.line.substring(0, f.leg.length) === f.leg
        ),
      },
      layout: data[0]?.layout,
    }));
    return batchFlight;
  });

  const getIMRequestBody = useRefFunc(() => {
    const flights = getIMFlights();
    if (!flights) {
      return null;
    }
    const requestBody: IMCmdGenerateRequestType & {
      cacheKey: string;
      adviseId: string;
      originCmd: string;
    } = {
      Head: {
        ClientAppID: CLIENT_APP_ID,
        ClientIP: CLIENT_IP,
      },
      flights,
      cacheKey: data[0]?.cacheKey,
      adviseId: data[0]?.adviseId,
      originCmd: data[0]?.etermcmd,
      userName: "",
    };
    return requestBody;
  });
  const getIMBatchRequestBody = useRefFunc(() => {
    const flights = getIMBatchFlights();

    const processedLines = new Set();
    flights?.forEach((flight) => {
      if (flight && flight.seg) {
        if (processedLines.has(flight.line.substring(0, 3))) {
          flight.seg.maincabins = [];
        } else {
          processedLines.add(flight.line.substring(0, 3));
        }
      }
    });

    if (!flights) {
      return null;
    }
    const requestBody: IMBatchCmdGenerateRequestType = {
      Head: {
        ClientAppID: CLIENT_APP_ID,
        ClientIP: CLIENT_IP,
      },
      flights,
      userName: "",
    };
    return requestBody;
  });

  const batchGenerateRef = useRef<BatchGenerateRef>(null);

  const IMCmdGenerate = useRefFunc(() => {
    const batchConfig = batchGenerateRef.current?.getBatchConfig();
    console.log("batchConfig", batchConfig);
    lastBatchCmd.current = [];
    lastCmd.current = "";
    if (batchConfig?.isBatch) {
      if (!batchConfig.config) {
        return;
      }
      const requestContent = getIMBatchRequestBody();
      console.log("requestContent", requestContent);
      if (requestContent == null) {
        message.error("error");
        return;
      }
      services.IMBatchCmdGenerate(requestContent).then((r: any) => {
        if (r?.ResponseStatus.Ack === "Success") {
          try {
            const soaResponse: IMBatchCmdGenerateResponseType = JSON.parse(
              r.responseBody
            );
            onGenerateSuccess(soaResponse, soaResponse.imCmd);
          } catch (e) {}
        }
      });
    } else {
      const changeList = getChangeList();
      const requestContent = getIMRequestBody();
      if (requestContent == null) {
        message.error("error");
        return;
      }
      services.IMCmdGenerate(requestContent).then((r: any) => {
        if (r?.ResponseStatus.Ack === "Success") {
          try {
            const soaResponse: IMCmdGenerateResponseType = JSON.parse(
              r.responseBody
            );
            onGenerateSuccess(soaResponse, soaResponse.imCmd);
          } catch (e) {}
        }
      });

      ubt(TRACE_ID.getName("layoutGenerate"), {
        row: param,
        soldList: [
          {
            line: currentSeg,
            subCabins: changeList,
          },
        ],
      });
    }
  });

  const changeSeg = useRefFunc((activeKey: string) => {
    ubt(TRACE_ID.getName("layoutRoute"), activeKey);
    setCurrentSeg(segs.find((s) => s.line === activeKey)?.line || param.route);
  });

  const handleSubmitCmd = useRefFunc(() => {
    setInstructionDestroy(true);
    const batchConfig = batchGenerateRef.current?.getBatchConfig();
    const roCacheKey = data[0]?.cacheKey;
    const adviseId = data[0]?.adviseId;
    const originCmd = data[0]?.etermcmd;
    if (!roCacheKey) {
      // TODO 获取cacheKey失败时, 重新刷新数据, 需要提示用户重刷, 需要把弹窗关掉
      refetch();
      return;
    }
    if (batchConfig?.isBatch) {
      if (!batchConfig.config) {
        return;
      }
      const flights = getIMBatchFlights();

      const processedLines = new Set();
      flights?.forEach((flight) => {
        if (flight && flight.seg) {
          if (processedLines.has(flight.line.substring(0, 3))) {
            flight.seg.maincabins = [];
          } else {
            processedLines.add(flight.line.substring(0, 3));
          }
        }
      });

      if (!flights) {
        message.error("error");
        return;
      }
      const requestBody: IMBatchRequestType = {
        Head: {
          ClientAppID: CLIENT_APP_ID,
          ClientIP: CLIENT_IP,
        },
        flights,
        roCacheKey,
        confirmedCMD: lastBatchCmd.current,
        userName: "",
        websiteUserID: userInfo.user.id.toString(),
        websiteUserName: userInfo.user.name,
        userGroup: userInfo.unitList
          ? userInfo.unitList[0].id.toString()
          : "-1",
      };
      setFeedBackLoading(true);
      services
        .IMBatch(requestBody)
        .then((r) => {
          if (r?.ResponseStatus.Ack === "Success") {
            try {
              const soaResponse: IMResponseType = JSON.parse(r.responseBody);
              onSubmitSuccess(soaResponse, { adviseId, roCacheKey, originCmd });
            } catch (e: any) {
              ubtUtils.error(
                "ro batch submit error",
                "submitResponse",
                e.stack
              );
              ubtTrace(
                {
                  type: "submitResponse",
                  result: "compareEmptyError",
                  error: e,
                  res,
                },
                ERROR_TRACE_NAME
              );
            }
          } else {
            message.error("submit IMBatch error");
            ubtTrace(
              {
                type: "submitResponse",
                result: "compareEmptyError",
                error: r,
                res,
              },
              ERROR_TRACE_NAME
            );
          }
        })
        .finally(() => setFeedBackLoading(false));
    } else {
      const flights = getIMFlights();
      if (!flights || !lastCmd.current) {
        message.error("error");
        return;
      }
      const requestBody: IMRequestType = {
        Head: {
          ClientAppID: CLIENT_APP_ID,
          ClientIP: CLIENT_IP,
        },
        flights,
        executeCMD: true,
        roCacheKey,
        confirmedCMD: lastCmd.current,
        userName: "",
        websiteUserID: userInfo.user.id.toString(),
        websiteUserName: userInfo.user.name,
        userGroup: userInfo.unitList
          ? userInfo.unitList[0].id.toString()
          : "-1",
      };
      setFeedBackLoading(true);
      services
        .IM(requestBody)
        .then((r) => {
          if (r?.ResponseStatus.Ack === "Success") {
            try {
              const soaResponse: IMResponseType = JSON.parse(r.responseBody);
              onSubmitSuccess(soaResponse, { adviseId, roCacheKey, originCmd });
            } catch (e) {
              message.error("submit error");
              ubtTrace(
                {
                  type: "submitResponse",
                  result: "compareEmptyError",
                  error: e,
                  res,
                },
                ERROR_TRACE_NAME
              );
            }
          } else {
            message.error("submit IM error");
            ubtTrace(
              {
                type: "submitResponse",
                result: "compareEmptyError",
                error: r,
                res,
              },
              ERROR_TRACE_NAME
            );
          }
        })
        .finally(() => setFeedBackLoading(false));
    }
  });

  // 调仓数据报错信息
  if (
    res &&
    res.ResponseStatus &&
    res.ResponseStatus.Ack === "Failure" &&
    res.ResponseStatus.Errors &&
    res.ResponseStatus.Errors.length
  ) {
    const error = res.ResponseStatus.Errors[0];
    const { ErrorCode: code, Message: msg } = error;
    const description =
      code && msg
        ? `${getSharkText("config_page_error")}${code}: ${msg}`
        : getSharkText("config_page_no_data");
    return <Empty description={description} />;
  }

  return (
    <Spin spinning={isLoading}>
      <div className="ro-panel" id="ro-panel">
        <div
          style={{
            overflow: "auto",
          }}
        >
          <div
            className="ro-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px 0",
            }}
          >
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              {getSharkText("cabin_management")}
            </div>
            <div>
              <CloseOutlined onClick={closeRoPanel} />
            </div>
          </div>
          <div>
            <Table
              className="micro-table"
              columns={infoColumns}
              dataSource={data}
              pagination={false}
              size="small"
              bordered
            />
            <div style={{ fontWeight: 600, padding: "15px 0 5px" }}>
              {getSharkText("cabin_layout")}
            </div>
            <Table
              data-testid="cabin-layout-table"
              className="micro-table"
              columns={summaryColumns}
              dataSource={summaryData}
              rowKey="key"
              size="small"
              pagination={false}
            />
            <div style={{ padding: "15px 0 0", fontWeight: 600 }}>
              <span>{getSharkText("cabin_adjustment")}</span>
              {res?.canSetSmallCabin ? (
                <Button
                  size="small"
                  style={{ marginLeft: 10 }}
                  onClick={() => setShowSubCabin((c) => !c)}
                >
                  {getSharkText("config_page_sub_cabin_adjustment")}
                </Button>
              ) : undefined}
            </div>
            {segs?.length ? (
              <>
                <Tabs
                  key={`${param.takeoffdate}_${param.flightNo}`}
                  id="ro-panel-tabs"
                  defaultActiveKey={currentSeg}
                  activeKey={currentSeg}
                  tabBarGutter={20}
                  onChange={changeSeg}
                  tabBarExtraContent={
                    <Checkbox
                      checked={useSuggest}
                      onChange={(e) => changeReset(e.target.checked)}
                    >
                      {/* 显示建议变化 */}
                      {getSharkText("config_page_show_suggestion")}
                    </Checkbox>
                  }
                >
                  {segs.map((seg: SegEntity) => (
                    <TabPane
                      tab={insertIntoString(seg.line, "-", 3)}
                      key={seg.line}
                    >
                      <AdjustCabin
                        ref={(r) => {
                          cabinEditorRef.current[seg.line] = r;
                        }}
                        flightNo={param.flightNo}
                        takeoffdate={param.takeoffdate}
                        cacheKey={data[0]?.cacheKey}
                        layoutRestriction={data[0]?.layoutRestriction}
                        hasDiffSuggestPrice={hasDiffSuggestPrice}
                        curSeg={seg}
                        useSuggestAdjust={useSuggest}
                      ></AdjustCabin>
                    </TabPane>
                  ))}
                </Tabs>
              </>
            ) : undefined}
            <Affix offsetBottom={15}>
              <Row
                style={{
                  margin: "10px 0",
                  backgroundColor: "var(--content_bg)",
                }}
                justify="space-between"
              >
                <Col>
                  <BatchGenerate
                    ref={batchGenerateRef}
                    takeoffdate={param.takeoffdate}
                    maxDate={maxDate}
                    isBatchPage={!row}
                  />
                </Col>
                <Col>
                  <Button
                    onClick={handleReset}
                    style={{ marginRight: 5, padding: "4px 10px" }}
                  >
                    <UndoOutlined
                      style={{
                        transform: "rotate(180deg)",
                        position: "relative",
                        top: -2,
                      }}
                    />
                    {getSharkText("config_page_restore")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={IMCmdGenerate}
                    style={{ padding: "4px 10px" }}
                  >
                    {getSharkText("config_page_PaAiFlCoBlIn_generate")}
                  </Button>
                </Col>
              </Row>
            </Affix>
            <div ref={notifyContainerRef} style={{ textAlign: "left" }}></div>
          </div>
        </div>
        <div className={`sub-cabin ${showSubCabin ? "show" : "hide"}`}>
          <div className="sub-cabin-content">
            <CloseOutlined
              style={{
                cursor: "pointer",
                float: "left",
                margin: "5px 5px",
              }}
              onClick={() => setShowSubCabin(false)}
            />
            {getSharkText("config_page_sub_cabin_adjustment")}
            <SubCabin
              onCancel={() => setShowSubCabin(false)}
              row={param}
              seg={currentSeg}
              roCacheKey={data[0]?.cacheKey}
              isShow={showSubCabin}
              adviseId={data[0]?.adviseId || null}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default BlackScreen;

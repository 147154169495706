import React, { useState, useEffect, useCallback } from "react";

interface IProps {
  setVerifyInfo: (v: any) => void;
  /**
   * 同个页面内显示多个验证码, ID必须不同
   */
  id?: string;
}

const VerifyCode = (props: IProps) => {
  const { setVerifyInfo, id = "verification-code" } = props;
  const [slideObj, setSlideObj] = useState();
  const init = useCallback(() => {
    if (slideObj) {
      //   @ts-ignore // 如果涉及在同一个div多次渲染验证码，可以加个判断来清除
      slideObj.clear();
    }
    setSlideObj(
      //   @ts-ignore
      new jigsawVerification({
        // 验证码DIV的id
        id,
        // appId
        appId: "100026391",
        // businessSite
        businessSite: "flightai_login_online_pic",
        // 可选填像素字符串或百分数字符串，如'280px'或'80%'，默认值'280px'
        width: "320px",
        // 滑块长度，可选，填像素字符串，如'40px'，默认值'40px'
        height: "20px",
        // 语种，默认‘zh-CN’（简体中文）
        language: "zh-CN",
        // language: "en-US",
        // select_language: "en-US",
        // 选字语种，默认‘zh-CN’（简体中文）
        mode: "float",
        resultHandler: (e: any) => {
          setVerifyInfo({
            token: e.token,
            version: e.version,
            requestId: e.rid,
          });
        },
      })
    );
  }, [setVerifyInfo, slideObj]);

  useEffect(() => {
    if (window.TARGET_PLATFORM_CONFIG) return;
    setTimeout(() => {
      init();
    }, 200);
  }, []);

  const refresh = () => {
    if (slideObj) {
      //   @ts-ignore
      slideObj.refresh();
    }
  };

  if (window.TARGET_PLATFORM_CONFIG) {
    return <div></div>;
  }

  return (
    <div className="form-group has-feedback validcode-div">
      <div id={id}></div>
    </div>
  );
};

export default VerifyCode;

import { useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import {
  FDDataset,
  FDDatasetCol,
  FDMenu,
  FDPageConfig,
  GetFDDatasetColResponseType,
} from "@ctrip/flt-bi-flightai-base";
import { useRef } from "react";
import { ResUI } from "Interface";

interface Services {
  getMenuTree: (parentId?: number) => Promise<any>;
  upsertMenuTree: (node: FDMenu) => Promise<any>;
  deleteMenu: (menuId: number) => Promise<any>;
  getPageConfig: (menuId: number) => Promise<any>;
  upsertPageConfig: (pageConfig: FDPageConfig) => Promise<any>;
  getDataset: () => Promise<any>;
  upsertDataset: (ds: FDDataset) => Promise<any>;
  deleteDataset: (dsId: number) => Promise<any>;
  getDatasetColsOnEdit: (dsId: number) => Promise<any>;
  getDatasetColsOnQuery: (
    dsId?: number,
    dsName?: string
  ) => Promise<ResUI<GetFDDatasetColResponseType>>;
  upsertDatasetCols: (cols: FDDatasetCol[]) => Promise<any>;
  genCols: (ds: FDDataset) => Promise<any>;
  deleteDatasetCol: (colId: number) => Promise<any>;
}

export const useServices = (): Services => {
  // #region 获取目录树
  const [, doFetchMenuTree] = useFetch({
    url: "GetFdUserMenuTree",
    head: {},
    query: null,
    ext: {},
    lazey: true,
  });
  const getMenuTree = useRefFunc(async (parentId?: number) => {
    return doFetchMenuTree({ ext: { parentId } });
  });
  // #endregion
  // #region 新建/编辑目录树
  const [, doUpsertMenuTree] = useFetch({
    url: "UpsertFdMenu",
    head: {},
    query: null,
    ext: {},
    lazey: true,
  });
  const upsertMenuTree = useRefFunc(async (node: FDMenu) => {
    return doUpsertMenuTree({ ext: { menu: node } });
  });
  // #endregion
  // #region 删除目录树
  const [, doDeleteMenu] = useFetch({
    url: "DeleteFdMenu",
    head: {},
    query: null,
    ext: {},
    lazey: true,
  });
  const deleteMenu = useRefFunc(async (menuId: number) => {
    return doDeleteMenu({ ext: { menuId } });
  });
  // #endregion
  // #region 获取页面配置
  const [, doFetchPageConfig] = useFetch({
    url: "GetFDPageConfig",
    head: {},
    query: null,
    ext: {},
    lazey: true,
  });
  const getPageConfig = useRefFunc(async (menuId: number) => {
    return doFetchPageConfig({ ext: { menuId } });
  });
  // #endregion
  // #region 新建/编辑页面配置
  const [, doUpsertPageConfig] = useFetch({
    url: "UpsertFDPageConfig",
    head: {},
    query: null,
    ext: {},
    lazey: true,
  });
  const upsertPageConfig = useRefFunc(async (pageConfig: FDPageConfig) => {
    return doUpsertPageConfig({ ext: { pageConfig } });
  });
  // #endregion

  // #region 获取数据集
  const [, doFetchDataset] = useFetch<FDDataset[]>({
    url: "GetFDDataset",
    head: {},
    ext: {},
    lazey: true,
  });
  const getDataset = useRefFunc(async () => {
    return doFetchDataset({ ext: {} });
  });
  // #endregion

  // #region 创建/更新数据集
  const [, doUpsertDataset] = useFetch({
    url: "UpsertFDDataset",
    head: {},
    ext: {},
    lazey: true,
  });
  const upsertDataset = useRefFunc(async (dataset: FDDataset) => {
    return doUpsertDataset({ ext: { dataset } });
  });
  // #endregion

  // #region 删除数据集
  const [, doDeleteDataset] = useFetch({
    url: "DeleteFDDataset",
    head: {},
    ext: {},
    lazey: true,
  });

  const deleteDataset = useRefFunc(async (id: number) => {
    return doDeleteDataset({ ext: { id } });
  });
  // #endregion

  // #region 获取数据表的列属性
  const [, doFetchDatasetColumns] = useFetch({
    url: "GetFDDatasetCol",
    head: {},
    ext: {},
    lazey: true,
    isSingleInstance: false,
  });
  const getDatasetColsOnEdit = useRefFunc(async (datasetId: number) => {
    return doFetchDatasetColumns({ ext: { datasetId, permission: 0 } });
  });
  const getDatasetColsOnQuery = useRefFunc(
    async (datasetId?: number, datasetName?: string) => {
      const ext: any = { permission: -1 };
      if (datasetId != null) ext.datasetId = datasetId;
      if (datasetName != null) ext.datasetName = datasetName;
      return doFetchDatasetColumns({
        ext,
      }) as unknown as Promise<ResUI<GetFDDatasetColResponseType>>;
    }
  );
  // #endregion

  // #region 预览数据集的列属性
  const [, doPreviewDatasetCols] = useFetch({
    url: "GenFDDatasetCols",
    head: {},
    ext: {},
    lazey: true,
  });
  const genCols = useRefFunc(async (dataset: FDDataset) => {
    return doPreviewDatasetCols({ ext: { dataset } });
  });
  // #endregion

  // #region 创建/更新datasetCols的属性
  const [, doUpsertDatasetCols] = useFetch({
    url: "UpsertFDDatasetCol",
    head: {},
    ext: {},
    lazey: true,
  });
  const upsertDatasetCols = useRefFunc(async (datasetCols: FDDatasetCol[]) => {
    return doUpsertDatasetCols({ ext: { datasetCols } });
  });
  // #endregion
  // #region 删除col
  const [, doDeleteDatasetCol] = useFetch({
    url: "DeleteFDDatasetCol",
    head: {},
    ext: {},
    lazey: true,
  });
  const deleteDatasetCol = useRefFunc(async (colId: number) => {
    return doDeleteDatasetCol({ ext: { colId } });
  });
  // #endregion

  const services = useRef({
    getMenuTree,
    upsertMenuTree,
    deleteMenu,
    getPageConfig,
    upsertPageConfig,
    getDataset,
    upsertDataset,
    deleteDataset,
    getDatasetColsOnEdit,
    getDatasetColsOnQuery,
    upsertDatasetCols,
    genCols,
    deleteDatasetCol,
  }).current;

  return services;
};

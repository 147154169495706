import { TOKEN, UID } from "Constants";

export const setCookie = (name: string, value: string, days = 1): void => {
  const exp = new Date();
  exp.setTime(new Date().getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toUTCString() + ";path=/";
};

export const getCookie = (name: string): string | null => {
  const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  const arr = document.cookie.match(reg);
  if (arr != null) {
    return unescape(arr[2]);
  }
  return null;
};

export const delCookie = (name: string): void => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null) {
    document.cookie =
      name + "=" + cval + ";expires=" + exp.toUTCString() + ";path=/";
  }
};

export const getCookieUID = (): string | null => {
  try {
    return getCookie(UID);
  } catch (e) {
    console.error("get cookie error to login :", e);
    window.location.replace("/login");
  }
  return null;
};
export const getCookieToken = (): string | null => {
  try {
    return getCookie(TOKEN);
  } catch (e) {
    console.error("get cookie token error to login :", e);
    window.location.replace("/login");
  }
  return null;
};

import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  Dimension,
  Filter,
  FilterValue,
  Measure,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { IFlightRecord } from "Page/AI/FlightManage/FlightManageInterface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import { arrayUpsert, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import CardItemSummary, { OverviewRes } from "./CardItemSummary";
import CardItem, { CardItemDataEx } from "./CardItem";
import { Select, Spin } from "antd";
import moment, { Moment } from "moment";
import { getCompareDate } from "Components/Dates/CompareCom";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import { getServer } from "Service/server";
import { RangeObject, SystemType } from "Interface";
import { round } from "lodash";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

/** 提前售卖明细表的数据集的ID */
const DATASET_NAME = "航班管理弹窗卡片";
const TAKEOFF_NAME = "takeoffdate";
const FLIGHTNO_NAME = "flightno";
const ROUTE_NAME = "route";
const TKT_CNT_NAME = "tkt_cnt";
const REVENUE_NAME = "revenue";
const AVG_PRICE_NAME = "avg_price";
const DISTANCE_NAME = "distance";
const CAP_NAME = "cap";
const SOLD_SEATS_NAME = "sold_seats";
const AHEAD_HOUR = "ahead_hour";
const SEAT_KILOMETER_INCOME_NAME = "seat_kilometer_income";
const LF_NAME = "lf";

const compareYearList = [
  getSharkText("compare_YEAR_per_name"),
  getSharkText("compare_YEAR_BEFORE_LAST_per_name"),
  getSharkText("compare_YEAR_LAST_LAST_per_name"),
  getSharkText("compare_YEAR_4_BEFORE_per_name"),
];

export interface Preview {
  acc_revenue: number;
  avg_price: number;
  cap: number;
  collecttime: string;
  compute_time: string;
  distance: number;
  flightno: string;
  route: string;
  saledseat: number;
  takeoffdate: string;
  true_compute_time: string;
}

export interface ResData {
  takeoffdate: string;
  flightno: string;
  route: string;
  collectTime: string;
  ahead_hour: number;
  m_exp_per_hour_income: number;
  SUM_distance: number;
  SUM_cap: number;
  SUM_revenue: number;
  SUM_avg_price: number;
  SUM_sold_seats: number;
  SUM_tkt_cnt: number;
  对比_abs_SUM_distance: number;
  对比_grate_SUM_distance: number;
  对比_abs_SUM_cap: number;
  对比_grate_SUM_cap: number;
  对比_abs_SUM_revenue: number;
  对比_grate_SUM_revenue: number;
  对比_abs_m_exp_per_hour_income: number;
  对比_grate_m_exp_per_hour_income: number;
  对比_abs_SUM_avg_price: number;
  对比_grate_SUM_avg_price: number;
  对比_abs_SUM_sold_seats: number;
  对比_grate_SUM_sold_seats: number;
  对比_abs_SUM_tkt_cnt: number;
  对比_grate_SUM_tkt_cnt: number;
  对比_abs_AVG_tkt_cnt: number;
  对比_abs_AVG_revenue: number;
  对比_abs_AVG_distance: number;
  对比_abs_AVG_cap: number;
}

const CardList: Array<{
  precision: number;
  valueType: "num" | "percentage";
  [key: string]: any;
}> = [
  {
    // 座公里收入
    cardName: getSharkText("config_page_seat_kilometer_income"),
    cardCode: SEAT_KILOMETER_INCOME_NAME,
    valueKey: (d: ResData) => {
      return d.SUM_revenue / (d.SUM_distance * d.SUM_cap);
    },
    compareValueKey: (d: ResData) => {
      return (
        d.对比_abs_AVG_revenue / (d.对比_abs_AVG_distance * d.对比_abs_AVG_cap)
      );
    },
    valueType: "num",
    precision: 3,
  },
  {
    cardName: getSharkText("key.loadfactor"),
    cardCode: LF_NAME,
    valueKey: (d: ResData) => {
      return d.SUM_sold_seats / d.SUM_cap;
    },
    compareValueKey: (d: ResData) => {
      return d.对比_abs_SUM_tkt_cnt / d.对比_abs_SUM_cap;
    },
    valueType: "percentage",
    precision: 2,
  },
  {
    cardName: getSharkText("config_page_average_ticket_price"),
    cardCode: AVG_PRICE_NAME,
    valueKey: (d: ResData) => {
      return d.SUM_avg_price;
    },
    compareValueKey: (d: ResData) => {
      return d.对比_abs_SUM_tkt_cnt === 0
        ? 0
        : d.对比_abs_SUM_revenue / d.对比_abs_SUM_tkt_cnt;
    },
    valueType: "num",
    precision: 0,
  },
  {
    cardName: getSharkText("config_page_seat_number"),
    cardCode: SOLD_SEATS_NAME,
    valueKey: (d: ResData) => d.SUM_sold_seats,
    compareValueKey: (d: ResData) => d.对比_abs_AVG_tkt_cnt,
    valueType: "num",
    precision: 0,
  },
];

const CARD_TYPE_LIST = ["overview", "detail"] as const;
export type CardType = (typeof CARD_TYPE_LIST)[number];

export interface CardContainerProps {
  record: IFlightRecord;
  type: CardType;
  compareType?: "year" | "week";
}

/** 航班管理卡片入口 */
const CardContainer = (props: CardContainerProps): ReactElement => {
  const { record, type, compareType } = props;
  const services = useServices();
  const [resData, setResData] = useState<ResData>();
  const [overviewRes, setOverviewRes] = useState<OverviewRes>();
  const [datasetId, setDatasetId] = useState<number | null>(null);
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [compareDays, setCompareDays] = useState<number>(
    compareType === "week" ? -28 : 0
  );
  const [wowType, setWowType] = useState<"single" | "accumulated">("single");
  const [compareYear, setCompareYear] = useState<number>(
    compareType === "year" ? moment(record.takeoffdate).year() - 1 : 0
  );

  // #region 数据查询
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(undefined, DATASET_NAME)
      .then(
        (r) => {
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
            if (r.data?.length) {
              setDatasetId(r.data[0].datasetId);
            }
          }
        },
        (e1) => {
          console.log("error: ", e1);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading: isLoadingPreview }, doFetchPreview] = useFetch({
    server: getServer(SystemType.airlines),
    url: "flightRealtimeQuery",
    head: {},
    ext: {},
    useCache: true,
    lazey: true,
  });

  const [{ isLoading }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
  });

  const dimensions: Dimension[] = useMemo(
    () => [
      {
        columnName: FLIGHTNO_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: ROUTE_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: AHEAD_HOUR,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ],
    []
  );

  const measures: Measure[] = useMemo(
    () => [
      {
        id: "tktCnt",
        columnName: TKT_CNT_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: DISTANCE_NAME,
        columnName: DISTANCE_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: CAP_NAME,
        columnName: CAP_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "revenue",
        columnName: REVENUE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "SUM",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "avg_price",
        columnName: AVG_PRICE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "SUM",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "avg_tkt_cnt",
        columnName: TKT_CNT_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "AVG",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "avg_distance",
        columnName: DISTANCE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "AVG",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "avg_cap",
        columnName: CAP_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "AVG",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "avg_revenue",
        columnName: REVENUE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "AVG",
          },
          formatConfig: null,
          comparison: null,
        },
      },
    ],
    []
  );

  const filters: Filter[] = useMemo(() => {
    const takeoffTime = moment(record.takeoffdate + " " + record.takeofftime);
    takeoffTime.set("minute", 0).set("second", 0).set("millisecond", 0);
    const aheadHours = moment().diff(takeoffTime, "hours");
    const tmp: Filter[] = [
      {
        columnName: TAKEOFF_NAME,
        filterConfig: [
          {
            calculate: "between",
            argsType: "string",
            stringArgs: [record.takeoffdate || "", record.takeoffdate || ""],
          },
        ],
      },
      {
        columnName: FLIGHTNO_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.flightNo],
          },
        ],
      },
      {
        columnName: ROUTE_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.route],
          },
        ],
      },
      {
        columnName: AHEAD_HOUR,
        filterConfig: [
          {
            calculate: ">=",
            argsType: "number",
            numberArgs: [aheadHours],
          },
        ],
      },
    ];
    return tmp;
  }, [record.flightNo, record.route, record.takeoffdate, record.takeofftime]);

  const refetch = useCallback(() => {
    const flightNo = record.flightNo;
    setResData(undefined);
    if (!flightNo) {
      return;
    }
    if (!datasetCols.length || datasetId == null) {
      return;
    }
    const wowCompareBaseDates: [Moment, Moment] = [
      moment(),
      moment().add(6, "d"),
    ];
    const yoyCompareBaseDates: [Moment, Moment] = [
      moment(record.takeoffdate),
      moment(record.takeoffdate),
    ];
    let compareDates: RangeObject = null;
    if (compareDays) {
      const tkMoment = moment(record.takeoffdate);
      for (let i = 0; i < 7; i++) {
        const tmp = moment().add(-28 + i, "d");
        if (tmp.weekday() === tkMoment.weekday()) {
          compareDates = [tmp, tmp];
          break;
        }
      }
    } else if (compareYear) {
      compareDates = getCompareDate({
        currentValue: yoyCompareBaseDates,
        yoyYear: compareYear,
        yoyType: "weekAlign",
      });
    }
    const compareDateStr = compareDates
      ? compareDates.map((c) => c?.format(DATE_FORMAT) ?? null)
      : null;
    let compareDateRst = compareDateStr;
    if (compareDateStr) {
      const beforeTkDay = moment(record.takeoffdate)
        .add(-1, "d")
        .format(DATE_FORMAT);
      const endCompareDay =
        wowType === "single" ? compareDateStr[1] : beforeTkDay;
      compareDateRst =
        compareDays && compareDateStr
          ? [compareDateStr[0], endCompareDay]
          : compareDateStr;
    }
    const compareDateFilter: FilterValue | undefined = compareDateStr
      ? {
          filterId: "compareFilter",
          filterType: "date",
          argOfReports: [
            {
              columnName: TAKEOFF_NAME,
              panelId: "card",
              values: {
                compare: compareDateRst,
                current: [record.takeoffdate, record.takeoffdate],
                granularity: "1d",
              },
            },
          ],
        }
      : undefined;
    doFetchPreview({
      ext: {
        startTakeoffDate: record.takeoffdate,
        endTakeoffDate: record.takeoffdate,
        routes: [record.route],
        flightNos: [record.flightNo],
        endTime: null,
      },
    }).then((res) => {
      if (res?.ResponseStatus?.Ack === "Success") {
        const list = JSON.parse(res.data);
        if (list && list.length) {
          const item: Preview = list[0];
          setOverviewRes({
            SUM_revenue: item.acc_revenue,
            collectTime: item.collecttime,
          });
          if (type === "overview") {
            return;
          }
          const tkTime = moment(record.takeoffdate);
          tkTime.set("hour", 24);
          const collectTime = moment(item.collecttime);
          collectTime.set("minute", 0);
          const diffSeconds = tkTime.diff(collectTime, "second");
          const aheadHour = round(diffSeconds / 3600);
          const aheadHourFilter: Filter = {
            columnName: AHEAD_HOUR,
            filterConfig: [
              {
                calculate: "=",
                argsType: "number",
                numberArgs: [aheadHour],
              },
            ],
          };
          const filtersWithHour = arrayUpsert(
            filters,
            aheadHourFilter,
            (a) => a.columnName === aheadHourFilter.columnName
          );
          const requestBuild = new RequestBuilder({
            datasetId,
            columns: datasetCols,
            dimensions,
            measures,
            chartFilters: filtersWithHour,
            sorters: [],
            containerFilters: compareDateFilter ? [compareDateFilter] : [],
            preserveComparatorGroups: true,
          });
          const { encrypted } = requestBuild.getRequestBody();
          if (!encrypted) return;
          doFetch({
            debugId:
              type + (compareDays ? "wow" : "yoy") + encrypted.slice(-16),
            ext: {
              datasetId,
              colIds: [],
              req: encrypted,
            },
          }).then((res2) => {
            if (res2?.ResponseStatus?.Ack === "Success") {
              const res3 = JSON.parse(res2.data);
              if (res3.status === 40000) {
                throw new Error("40000");
              }
              const rows = res3.rows;
              const headers = res3.headers;
              const source = DataRow2ListMap(
                rows,
                headers
              ) as unknown as ResData[];
              if (source.length) {
                const resItem: ResData = source[0];
                resItem.SUM_revenue = item.acc_revenue;
                resItem.SUM_avg_price = item.avg_price;
                resItem.SUM_cap = item.cap;
                resItem.SUM_distance = item.distance;
                resItem.SUM_sold_seats = item.saledseat;
                resItem.collectTime = item.collecttime;
                setResData(resItem);
              } else {
                setResData(undefined);
              }
            }
          });
        }
      }
    });
  }, [
    compareDays,
    compareYear,
    datasetCols,
    datasetId,
    dimensions,
    doFetch,
    doFetchPreview,
    filters,
    measures,
    record.flightNo,
    record.route,
    record.takeoffdate,
    type,
    wowType,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);
  const values: any = useMemo(() => {
    if (!resData) {
      return EMPTY_ARRAY;
    }
    return resData;
  }, [resData]);

  // #endregion

  const data = useMemo(() => {
    if (!resData) {
      return [];
    }
    if (type === "overview") {
      return [];
    }
    const tmp: CardItemDataEx[] = CardList.map((c) => {
      return {
        cardName: c.cardName,
        cardCode: c.cardCode,
        cardHint: "",
        cardValue: c.valueKey(values),
        compareValue: c.compareValueKey(values),
        avgValue: 0,
        yoyValue: c.compareValueKey(values),
        valueType: c.valueType,
        precision: c.precision,
        collectTime: resData.collectTime,
      };
    });
    return tmp;
  }, [resData, type, values]);

  const cardTitle = useMemo(() => {
    if (type === "overview") {
      return null;
    }
    const weekOptions = [
      {
        label: getSharkText("config_page_ring_ratio_four_weeks"),
        value: -28,
      },
    ];
    const yearOption = compareYearList.map((c, i) => ({
      value: moment(record.takeoffdate).year() - i - 1,
      label: c,
    }));
    if (compareType === "year") {
      return (
        <Select
          value={compareYear}
          onChange={setCompareYear}
          options={yearOption}
          style={{ width: 120 }}
        ></Select>
      );
    } else if (compareType === "week") {
      return (
        <Select
          value={compareDays}
          onChange={setCompareDays}
          options={weekOptions}
          style={{ width: 120 }}
        />
      );
    }
    return null;
  }, [compareDays, compareType, compareYear, record.takeoffdate, type]);

  return type === "overview" ? (
    <Spin spinning={isLoading || isLoadingPreview}>
      <CardItemSummary data={overviewRes} />
    </Spin>
  ) : (
    <Spin spinning={isLoading || isLoadingPreview}>
      <CardItem
        title={cardTitle}
        data={data}
        comparePrefix={compareType === "year" ? "同比" : "环期"}
      />
    </Spin>
  );
};
export default CardContainer;

import React, { useEffect } from "react";
import { Row, Empty, Spin, Col, Tooltip } from "antd";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import CardItem from "./CardItem";
import { CardItemData, Area } from "Interface";
import Refetch from "Components/Refetch";
import _ from "lodash";
import { getServer } from "Service/server";
import { IHolidayQueryExt } from "../HolidayAnalysisCommon";

interface CardsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  curCardCode: string;
  setCurCard: (card: CardItemData) => void;
  area?: Area;
  setHasCardData: (has: boolean) => void;
  queryExt?: IHolidayQueryExt;
}

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    setCurCard,
    curCardCode,
    area,
    setHasCardData,
    queryExt,
  } = props;
  const [globalState] = useGlobal();
  const { queryCondition, helpVisibility, airlinesQueryCondition, systemType } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = queryExt;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      ...ext,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({ query, ext: { ...ext } });
  };

  useEffect(() => {
    setHasCardData(false);
    if (queryExt && queryExt.holiday) {
      refetch();
    }
  }, [query, area, queryExt]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (!curCardCode) {
        setCurCard(data[0]);
      }
      setHasCardData(true);
    }
  }, [data]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Tooltip
      title={getSharkText("card.switch_card.explaination")}
      getPopupContainer={() => document.getElementById("main") || document.body}
      visible={helpVisibility}
      placement="bottomLeft"
    >
      <Spin spinning={isLoading}>
        <div className="cards-carousel">
          <div className="cards-carousel-content">
            {!_.isEmpty(data) ? (
              <Row
                gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
                className="cards-row"
              >
                {data.map((item: any, index: number) => (
                  <Col xl={8} key={index} className="cards-column">
                    <CardItem
                      data={item}
                      selectedCode={curCardCode}
                      setCurCard={setCurCard}
                      systemType={systemType}
                      compareType={
                        isAirportMode(systemType)
                          ? queryCondition.compareType
                          : airlinesQueryCondition.compareType
                      }
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Spin>
    </Tooltip>
  );
};

export default Cards;

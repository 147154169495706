import moment, { Moment } from "moment";
import {
  Module,
  UserList,
  SystemType,
  Role,
  AirportOrAirline,
  Area,
  IDateMode,
  ECompareTypeV2,
} from "Interface";
import _, {
  cloneDeep,
  difference,
  groupBy,
  isEmpty,
  isFinite,
  isNumber,
  merge,
  uniq,
  uniqBy,
} from "lodash";
import min from "lodash/min";
import {
  UID,
  TOKEN,
  BINDING,
  PERCENT_VALUES,
  MULTI_AIRPORT,
  COMPARE_TYPE_PER_NAME,
  ctripDefaultAirport,
  ctripDefaultAirlines,
} from "Constants";
import { delCookie } from "./cookie";
import { IGlobalState } from "Store";
import { CellObject } from "xlsx/types";
import { SortOrder } from "antd/lib/table/interface";
import { Dispatch, SetStateAction } from "react";
import ubtUtils from "./ubtUtils";
import { getSharkText } from "./i18nGlobal";

export * from "./cookie";
export * from "./useSessionStorage";
export * from "./xlsx";
export * from "./charts";
export * from "./watermark";
export * from "./useFetch";

window._ = _;

/**
 *  lodash的isNumber要求参数必须是数值类型, 我们需要检测字符串是否是数值时, 使用这个isNumeric函数
 * @param v 被检测的值
 * @returns 检测结果
 */
export const isNumeric = (v: string | number | null | undefined): boolean => {
  if (typeof v === undefined) {
    return false;
  }
  return /^-?[\d.]+(?:e-?\d+)?$/.test(`${v}`);
};
/**
 * 检测一个值是否是数值并且不为0, 多用于被除数的检测
 * @param v 被检测数
 */
export const isNotZeroNumeric = (
  v: string | number | null | undefined
): boolean => {
  return isNumeric(v) && !!v && Number(v) !== 0;
};
export const getDateRange = (dateMode: IDateMode): [Moment, Moment] | null => {
  let date: [Moment, Moment] | null;
  switch (dateMode) {
    case "currentMonth":
      date = [moment().startOf("month"), moment().endOf("month")];
      break;
    case "lastWeek":
      date = [moment().add(-7, "d"), moment().add(-1, "d")];
      break;
    case "lastMonth":
      date = [
        moment(new Date()).add(-30, "days"),
        moment(new Date()).add(-1, "days"),
      ];
      break;
    case "lastThreeMonth":
      date = [
        moment(new Date()).add(-3, "months"),
        moment(new Date()).add(-1, "days"),
      ];
      break;
    case "lastYear":
      date = [
        moment(new Date()).add(-1, "year"),
        moment(new Date()).add(-1, "days"),
      ];
      break;
    case "currentDay":
      date = [moment(new Date()), moment(new Date())];
      break;
    case "nextWeek":
      date = [moment(new Date()), moment(new Date()).add(6, "days")];
      break;
    case "nextThirtyDays":
      date = [moment(new Date()), moment(new Date()).add(29, "days")];
      break;
    case "nextFourteenDays":
      date = [moment(new Date()), moment(new Date()).add(13, "days")];
      break;
    case "fourteenDays":
      date = [
        moment(new Date()).add(-14, "days"),
        moment(new Date()).add(14, "days"),
      ];
      break;
    case "ninetyDays":
      date = [moment(new Date()), moment(new Date()).add(89, "days")];
      break;
    case "lastDay":
      date = [moment().add(-1, "days"), moment().add(-1, "days")];
      break;
    case "none":
      date = null;
      break;
    default:
      console.warn("not defined this dateMode: ", dateMode);
      date = [
        moment(new Date()).add(-30, "days"),
        moment(new Date()).add(-1, "days"),
      ];
  }
  return date;
};

export const getDefaultDateMode = (pathname: string): string => {
  let modeName = "";
  switch (pathname) {
    case "/onsale-airline":
      modeName = "nextThirtyDays";
      break;
    default:
      modeName = "lastMonth";
  }
  return modeName;
};
/**
 * 同比增幅, 先除再减1再*100
 * @param value 原值
 * @param compareValue 对比值
 * @returns 计算结果
 */
export const getComparePercentageVal = (
  value: number,
  compareValue: number
): number => (value / compareValue - 1) * 100;

/**
 * 计算原值占对比值的份额
 * @param value 原值
 * @param compareValue 对比值
 * @returns 原值/对比值
 */
export const getProportionVal = (value: number, compareValue: number): number =>
  value / compareValue;

/**
 * 计算原值占对比值的份额 * 100
 * @param value 原值
 * @param compareValue 对比值
 * @returns 返回原值/对比值*100
 */
export const getProportionPercentageVal = (
  value: number,
  compareValue: number
): number => (value / compareValue) * 100;

/**
 * 增幅 (value / compareValue) - 1
 * @param value
 * @param compareValue
 * @returns
 */
export const getCompareVal = (value: number, compareValue: number): number =>
  value / compareValue - 1;

/**
 * 百分比相减
 * @param value 原值
 * @param compareValue 对比值
 * @returns 相减结果
 */
export const getComparedPercentage = (
  value: number,
  compareValue: number
): number => value - compareValue;

export const calculateCardsContrastVal = (
  value: number,
  compareValue: number,
  compareType?: ECompareTypeV2,
  systemType?: SystemType,
  cardCode?: string
): number => {
  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;
  const isAirlinesMarketComparison =
    compareType === ECompareTypeV2.MARKET_COMPARE;
  if (isAirlinesMarketComparison) {
    switch (cardCode) {
      case "passenger_traffic":
      case "transport_capacity":
      case "flight_sorties":
      case "kilo_passenger_traffic":
      case "kilo_transport_capacity":
        return (value / compareValue) * 100;
      case "load_factor":
      case "wide_body":
        return getComparedPercentage(value, compareValue);
      default:
        return getComparePercentageVal(value, compareValue);
    }
  } else {
    return isPercentVal
      ? getComparedPercentage(value, compareValue)
      : getComparePercentageVal(value, compareValue);
  }
};

export const getCardTrendText = (
  code?: string,
  systemType?: SystemType,
  compareType?: ECompareTypeV2
): string => {
  const isAirlinesMarketComparison =
    compareType === ECompareTypeV2.MARKET_COMPARE;
  if (isAirlinesMarketComparison) {
    switch (code) {
      case "passenger_traffic":
      case "transport_capacity":
      case "flight_sorties":
      case "kilo_passenger_traffic":
      case "kilo_transport_capacity":
        // 市占
        return getSharkText("key.market_share.name");
      default:
        // 对比市场
        return getSharkText("key.compare_to_market");
    }
  } else {
    return compareType !== undefined ? COMPARE_TYPE_PER_NAME[compareType] : "";
  }
};

export const groupCompareData = (
  data: any[],
  percentageKey: string[],
  noNeedCountKey: string[]
): any[] => {
  const groupData = _.groupBy(data, "type");
  const curData = groupData[0];
  const compareData = groupData[1];

  const isPercentage = (keyName: string) => percentageKey.indexOf(keyName) > -1;
  const noNeedCount = (keyName: string) => noNeedCountKey.indexOf(keyName) > -1;

  if (curData) {
    curData.map((item, idx) => {
      if (compareData) {
        const compare =
          compareData.find(
            (c) =>
              c.dcityCode === item.dcityCode && c.acityCode === item.acityCode
          ) || {};
        const result: any = {};
        if (!_.isEmpty(compare)) {
          const dataKeys = Object.keys(item);
          dataKeys.forEach((key) => {
            result[key] = noNeedCount(key)
              ? item[key]
              : isPercentage(key)
              ? getComparedPercentage(item[key], compare[key])
              : getComparePercentageVal(item[key], compare[key]);
          });
        }
        result.key = idx;
        compare.key = (idx + 1) * 2;
        item.compare = compare;
        item.result = result;
      } else {
        item.result = { key: idx };
        item.compare = { key: (idx + 1) * 2 };
      }
      item.key = idx;
      return item;
    });
    return curData;
  } else {
    return [];
  }
};

/**
 * 为表格数据生成子单元格数据
 * 传入一组数据, 必须是对象数组的形式, 将这组数据分成父组与子组
 *
 * @param data 原始数据
 * @param groupBy 用来区分父组与子组的列名, 列值为0时表示父组, 列值为1时表示子组, 不能用列值时使用groupByFn来区分, groupBy和groupByFn必须有一个有值
 * @param groupByFn 用函数来区分父组与子组, 传入参数为每个数组元素, 返回值必须为0或者1, 0表示父组, 1表示子组
 * @param dimColumns 用来关联父组与子组
 * @returns 对象数组, 子组被放入父组的children中, 并且子组每个对象会被添加isChild=true属性, 只支持子组与父组, 不支持多分组
 */
interface GroupSubPropsInterface {
  data: any[];
  groupBy?: string;
  groupByFn?: (d: any) => number;
  dimColumns: string[];
}

export const groupSubData = (props: GroupSubPropsInterface): any[] => {
  const { data, groupBy, groupByFn, dimColumns } = props;
  if (!groupBy && !groupByFn) {
    throw new Error("groupBy and groupByFn must have a value");
  }
  const groupData = _.groupBy(data, groupBy || groupByFn);
  const curData = groupData[0];
  const compareData = groupData[1];

  if (curData) {
    curData.map((item) => {
      item.children = undefined;
      if (compareData) {
        // 获取与当前父元素维度匹配的子元素
        const compareList =
          compareData.filter((c) =>
            dimColumns.every((dim) => c[dim] === item[dim])
          ) || [];
        if (!_.isEmpty(compareList)) {
          compareList.forEach((c) => {
            if (!item.children) {
              item.children = [];
            }
            c.isChild = true;
            item.children.push(cloneDeep(c));
          });
        }
      }
      return item;
    });
    return curData;
  } else {
    return [];
  }
};

export const groupMarketComparisonData = (data: any[]): any[] => {
  const groupData = _.groupBy(data, "type");
  const curData = groupData[0];
  const compareData = groupData[1];
  const compareData2 = groupData[2];

  if (curData) {
    curData.map((item, idx) => {
      if (compareData) {
        const compare =
          compareData.find(
            (c) =>
              c.dcityCode === item.dcityCode && c.acityCode === item.acityCode
          ) || {};
        compare.key = (idx + 1) * 2;
        item.compare = compare;
      } else {
        item.compare = { key: (idx + 1) * 2 };
      }
      if (compareData2) {
        const compare2 =
          compareData2.find(
            (c) =>
              c.dcityName === item.dcityName.split("/")[0] &&
              c.acityName === item.acityName.split("/")[0]
          ) || {};
        compare2.key = (idx + 1) * 2;
        item.compare2 = compare2;
      } else {
        item.compare2 = { key: (idx + 1) * 2 };
      }
      item.key = idx;
      return item;
    });
    return curData;
  } else {
    return [];
  }
};

export const getModuleStatus = (
  pathname: string,
  moduleList: Module[]
): number => {
  const curModule = moduleList.find((module) => module.moduleCode === pathname);
  // 添加Travix后, 没有修改systemType -> pathname的全部逻辑, 只是新加了一层,
  // 因为上线时间紧急, 先采用判断CurModule是否存在的方式返回Module, 正确的做法是修改systemType, 修改getModuleNameFromPath函数
  if (curModule) {
    return curModule ? curModule.moduleStatus : 3;
  } else {
    const originalName = pathname
      .replace("_airport", "")
      .replace("_airlines", "");
    const rstModule = moduleList.find(
      (module) => module.moduleCode === originalName
    );
    if (rstModule) {
      return rstModule ? rstModule.moduleStatus : 3;
    }
  }
  console.info("get Module Status");
  return 3;
};

export const getModule = (
  pathname: string,
  moduleList: Module[]
): Module | undefined => {
  const curModule = moduleList.find((module) => module.moduleCode === pathname);
  // 添加Travix后, 没有修改systemType -> pathname的全部逻辑, 只是新加了一层,
  // 因为上线时间紧急, 先采用判断CurModule是否存在的方式返回Module, 正确的做法是修改systemType, 修改getModuleNameFromPath函数
  if (curModule) {
    return curModule;
  } else {
    const originalName = pathname
      .replace("_airport", "")
      .replace("_airlines", "");
    const rstModule = moduleList.find(
      (module) => module.moduleCode === originalName
    );
    if (rstModule) {
      return rstModule;
    }
  }
  return curModule;
};

/**
 * 用千分位格式化数据
 * @param num 数值,例如12345
 * @returns 用千分位格式化之后的数值12,345
 */
export const toThousands = (num: number): string => {
  // let tmp = num.toString()
  // let result = ''
  // while (tmp.length > 3) {
  //   result = ',' + tmp.slice(-3) + result
  //   tmp = tmp.slice(0, tmp.length - 3)
  // }
  // if (tmp) {
  //   result = tmp + result
  // }
  // return result
  if (!num) {
    return "0";
  }
  // return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let rst = `${num}`;
  try {
    const reg = new RegExp("\\B(?<!\\.\\d*)(?=(\\d{3})+(?!\\d))", "g");
    rst = num.toString().replace(reg, ",");
  } catch (e) {
    console.log(e);
  }
  return rst;
};

export const isAirportMode = (systemType: SystemType): boolean =>
  systemType === SystemType.airport;

export const getModuleNameFromPath = (
  pathname: string,
  systemType: SystemType
): string => {
  if (systemType && !isAirportMode(systemType)) {
    return pathname === "/"
      ? "dashboard_airlines"
      : pathname.replace("/", "") + "_airlines";
  } else if (systemType === SystemType.airport) {
    return pathname === "/"
      ? "dashboard_airport"
      : pathname.replace("/", "") + "_airport";
  } else {
    return pathname === "/" ? "dashboard_airport" : pathname.replace("/", "");
  }
};

export const logout = (): void => {
  delCookie(UID);
  delCookie(TOKEN);
  delCookie(BINDING);
};

export const isHideDigits = (cardCode: string): boolean =>
  cardCode === "passenger_traffic" || cardCode === "transport_capacity";

export const getUserRoleTypeText = (userData: UserList): string => {
  if (userData.user.groupId === 0 || userData.roleList.length === 0) {
    // 航司 ：机场
    return userData.user.demoType === 2
      ? decodeURIComponent("%E8%88%AA%E5%8F%B8")
      : decodeURIComponent("%E6%9C%BA%E5%9C%BA");
  } else if (userData.roleList.length > 1) {
    // 航司 机场
    return decodeURIComponent("%E8%88%AA%E5%8F%B8%20%E6%9C%BA%E5%9C%BA");
  } else {
    // 航司 ： 机场
    return userData.roleList[0].roleType === 2
      ? decodeURIComponent("%E8%88%AA%E5%8F%B8")
      : decodeURIComponent("%E6%9C%BA%E5%9C%BA");
  }
};

export const showNum = (
  value: number,
  type: "num" | "percentage" | "price" = "num",
  dec = type === "percentage" ? 1 : 0
): string => {
  return _.isFinite(value)
    ? `${type === "price" ? "¥" : ""}${
        type === "percentage"
          ? _.round(value, dec) + "%"
          : toThousands(_.round(value, dec))
      }`
    : "";
};

export const showRawNum = (
  value: number,
  type: "num" | "percentage" | "price" | "minute" = "num",
  dec = 1
): CellObject | string => {
  const decArr: string[] = [];
  decArr.length = dec;
  decArr.fill("0", 0, dec);
  const z = `0.${decArr.join()}%`;
  return _.isFinite(value)
    ? type === "percentage"
      ? { v: value / 100, z, t: "n" }
      : { v: value, t: "n", z: "#,##0" }
    : "";
};

/**
 * 对表格中的数据进行排序, 并且非数值类型的值放在最后
 *
 * @param v1 数据1
 * @param v2 数据2
 * @returns 默认降序排列, 当v1为空时, 返回1 表示将v1放后面, v2为空时, 返回-1
 */
export const tableNumSorter = (
  v1: string | number | null | undefined,
  v2: string | number | null | undefined,
  c?: SortOrder
): number => {
  let rst = 0;
  if (!isFinite(v1) || !isNumber(v1)) {
    rst = c && c === "descend" ? -1 : 1;
  } else if (!isFinite(v2) || !isNumber(v2)) {
    rst = c && c === "descend" ? 1 : -1;
  } else {
    rst = v1 < v2 ? -1 : 1;
  }
  return rst;
};

export const getFilterData = (
  searchkeys: string[],
  searchText: string,
  dataSource: any[]
): any[] => {
  const cloneData = _.cloneDeep(dataSource);
  const filter = cloneData.filter((item: any) => {
    let isPass = false;
    for (const key of searchkeys) {
      if (item[key] && item[key].includes(searchText.toLocaleUpperCase())) {
        isPass = true;
        break;
      }
    }
    return isPass;
  });
  return filter;
};

interface IDurationFormatterOutput {
  d: number;
  h: number;
  m: number;
  s: number;
}
/**
 * 取整数, 比如计算时间格式化, 正数小时向下取整, 负数小时向上取整
 * @param num 参数
 * @returns 取整
 */
export const floorNumber = (num: number): number => {
  return num > 0 ? Math.floor(num) : Math.ceil(num);
};
// 参数的单位为秒 时长formatter, 例如76分12秒变为1h16m12s, 最大单位为天(d)
export const durationFormatter = (
  seconds: number,
  endAt: "d" | "h" | "m" | "s" = "m"
): string => {
  const ends = ["d", "h", "m", "s"];
  const endIdx = ends.indexOf(endAt);
  if (seconds === 0) {
    return `0${endAt}`;
  }
  const secondsInDay = seconds % (24 * 60 * 60);
  const secondsInHour = secondsInDay % (60 * 60);
  const secondsInMinute = secondsInHour % 60;
  const obj: IDurationFormatterOutput = {
    d: floorNumber(seconds / (24 * 60 * 60)),
    h: floorNumber(secondsInDay / (60 * 60)),
    m: floorNumber(secondsInHour / 60),
    s: floorNumber(secondsInMinute),
  };
  const rst = `${obj.d && endIdx > -1 ? `${obj.d}d` : ""}${
    obj.h && endIdx > 0 ? `${obj.h}h` : ""
  }${obj.m && endIdx > 1 ? `${obj.m}m` : ""}${
    obj.s && endIdx > 2 ? `${obj.s}s` : ""
  }`;
  return rst;
};

export const isZero = (val: number): boolean => val === 0;

/**
 * 在数组任意位置插入元素, 会生成一个新的数组, 旧的数组保持不变
 *
 * @param arr 原始数组
 * @param index 位置
 * @param newItem 新元素
 */
export const insertArray = <T>(arr: T[], index: number, newItem: T): T[] => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const getAirportsOrAirlinesArr = (
  role: Role | undefined,
  airportMode: boolean
): AirportOrAirline[] => {
  const ctripArr = airportMode ? ctripDefaultAirport : ctripDefaultAirlines;
  const lang = sessionStorage.getItem("lang") || "zh-CN";
  if (role) {
    const isCtrip = role.permissionCode === "*";
    const codeArr = role.permissionCode.split(",");
    const nameArr = role.permissionName.split(",");
    const arr: AirportOrAirline[] = [];
    codeArr.forEach((item, idx) => {
      arr.push({
        code: item,
        name: lang === "en-US" ? "" : nameArr[idx],
      });
    });
    return isCtrip ? ctripArr : arr;
  } else {
    return ctripArr;
  }
};

export const getAirlinesRole = (roleList: Role[]): Role | undefined => {
  return roleList.find((item: Role) => item.roleType === 2);
};

/** 从用户角色中获取用户权限航司 */
export const getUserAirlines = (roleList?: Role[]): AirportOrAirline[] => {
  const airlinesRole = roleList ? getAirlinesRole(roleList) : undefined;
  return getAirportsOrAirlinesArr(airlinesRole, false);
};

/**
 * 判断用户是否属于某个机场/航司组
 * @param roleList 用户信息,包含roleList, user
 * @param groupCode 目标权限的code, 比如航司: CA, MU, 机场: SHA
 */
export const isGroupUser = (roleList: Role[], groupCode: string): boolean => {
  const userAirlines = getUserAirlines(roleList);
  const userAirlinesCode = userAirlines.map((a) => a.code);
  return userAirlinesCode.includes(groupCode);
};

// 递归查找airport路径, 在Area结构的数据中, 根据机场编码SHA, 找到['华东', '上海', 'SHA']
export const getAirportPath = (
  code: string,
  areaData: Area[],
  path: string[]
): string[] | undefined => {
  let rst = cloneDeep(path);
  const item = areaData.find((area: Area) => {
    const tmp = cloneDeep(path);
    tmp.push(area.areaCode);
    if (area.areaCode === code) {
      rst = tmp;
      return true;
    } else if (area.children) {
      const deepPath = getAirportPath(code, area.children, tmp);
      if (deepPath) {
        rst = deepPath;
        return true;
      }
      return false;
    } else {
      return false;
    }
  });
  return item ? rst : undefined;
};

export const getAirport = (
  code: string,
  areaData: Area[]
): Area | undefined => {
  let item;
  areaData.find((area: Area) => {
    if (area.areaCode === code) {
      item = area;
      return true;
    } else if (area.children) {
      item = getAirport(code, area.children);
      return !!item;
    } else {
      return false;
    }
  });
  return item;
};

export const isHistoryToFuture = (dates: Moment[]): boolean => {
  const today = moment(new Date()).format("YYYY-MM-DD");
  return (
    dates[0].isBefore(today, "day") && dates[1].isSameOrAfter(today, "day")
  );
};

// 数组的merge, 使用lodash的merge方法, 递归调用
// eslint-disable-next-line
export const mergeArray = (arr: any[], rst?: any, idx?: number): any => {
  if (isEmpty(arr)) {
    return undefined;
  } else if (arr.length === 1) {
    return arr[0];
  } else {
    const data = cloneDeep(arr);
    let tmp = rst || data[0];
    const i = idx || 1;
    tmp = merge(tmp, data[i]);
    if (i === arr.length - 1) {
      return tmp;
    } else {
      return mergeArray(arr, tmp, i + 1);
    }
  }
};

// eslint-disable-next-line
export const isSame = (a: any, b: any): boolean => {
  a = cloneDeep(a);
  b = cloneDeep(b);
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  const baseTypes = ["string", "number", "boolean"];
  if (baseTypes.includes(typeof a) && a !== b) {
    return false;
  }
  if (a === null || b === null) {
    return false;
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    b = b.sort();
    return a.sort().every((av, ai) => isSame(av, b[ai]));
  }
  if (typeof a === "object") {
    if (a instanceof moment) {
      if (b instanceof moment) {
        const am = a as Moment;
        const bm = b as Moment;
        return am.isSame(bm);
      }
      return false;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    if (aKeys.length === 0) {
      if (Object.keys(b).length !== 0) {
        return false;
      }
    }
    return aKeys.every((av) => {
      if (Object.prototype.hasOwnProperty.call(b, av)) {
        return isSame(a[av], b[av]);
      }
      return false;
    });
  }
  return true;
};

export const getIsDemo = (
  pathname: string,
  globalState: IGlobalState
): boolean => {
  const { userInfo, systemType } = globalState;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  return isDemo;
};

/**
 * 向字符串指定位置插入一段字符串
 * @param str 字符串
 * @param insertContent 需要插入的内容
 * @param idx 插入位置, 默认为结尾, 从1开始数, 输入2表示内容插入在第2个字符后面
 * @returns 插入以后的字符串, 如果字符串长度小于idx, 则插入失败. 报错
 */
export const insertIntoString = (
  str: string,
  insertContent: string,
  idx: number = str.length
): string => {
  if (str.length < idx) {
    throw new Error("text length not enough to insert!");
  }
  if (idx === str.length) {
    return str + insertContent;
  } else {
    const pre = str.slice(0, idx);
    const suf = str.slice(idx, str.length);
    return pre + insertContent + suf;
  }
};

/**
 * 将一个字符串的首字母大写
 * @param str 输入字符串
 * @returns 首字母大写的字符串
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * 将一个字符串首字母大写再拼接前缀
 * @param pre 前缀
 * @param str 输入字符串
 * @returns 将str首字母大写并且拼接pre前缀后返回
 */
export const prefixStr = (pre: string, str: string): string => {
  if (!pre || !str) {
    return str;
  }
  return pre + capitalizeFirstLetter(str);
};

/**
 * 行转列, 考虑到数据量较大的情况下的行转列性能, 处理时先对数据集按照维度做排序, 用顺序取数替代查找取数, 提高处理效率
 * 转换前每一项数据的属性个数需要相等且相同
 * 例如: 将
 *  [
      {student: "Andrew", subject: "Maths", marks: 85, px: 3},
      {student: "Andrew", subject: "Science", marks: 82, px: 9},
      {student: "Andrew", subject: "Computers", marks: 90, px: 10},
      {student: "John", subject: "Maths", marks: 90, px: 10},
      {student: "John", subject: "Science", marks: 85, px: 11},
      {student: "John", subject: "Electronics", marks: 75, px: 5}
    ]
    转换为
    [
    {
        "student": "Andrew",
        "Mathsmarks": 85,
        "Mathspx": 3,
        "Sciencemarks": 82,
        "Sciencepx": 9,
        "Computersmarks": 90,
        "Computerspx": 10,
        "Electronicsmarks": null,
        "Electronicspx": null
    },
    {
        "student": "John",
        "Mathsmarks": 90,
        "Mathspx": 10,
        "Sciencemarks": 85,
        "Sciencepx": 11,
        "Computersmarks": null,
        "Computerspx": null,
        "Electronicsmarks": 75,
        "Electronicspx": 5
    }
    ]
 * @param data 待转换数据集
 * @param dims 维度, 去重值决定了转换后的行数
 * @param rtColumn 被转换的列, 去重值与值列相乘得到转换后的列
 * @param valColumns 值列, 如果不传则使用维度和被转换列外的所有列
 * @param newColumnFormatter 可以自定义转换后的列名, rtVal是被转换的列去重后的值, oriCol是转换前的值的列名, 返回值被用作新的列名, 新的列名不能重复, 如果新的列名返回null值, 则该列不生成
 * @param defaultValue 生成的空值格的默认填充值, 不传时填充null
 * @returns 转换后的数据集
 */
export const rowToColumn = (
  data: any[],
  dims: string | string[],
  rtColumn: string,
  valColumns?: string | string[],
  newColumnFormatter?: (rtVal: string, oriCol: string) => string | null,
  defaultValue?: unknown
): any => {
  if (data.length === 0) {
    return [];
  }
  const tmpDims = typeof dims === "string" ? [dims] : dims;
  // 未定义valColumns时取dim和rtColumns之外的所有列
  const tmpValColumns =
    typeof valColumns === "undefined"
      ? Object.keys(data[0]).filter(
          (key: string) => !tmpDims.includes(key) && rtColumn !== key
        )
      : typeof valColumns === "string"
      ? [valColumns]
      : valColumns;

  const generateNewColumnName = (rtVal: string, oriVal: string) => {
    return newColumnFormatter
      ? newColumnFormatter(rtVal, oriVal)
      : tmpValColumns.length === 1
      ? rtVal
      : rtVal + oriVal;
  };
  // 1. 先取出所有需要被转换的列值的去重集合
  const rtColumnValues: string[] = uniqBy(
    data,
    (item: any) => item[rtColumn]
  ).map((d) => d[rtColumn]) as string[];
  // 2. 把数据按照维度分组
  const groupedData = groupBy(
    data,
    (item: any) => "prefix-" + tmpDims.map((dim) => item[dim]).join()
  );
  // 3. 将已经分组的数据行转列
  const res = Object.keys(groupedData).reduce((rst, currentGroup) => {
    const groupArr = groupedData[currentGroup];
    const newItem: any = {};
    const groupIncludeRtValues: any = [];
    tmpDims.forEach((dim) => {
      newItem[dim] = groupArr[0][dim];
    });
    groupArr.forEach((g) => {
      tmpValColumns.forEach((val) => {
        const rtVal = g[rtColumn];
        groupIncludeRtValues.push(rtVal);
        // 如果定义了新列的格式化函数, 则使用格式化, 否则, 如果值列只有一个, 则使用rt列的值, 否则将rt和值列名拼接
        const newColumnName: string | null = generateNewColumnName(rtVal, val);
        if (newColumnName) {
          newItem[newColumnName] = g[val];
        }
      });
    });
    const unIncludeRtValues = difference(rtColumnValues, groupIncludeRtValues);
    unIncludeRtValues.forEach((rtVal) => {
      tmpValColumns.forEach((val) => {
        const newColumnName: string | null = generateNewColumnName(rtVal, val);
        if (newColumnName) {
          newItem[newColumnName] =
            typeof defaultValue !== "undefined" ? defaultValue : null;
        }
      });
    });
    rst.push(newItem);

    return rst;
  }, [] as any[]);
  return res;
};

/**
 * 埋点函数, 用于快速记录PV, PV字段记录在module_id中
 * @param key 埋点名
 */
export const ubt = (key: string, data?: unknown): void => {
  ubtUtils.info(
    key,
    {
      moduleId: key,
      moduleData: data,
    },
    "o_flt_flightai_flightmanage_trace"
  );
};
/**
 * 因为对象未变更时setState会导致无效的重复刷新, 此函数会在set前对比对象内容是否实际变动, 如果未变动则不执行set
 * @param cb setState的函数
 * @param oldVal 旧的值
 * @param newVal 新的值
 * @returns 执行set返回true, 否则返回false
 */
export const objSet = <T>(
  cb: Dispatch<SetStateAction<T>>,
  oldVal: T,
  newVal: T
): boolean => {
  if (!isSame(oldVal, newVal)) {
    cb(newVal);
    return true;
  }
  return false;
};
/**
 * 为客座率做浮动展示
 * @param val 原始值
 * @param type 数值或者百分比
 * @returns 区段
 */
export const genLoadFloat = (
  val: number,
  type: "num" | "percentage" = "num",
  max?: number
): string => {
  if (!isNumeric(val)) {
    return "-";
  }
  const tmpMax = val * 1.05;
  const allowMax = isNumber(max) && type === "percentage" ? max * 100 : max;
  const maxRst =
    max !== undefined ? (min([allowMax, tmpMax]) as number) : tmpMax;
  if (type === "num") {
    return `${Math.floor(val * 0.95)} - ${Math.ceil(maxRst)}`;
  } else if (type === "percentage") {
    return `${showNum(val * 0.95, "percentage")} - ${showNum(
      maxRst,
      "percentage"
    )}`;
  } else {
    return `${val}`;
  }
};

// 为了便利调试
window.moment = moment;
/**
 * 将时间字符串提取成时间戳数值, 提高比较和补全的效率
 * @param s 时间字符串, 例如"/Date(1637596800000)"
 * @returns 数值时间戳, 例如 1637596800000
 */
export const timeStrToNum = (s: string): number => {
  return Number(s.match(/[0-9]+/));
};

window._ = _;

/**
 * 判断机场是否是一市多场, 如果是则返回一市多场条目
 * @param port 传入机场
 */
export const isOneCityMultiPort = (port: string): [string, string[]] | null => {
  const keys = Object.keys(MULTI_AIRPORT);
  const rst = keys.find((key) => MULTI_AIRPORT[key].includes(port));
  return rst ? [rst, MULTI_AIRPORT[rst]] : null;
};
/**
 * 处理一市两场的机场, 如果航线中包含了一市两场的航线, 则增加该城市的另一机场的航线
 * 例如["XIYPVG"] 转为 ["XIYPVG", "XIYSHA"]
 * @param routes 航线列表
 */
export const getRouteForMultiAirportCity = (routes: string[]): string[] => {
  const rst = cloneDeep(routes);
  routes.forEach((route) => {
    if (route) {
      const dep = route.substr(0, 3);
      const arr = route.substr(3, 3);
      const depMulti = isOneCityMultiPort(dep);
      const arrMulti = isOneCityMultiPort(arr);
      const deps = depMulti ? depMulti[1] : [dep];
      const arrs = arrMulti ? arrMulti[1] : [arr];
      // 可能原本就选择了一市多场的多个选项, 因此先全部插入一遍, 返回前再去重
      deps.forEach((d) => {
        arrs.forEach((a) => {
          rst.push(`${d}${a}`);
        });
      });
    }
  });
  return uniq(rst);
};

export const getTS = (s: string | number): string => {
  return `/Date(${s})/`;
};
// return array of [r,g,b,a] from any valid color. if failed returns undefined
export const colorValues = (color: string): number[] | undefined => {
  if (color === "") return;
  if (color.toLowerCase() === "transparent") return [0, 0, 0, 0];
  if (color[0] === "#") {
    if (color.length < 7) {
      // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
      color =
        "#" +
        color[1] +
        color[1] +
        color[2] +
        color[2] +
        color[3] +
        color[3] +
        (color.length > 4 ? color[4] + color[4] : "");
    }
    return [
      parseInt(color.substr(1, 2), 16),
      parseInt(color.substr(3, 2), 16),
      parseInt(color.substr(5, 2), 16),
      color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1,
    ];
  }
  if (color.indexOf("rgb") === -1) {
    // convert named colors
    const tempElem = document.body.appendChild(
      document.createElement("fictum")
    );
    // intentionally use unknown tag to lower chances of css rule override with !important
    // this flag tested on chrome 59, ff 53, ie9, ie10, ie11, edge 14
    const flag = "rgb(1, 2, 3)";
    tempElem.style.color = flag;
    // color set failed - some monstrous css rule is probably taking over the color of our object
    if (tempElem.style.color !== flag) return;
    tempElem.style.color = color;
    // color parse failed
    if (tempElem.style.color === flag || tempElem.style.color === "") return;
    color = getComputedStyle(tempElem).color;
    document.body.removeChild(tempElem);
  }
  if (color.indexOf("rgb") === 0) {
    if (color.indexOf("rgba") === -1) {
      // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
      color += ",1";
    }
    const cs = color.match(/[.\d]+/g);
    if (cs) {
      return cs.map((a) => {
        return +a;
      });
    }
  }
};

export class ColorComputer {
  private start: number[] = [];
  private end: number[] = [];
  constructor(startC: string, endC: string) {
    const startRgb = colorValues(startC);
    const endRgb = colorValues(endC);
    if (!startRgb || !endRgb) {
      throw new Error("init color value failed");
    }
    this.start = startRgb;
    this.end = endRgb;
  }

  private componentToHex = (c: number): string => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  getColor = (per: number): string => {
    const rst = this.start.map((s, i) => {
      const val = Math.floor(s + (this.end[i] - s) * per);
      const r = val < 0 ? 0 : val > 255 ? 255 : val;
      const v = i === 3 ? r * 255 : r;
      return this.componentToHex(v);
    });
    return `#${rst.join("")}`;
  };
}

/**
 * 存在一个已知的bug, 如果父节点与子节点都复合operator, 那么只会匹配父节点
 * @param list
 * @param operator
 * @param childrenKey
 * @returns
 */
export const deepFind = (
  list: any[],
  operator: (r: any) => boolean,
  childrenKey = "children"
): any => {
  const rst = list.find((l) => operator(l));
  if (rst) {
    return rst;
  }
  for (let idx = 0; idx < list.length; idx++) {
    const ele = list[idx];
    if (ele[childrenKey] && Array.isArray(ele[childrenKey])) {
      const deepR = deepFind(ele[childrenKey], operator, childrenKey);
      if (deepR) {
        return deepR;
      }
    }
  }
};

/**
 * 因为antd的日历组件会包含一部分上月日期和下月日期,
 * 为了保证每个日历都在查询范围, 传入当前选中日期后, 查询从上月开始到下月结束的所有数据
 * @param date calendar选中日期
 * @returns 选中日期上一月开始至下一月结束
 */
export const getCalendarRange = (date: Moment): Moment[] => {
  const start = cloneDeep(date).add(-1, "month").startOf("month");
  const end = cloneDeep(date).add(1, "month").endOf("month");
  return [start, end];
};

let debugTime = 0;
/**
 * 显示农历日期, 春节显示春节, 每月初一显示月份, 其余显示农历日期
 * @param date 需要转换的日期
 * @returns 转后显示的值
 */
export const showLunarDay = (date: Date): string => {
  const startTs = new Date().valueOf();
  const numberFormatter = new Intl.DateTimeFormat("zh-u-ca-chinese", {
    month: "numeric",
    day: "numeric",
  });
  const numberRst = numberFormatter.format(date);
  const numberArr = numberRst.split("-");
  const month = parseInt(numberArr[0], 10);
  const day = parseInt(numberArr[1], 10);
  const stringFormatter = new Intl.DateTimeFormat(
    "zh-u-ca-chinese-nu-hanidec",
    { dateStyle: "medium" }
  );
  const stringRst = stringFormatter.format(date);
  const stringMD = stringRst.split("年")[1];
  const stringArr = stringMD.split("月");
  if (day === 1) {
    return month === 1 ? "春节" : `${stringArr[0]}月`;
  }
  const endTs = new Date().valueOf();
  debugTime += endTs - startTs;
  return stringArr[1];
};

export interface RecursiveListOption {
  /** item的主键, 默认为id */
  itemKey: string;
  /** 关联父节点的属性名, 默认为parentId */
  parentKey: string;
  /** 根节点的[parentKey]的值, 默认为"-1" */
  rootParentId: string | number;
  /** 子节点属性名, 默认为children */
  childKey: string;
}

/**
 * listMap转为tree格式
 * @param list 数据源
 * @param option 配置项
 * @returns tree格式的数据, 无子项的赋空数组
 */
export const recursiveList = <T extends Record<string, any>>(
  list: T[],
  option?: RecursiveListOption
): T[] => {
  const {
    rootParentId = 0,
    itemKey = "id",
    parentKey = "parentId",
    childKey = "children",
  } = option || {};
  // 用一个对象来存储每个节点的引用，方便查找父节点
  const map: any = {};
  // 遍历数组，将每个节点添加到map中
  list.forEach((node) => {
    map[node[itemKey]] = node;
  });

  // 定义一个函数，用于递归地查找子节点
  const findChildren = (node: any) => {
    // 在map中查找当前节点的子节点
    const children = list.filter((child) => child[parentKey] === node[itemKey]);
    // 遍历子节点，递归地查找它们的子节点
    children.forEach((child) => {
      findChildren(child);
    });
    // 将子节点添加到当前节点的children属性中
    node[childKey] = children;
  };

  // 找到根节点
  let roots: any = list.filter((node) => map[node[itemKey]] === rootParentId);
  let hasRoot = true;
  if (!roots.length) {
    hasRoot = false;
    roots = [
      {
        [itemKey]: rootParentId,
        [parentKey]: null,
        [childKey]: [],
      },
    ];
  }
  // 遍历根节点，递归地查找它们的子节点
  roots.forEach((root: any) => {
    findChildren(root);
  });

  // 返回根节点数组
  return hasRoot ? roots : roots[0][childKey];
};

/**
 * Utils用来存放与业务无关的通用方法, 也业务有关的放在global.ts中
 */
const Utils = {
  rowToColumn,
  getWeekDay: (date?: Date): string => {
    const d = new Intl.DateTimeFormat("zh-CN", { weekday: "short" });
    const day = date ?? new Date();
    return d.format(day);
  },
  /**
   * 从多重嵌套的数据中找到目标数据, 并且返回完整的父子关系路径
   * @param list
   * @param operator
   * @param childrenKey
   * @returns
   */
  deepFindPath: (
    list: any[],
    operator: (r: any) => boolean,
    childrenKey = "children"
  ): any => {
    const rst = list.find((l) => operator(l));
    if (rst) {
      return rst;
    }
    for (let idx = 0; idx < list.length; idx++) {
      const ele = list[idx];
      if (ele[childrenKey] && Array.isArray(ele[childrenKey])) {
        const deepR = Utils.deepFindPath(
          ele[childrenKey],
          operator,
          childrenKey
        );
        if (deepR) {
          const tmp = cloneDeep(ele);
          tmp[childrenKey] = [deepR];
          return tmp;
        }
      }
    }
  },
  getPathFromTreeNode: (
    list: any[],
    operator: (r: any) => boolean,
    childrenKey = "children"
  ): any[] | null => {
    const rst = Utils.filterTreeWithPath(list, operator);
    if (rst.length) {
      const toList = (children: any[], paths: any[]) => {
        paths.push(children[0]);
        if (children[0][childrenKey]?.length) {
          toList(children[0][childrenKey], paths);
        }
      };
      const pathRst: any[] = [];
      toList(rst, pathRst);
      return pathRst;
    }
    return [];
  },
  /**
   * 从树状结构的数据中找到父节点, 并且返回完整的父节点数据
   * @param tree
   * @param operator
   * @param childrenKey
   * @returns 父节点数据
   */
  getParentNode: (
    tree: any[],
    operator: (node: any) => boolean,
    childrenKey = "children"
  ): any => {
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node[childrenKey]) {
        if (node[childrenKey].some(operator)) {
          return node;
        } else if (
          Utils.getParentNode(node[childrenKey], operator, childrenKey)
        ) {
          return Utils.getParentNode(node[childrenKey], operator, childrenKey);
        }
      }
    }
    return null;
  },
  /**
   * 在树状结构中过滤, 返回一个新的过滤后的树状数组, 只要子节点中有一个元素满足条件, 就会返回一整条树状链路
   * @param tree
   * @param operator
   * @param childrenKey
   */
  filterTreeWithPath: (
    tree: any[],
    operator: (node: any) => boolean,
    childrenKey = "children"
  ): any[] => {
    const cp = cloneDeep(tree);
    const rst = cp.filter((node) => {
      if (node[childrenKey]?.length) {
        const tmp = Utils.filterTreeWithPath(
          node[childrenKey],
          operator,
          childrenKey
        );
        if (tmp.length) {
          node[childrenKey] = tmp;
          return true;
        }
      }

      const r = operator(node);
      if (r && node[childrenKey]) {
        node[childrenKey] = [];
      }
      return r;
    });
    return rst;
  },
  /** 从树状结构的数据中过滤出符合条件的树状结构 */
  filterTree: (node: any, operator: (node: any) => boolean): any[] => {
    if (operator(node)) {
      const children = node.children
        ?.map((child: any) => Utils.filterTree(child, operator))
        .filter(Boolean);
      return { ...node, children };
    } else {
      return node.children
        ?.map((child: any) => Utils.filterTree(child, operator))
        .filter(Boolean)[0];
    }
  },
  /**
   * 为树状结构的每个元素都添加某个属性, 比如说给所有的元素添加key属性
   * @param tree
   * @param operator
   * @param childrenKey
   * @param attributeName
   */
  generateAttrForTree: (
    tree: any[],
    generator: (node: any) => any,
    attributeName = "key",
    childrenKey = "children"
  ): any[] => {
    const rst = tree.map((c) => {
      const newItem = {
        ...c,
        [attributeName]: generator(c),
      };
      if (c[childrenKey]) {
        newItem[childrenKey] = Utils.generateAttrForTree(
          c[childrenKey],
          generator,
          attributeName,
          childrenKey
        );
      }
      return newItem;
    });
    return rst;
  },
  /**
   * UUID
   */
  getUUID: (): string => {
    if (typeof crypto === "object") {
      if (typeof crypto.randomUUID === "function") {
        return crypto.randomUUID();
      }
      if (
        typeof crypto.getRandomValues === "function" &&
        typeof Uint8Array === "function"
      ) {
        const callback = (c: any) => {
          const num = Number(c);
          return (
            num ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
          ).toString(16);
        };
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, callback);
      }
    }
    let timestamp = new Date().getTime();
    let perforNow =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let random = Math.random() * 16;
      if (timestamp > 0) {
        random = (timestamp + random) % 16 | 0;
        timestamp = Math.floor(timestamp / 16);
      } else {
        random = (perforNow + random) % 16 | 0;
        perforNow = Math.floor(perforNow / 16);
      }
      return (c === "x" ? random : (random & 0x3) | 0x8).toString(16);
    });
  },
  /*
   * 在字符串指定位置插入内容
   * @param source 源字符串
   * @param startIndex 指定位置
   * @param content 需要插入的内容
   * @returns 修改后的字符串
   */
  stringInsert: (
    source: string,
    startIndex: number,
    content: string
  ): string => {
    return (
      source.slice(0, startIndex) + (content || "") + source.slice(startIndex)
    );
  },
  /**
   * 首字母大写
   * @param str 字符串
   * @returns 首字母大写后的字符串
   */
  capitalizeFirstLetter: (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  /**
   * 在list列表中查找或者新建元素并返回元素
   * @param list 列表
   * @returns 已有或新建的元素
   */
  findOrCreateListItem: <T = any>(
    list: T[],
    operator: (item: T) => boolean,
    defaultItem: T
  ): T => {
    const exist = list.find(operator);
    if (exist) {
      return exist;
    }
    const newItem = cloneDeep(defaultItem);
    list.push(newItem);
    return newItem;
  },
  /**
   * 比较两个数值的大小, 可以比较字符串格式的数值, 比如"14" > "9"
   * @param s1 值1
   * @param s2 值2
   * @returns 如果其中一个为Nan, 返回0, s1小于s2返回-1, 大于返回1,等于返回0
   */
  numberCompare: (
    s1?: string | number | null,
    s2?: string | number | null
  ): -1 | 0 | 1 => {
    const ns1 = Number(s1);
    const ns2 = Number(s2);
    if (isNaN(ns1) || isNaN(ns2)) {
      return 0;
    }
    if (ns1 === ns2) {
      return 0;
    }
    return ns1 < ns2 ? -1 : 1;
  },
  /**
   * 从字符串中获取第一个数值, 支持小数, 不支持百分比, 如果没有数值返回null
   *
   * 例如 AB123.5CD34, 返回123.5
   * @param s 输入字符串
   */
  getStringFirstNumber: (s: string): number | null => {
    const matches = s.match(/[\d.]+/gi);
    return matches ? Number(matches[0]) : null;
  },
  /**
   * 将大的长的数值转换为短且带单位的表示, 比如12345678转为12.3M, 最大支持到t
   * @param num 被转换的数值
   * @param precision 小数点后精度,默认一位
   */
  abbreviateNumber: (num: number, precision = 1): string | null => {
    if (num === null) {
      return null;
    }
    // terminate early
    if (num === 0) {
      return "0";
    }
    // number of decimal places to show
    precision = !precision || precision < 0 ? 0 : precision;
    // get power
    const b = num.toPrecision(2).split("e");
    // floor at decimals, ceiling at trillions
    const k =
      b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3);
    // divide by power
    const c =
      k < 1
        ? num.toFixed(0 + precision)
        : (num / Math.pow(10, k * 3)).toFixed(precision);
    // enforce -0 is 0
    const d = Number(c) < 0 ? c : Math.abs(Number(c));
    // append power
    const e = d + ["", "K", "M", "B", "T"][k];
    return e;
  },
};
export const U = Utils;
// @ts-ignore;
window.U = U;

/**
 * 在树状结构中查找第一个符合条件的项目
 * @param v
 * @param fn
 * @returns
 */
export const findTree = (v: any[], fn: (item: any) => boolean): any | null => {
  for (let i = 0; i < v.length; i++) {
    const item = v[i];
    if (fn(item)) {
      return item;
    }
    if (item.children) {
      const rst = findTree(item.children, fn);
      if (rst) {
        return rst;
      }
    }
  }
};

/**
 * 在对象数组中根据operator判断结果替换元素, 只替换第一个判断为真的元素, 如果没有找到匹配元素, 则将新元素添加到数组
 * @param arr 原始数组
 * @param newItem 新元素
 * @param operator 判断条件, 参数为遍历原始数组的每个元素
 * @returns 一个深拷贝的替换过的新数组
 */
export const arrayUpsert = <T = object>(
  arr: T[],
  newItem: T,
  operator: (v: T) => boolean
): T[] => {
  const cp = cloneDeep(arr);
  const idx = cp.findIndex(operator);
  if (idx >= 0) {
    cp.splice(idx, 1, newItem);
  } else {
    cp.push(newItem);
  }
  return cp;
};

/** 字符串包含, 忽略大小写 */
export const stringContains = (str: string, query: string): boolean => {
  if (!str) return false;
  return str.toLocaleLowerCase().includes(query.toLocaleLowerCase());
};

/** 合并两个数组并保持各自原有的顺序 例如["A","B","D"], ["C", "D"], 合并为["A", "B", "C", "D"]*/
export const orderMerge = <T = any>(arr1: T[], arr2: T[]): T[] => {
  let result = [];
  let i = 0;
  let j = 0;
  while (i < arr1.length && j < arr2.length) {
    if (arr1[i] === arr2[j]) {
      result.push(arr1[i]);
      i++;
      j++;
    } else if (arr2.slice(j).includes(arr1[i])) {
      while (arr2[j] !== arr1[i]) {
        result.push(arr2[j]);
        j++;
      }
      result.push(arr1[i]);
      i++;
      j++;
    } else if (arr1.slice(i).includes(arr2[j])) {
      while (arr1[i] !== arr2[j]) {
        result.push(arr1[i]);
        i++;
      }
      result.push(arr2[j]);
      i++;
      j++;
    } else {
      result.push(arr1[i]);
      i++;
    }
  }
  result = result.concat(arr1.slice(i)).concat(arr2.slice(j));
  return result;
};

/**
 * 生成一个不重复的UUID
 * @param len 长度, 默认为6, 0则返回完整的uuid
 * @param existsIds 已经存在的id列表
 * @returns 生成的id, 超过100次未能生成新ID则返回null;
 */
export const getUnDuplicateUUID = (
  len = 6,
  existsIds?: string[]
): string | null => {
  let times = 0;
  while (times <= 100) {
    let tmpId = U.getUUID();
    if (len > 0) {
      tmpId = tmpId.substring(0, len);
    }
    if (existsIds && existsIds.includes(tmpId)) {
      times++;
    } else {
      return tmpId;
    }
  }
  return null;
};

/** 字符串首字母小写 */
export const lowerFirst = (str: string): string => {
  return str.substring(0, 1).toLocaleLowerCase() + str.substring(1);
};

/** 对比两个对象数组, 将key值相同的放在一起 */
export const listMapGroup = <T = any>(
  arr1: T[],
  arr2: T[],
  keys: string[]
): Array<{ item1?: T; item1Idx?: number; item2?: T; item2Idx?: number }> => {
  const keyMap: Record<
    string,
    { item1?: T; item1Idx?: number; item2?: T; item2Idx?: number }
  > = {};
  arr1.forEach((a, idx) => {
    const key = keys.map((k) => a[k as unknown as keyof T]).join("-");
    keyMap[key] = { item1: a, item1Idx: idx };
  });
  arr2.forEach((a, idx) => {
    const key = keys.map((k) => a[k as unknown as keyof T]).join("-");
    if (keyMap[key]) {
      keyMap[key].item2 = a;
      keyMap[key].item2Idx = idx;
    } else {
      keyMap[key] = { item2: a, item2Idx: idx };
    }
  });
  return Object.values(keyMap);
};

/** 获取字符串的字符长度 */
export const getCharacterLength = (str: string): number => {
  let length = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    // 中文字符的Unicode范围是[\u4e00-\u9fa5]
    if (charCode >= 0x4e00 && charCode <= 0x9fa5) {
      // 中文字符算2个字符
      length += 2;
    } else {
      // 英文字符算1个字符
      length += 1;
    }
  }
  return length;
};

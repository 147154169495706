import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Empty, Popover, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "./charts";
import { AggCode, Area, IDownloadHeader, ISeries } from "Interface";
import useGlobal from "Store";
import {
  fillCharts,
  getBetweenDateArr,
  getComparedPercentage,
  getComparePercentageVal,
  getModule,
  getModuleNameFromPath,
  isAirportMode,
  showNum,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import moment from "moment";
import {
  COMPARE_TYPE_PER_NAME,
  COMPARE_TYPE_VALUE_NAME,
  DATE_FORMAT,
  PERCENT_VALUES,
} from "Constants";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DISTRIBUTION_TREND_PROPORTION_HELP } from "Page/AI/PassengerAnalysis/PassengerAnalysisCommon";
import { useLocation } from "react-router-dom";
import DownloadBtn from "Components/DownloadBtn";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  cardCode?: string;
  area?: Area;
  departArea?: Area;
  arriveArea?: Area;
  type?: number;
  height?: number;
  style?: object;
  handleDownload?: () => void;
  ext?: any;
  hasPer?: boolean;
}

const BarLineCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    cardCode,
    area,
    height = 452,
    style,
    type,
    departArea,
    arriveArea,
    ext: otherExt,
    hasPer = true,
  } = props;
  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [rate, setRate] = useState<0 | 1>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const startDate = isAirport
    ? queryCondition.startDate
    : airlinesQueryCondition.startDate;
  const endDate = isAirport
    ? queryCondition.endDate
    : airlinesQueryCondition.endDate;
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      aggCode,
      rate,
      ...otherExt,
    },
    lazey: true,
  });

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1 || rate === 1;
  const refetch = () => {
    doFetch({
      query,
      ext: {
        aggCode,
        rate,
        ...otherExt,
      },
    });
  };

  useEffect(() => {
    if (otherExt.city || otherExt.row) {
      refetch();
    }
  }, [
    aggCode,
    cardCode,
    query,
    area,
    type,
    departArea,
    arriveArea,
    rate,
    otherExt,
  ]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value
          ? isPercentVal
            ? _.round(item.value * 100, 1)
            : _.round(item.value, 0)
          : 0;
        item.compareValue = item.compareValue
          ? isPercentVal
            ? _.round(item.compareValue * 100, 1)
            : _.round(item.compareValue, 0)
          : 0;
        const resultValue = _.round(
          isPercentVal
            ? getComparedPercentage(item.value, item.compareValue)
            : getComparePercentageVal(item.value, item.compareValue),
          1
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [data]);

  const seriesValueName =
    systemType === 2 && compareType === 1
      ? airlinesQueryCondition.airlines
      : getSharkText("charts.current_period.seriesvaluename");
  // const seriesResultValueName = getCardTrendText(cardCode, systemType, compareType)
  const seriesResultValueName = COMPARE_TYPE_PER_NAME[compareType];
  const seriesCompareValueName =
    systemType === 2 && compareType === 1
      ? getSharkText("charts.market.seriesvaluename")
      : COMPARE_TYPE_VALUE_NAME[compareType];

  const series: ISeries[] = useMemo(() => {
    return [
      {
        type: "line",
        encode: { x: "day", y: "value" },
        lineStyle: {
          width: 3,
        },
        name: seriesValueName,
        yAxisIndex: 1,
        showSymbol: false,
        symbol: "circle",
        // barMaxWidth: 20,
        // barGap: 0
      },
      {
        type: "line",
        encode: { x: "day", y: "compareValue" },
        lineStyle: {
          width: 3,
        },
        name: seriesCompareValueName,
        yAxisIndex: 1,
        showSymbol: false,
        symbol: "circle",
        // barMaxWidth: 20,
      },
      {
        type: "bar",
        encode: { x: "day", y: "resultValue" },
        barMaxWidth: 20,
        name: seriesResultValueName,
        // lineStyle: {
        //     width: 3
        // },
        // showSymbol: false,
        // symbol: 'circle'
      },
    ];
  }, [seriesCompareValueName, seriesResultValueName, seriesValueName]);

  const handleDownload = useRefFunc(() => {
    const dateColumn = [
      {
        title: getSharkText("charts.date_of_departure"),
        dataIndex: "day",
        render: (v) => moment(v).format(DATE_FORMAT),
      },
    ] as IDownloadHeader[];
    const columns = dateColumn.concat(
      series.map((s) => {
        return {
          title: s.name,
          dataIndex: s.encode.y,
          render: (v) =>
            isPercentVal || s.encode.y === "resultValue"
              ? showNum(v, "percentage")
              : showNum(v),
        } as IDownloadHeader;
      })
    );
    downloadExcel(
      columns,
      barData,
      getSharkText("menu_passenger_analysis_airport")
    );
  });

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  const onRateChange = (e: RadioChangeEvent) => {
    setRate(e.target.value);
  };

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        {hasPer ? (
          <Col>
            <Radio.Group value={rate} onChange={onRateChange} id="chartsRate">
              <Radio.Button value={0}>
                {getSharkText("key.value.text")}
              </Radio.Button>
              <Radio.Button value={1}>
                {getSharkText("key.proportion.source")}
                <Popover content={DISTRIBUTION_TREND_PROPORTION_HELP}>
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Popover>
              </Radio.Button>
            </Radio.Group>
          </Col>
        ) : undefined}
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          isPercentVal={isPercentVal}
          loading={isLoading}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          cardCode={cardCode}
          isDemo={IsDemo}
          series={series}
          seriesValueName={seriesValueName}
        />
      )}
    </Card>
  );
};

export default BarLineCharts;

import React, { useMemo } from "react";
import { Radio, Select } from "antd";
import { ModuleType, SystemType } from "Interface";
import { SYSTEM_TYPES } from "Constants";
import { useLocation } from "react-router-dom";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobalState from "Store";

interface TypeProps {
  systemType: SystemType;
  changeSystemType: (type: SystemType) => void;
}

const { Option } = Select;

const SelectSystemType: React.FC<TypeProps> = (props: TypeProps) => {
  const { systemType, changeSystemType } = props;
  const location = useLocation();
  const [globalState] = useGlobalState();
  const { moduleList } = globalState.userInfo;

  const { pathname } = location;
  const list = moduleList.filter((item) => item.menuUrl === pathname);

  const isOnlyAirport =
    list.length === 1 && list[0].moduleType === ModuleType.airport;
  const isOnlyAirline =
    list.length === 1 && list[0].moduleType === ModuleType.airline;

  if (isOnlyAirline && systemType === SystemType.airport) {
    changeSystemType(SystemType.airlines);
  }
  if (isOnlyAirport && systemType === SystemType.airlines) {
    changeSystemType(SystemType.airport);
  }

  const handleChange = (value: number) => {
    changeSystemType(value);
  };

  const options = useMemo(() => {
    return SYSTEM_TYPES.map((item) =>
      // <Option className={item.className} key={item.value} value={item.value}>
      //   {item.name}
      //   {getSharkText("key.version.text")}
      // </Option>
      ({
        label: item.name + getSharkText("key.version.text"),
        value: item.value,
      })
    );
  }, []);

  if (isOnlyAirline || isOnlyAirport) return null;

  return (
    <Radio.Group
      options={options}
      optionType="button"
      buttonStyle="solid"
      value={systemType}
      style={{ minWidth: 90, marginRight: 16 }}
      onChange={(e) => handleChange(e.target.value)}
    ></Radio.Group>
  );
};

export default SelectSystemType;

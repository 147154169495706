import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  Dimension,
  Filter,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AIRLINE_NAME,
  APORT_NAME,
  APORTNAME_NAME,
  ARR_CITYNAME_NAME,
  ARR_CONTINENTNAME_NAME,
  ARR_COUNTRYNAME_NAME,
  ARR_PROVINCENAME_NAME,
  ARR_REGIONNAME_NAME,
  BRIDGE_RATIO_NAME,
  customCols,
  DATASET_DA_ID,
  DATASET_ID,
  DEP_CITYNAME_NAME,
  DEP_CONTINENTNAME_NAME,
  DEP_COUNTRYCLASS_NAME,
  DEP_COUNTRYNAME_NAME,
  DEP_PROVINCENAME_NAME,
  DEP_REGIONNAME_NAME,
  DPORT_NAME,
  DPORTNAME_NAME,
  FLIGHTSTATE_NAME,
  ONTIME_RATIO_NAME,
  TKZONE,
} from "../AirportRanking/DataSetColumns";
import RankingV2View from "Components/RankingV2/RankingV2View";
import { Item } from "Components/RankingV2/RankingV2Interface";
import { Button, Card, Col, Radio, Row, Spin, Typography } from "antd";
import { Area, FlightClass } from "Interface";
import useGlobalState from "Store";
import { SwapLeftOutlined, SwapRightOutlined } from "@ant-design/icons";
import Refetch from "Components/Refetch";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import RankSortBtn from "../../../../../Components/RankSortBtn";

const titleText = getSharkText("key.routes_performance");

interface ResData {
  dportname: string;
  aportname: string;
  m_exp_bridge_rate: number;
  m_exp_ontime_rate: number;
}

const tabValues = ["ontime", "bridge"] as const;
type TabType = (typeof tabValues)[number];

const tabs: Array<{ label: string; value: TabType }> = [
  {
    label: getSharkText("config_page_ontime_rate"),
    value: "ontime",
  },
  {
    label: getSharkText("config_page_bridge_rate"),
    value: "bridge",
  },
];

const internal: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_COUNTRYCLASS_NAME, arr: ARR_COUNTRYNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const inland: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_REGIONNAME_NAME, arr: ARR_REGIONNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const AreaColumnMap: Record<
  FlightClass,
  Record<number, { dep: string; arr: string }>
> = {
  [FlightClass.All]: internal,
  [FlightClass.Domestic]: inland,
  [FlightClass.Foreign]: internal,
  [FlightClass.Overseas]: internal,
};

interface FilterGenerator {
  depart: 0 | 1;
  arrive: 0 | 1;
  flightClass: FlightClass;
  startDate: string;
  endDate: string;
  /** 页面顶部的全局机场 */
  port: Area;
  /** 选中的机场 */
  area?: Area;
  airline: string;
  tab: TabType;
}

const getFilters = (param: FilterGenerator): [Filter[], StandardFilter[]] => {
  const {
    depart,
    arrive,
    flightClass,
    startDate,
    endDate,
    port,
    area,
    airline,
    tab,
  } = param;
  const filters: Filter[] = [];
  let oriFilters: StandardFilter[] = [];
  if (airline) {
    filters.push({
      columnName: AIRLINE_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: airline.split(","),
        },
      ],
    });
  }
  if (flightClass === FlightClass.Domestic) {
    filters.push({
      columnName: DEP_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  } else if (flightClass === FlightClass.Foreign) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
    filters.push({
      columnName: DEP_COUNTRYCLASS_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  }
  const daArr: StandardFilter[] = [
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].dep}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].arr
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
  ];
  const adArr: StandardFilter[] = [
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].dep
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].arr}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
  ];
  if (depart && arrive) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: { filters: daArr },
          },
          {
            and: { filters: adArr },
          },
        ],
      },
    });
  } else if (depart) {
    oriFilters = oriFilters.concat(daArr);
  } else if (arrive) {
    oriFilters = oriFilters.concat(adArr);
  }
  if (startDate && endDate) {
    oriFilters.push({
      range: {
        field: `dimension.${TKZONE}`,
        strRange: { lower: startDate, upper: endDate },
      },
    });
  }
  // if (tab === "ontime") {
  //   filters.push({
  //     columnName: DA_TYPE_NAME,
  //     filterConfig: [
  //       {
  //         calculate: "include",
  //         argsType: "string",
  //         stringArgs: ["da"],
  //       },
  //     ],
  //   });
  // } else if (depart && !arrive) {
  //   filters.push({
  //     columnName: DA_TYPE_NAME,
  //     filterConfig: [
  //       {
  //         calculate: "include",
  //         argsType: "string",
  //         stringArgs: ["da"],
  //       },
  //     ],
  //   });
  // } else if (!depart && arrive) {
  //   filters.push({
  //     columnName: DA_TYPE_NAME,
  //     filterConfig: [
  //       {
  //         calculate: "include",
  //         argsType: "string",
  //         stringArgs: ["ad"],
  //       },
  //     ],
  //   });
  // }
  oriFilters.push({
    not: {
      filter: {
        in: {
          field: `dimension.${FLIGHTSTATE_NAME}`,
          values: ["取消"],
        },
      },
    },
  });
  return [filters, oriFilters];
};

export interface AirportRankingProps {
  area?: Area;
  airline: string;
}

/** 机场排行榜组件 */
const AirportRanking = (props: AirportRankingProps): ReactElement => {
  const { area, airline } = props;
  const [globalState] = useGlobalState();
  const [currentTab, setCurrentTab] = useState<TabType>("ontime");
  const { queryCondition } = globalState;
  const {
    departure,
    arrive,
    flightClass,
    startDate,
    endDate,
    originalAirport,
  } = queryCondition;
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<ResData[]>([]);
  const [sort, setSort] = useState<"DESC" | "ASC">("DESC");
  const services = useServices();
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_DA_ID)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers);
      setResData(source as unknown as ResData[]);
    },
  });

  const columns = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const refetch = useCallback(() => {
    if (!datasetCols.length || !originalAirport) {
      return;
    }
    const dimensions: Dimension[] = [
      {
        columnName: DPORTNAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: APORTNAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    const measures: Measure[] = [
      {
        id: ONTIME_RATIO_NAME,
        columnName: ONTIME_RATIO_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: BRIDGE_RATIO_NAME,
        columnName: BRIDGE_RATIO_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ];
    const [filters, oriFilters] = getFilters({
      depart: departure,
      arrive,
      flightClass,
      startDate,
      endDate,
      area,
      airline,
      port: originalAirport,
      tab: currentTab,
    });
    const sorters: Sorter[] = [
      currentTab === "ontime"
        ? {
            chartUsedColId: ONTIME_RATIO_NAME,
            columnName: ONTIME_RATIO_NAME,
            sorter: sort,
            statistical: "COUNT",
          }
        : {
            chartUsedColId: BRIDGE_RATIO_NAME,
            columnName: BRIDGE_RATIO_NAME,
            sorter: sort,
            statistical: "COUNT",
          },
    ];

    const datasetId = departure && arrive ? DATASET_DA_ID : DATASET_ID;

    const requestBuild = new RequestBuilder({
      datasetId,
      columns,
      dimensions,
      measures,
      chartFilters: filters,
      sorters,
      containerFilters: [],
      oriFilters,
      limit: 10,
    });
    const { encrypted } = requestBuild.getRequestBody();
    setResData([]);
    doFetch({
      ext: {
        datasetId,
        colIds: [],
        req: encrypted,
      },
    });
  }, [
    airline,
    area,
    arrive,
    columns,
    currentTab,
    datasetCols.length,
    departure,
    doFetch,
    endDate,
    flightClass,
    originalAirport,
    sort,
    startDate,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => {
    return resData.map((r, i) => {
      const name = r.dportname + " - " + r.aportname;
      const tmp: Item = {
        id: name,
        name,
        value:
          currentTab === "ontime" ? r.m_exp_ontime_rate : r.m_exp_bridge_rate,
        rnk: i,
      };
      return tmp;
    });
  }, [currentTab, resData]);

  const handleSortChange = useRefFunc(() => {
    setSort(sort === "DESC" ? "ASC" : "DESC");
  });

  if (error) {
    <Refetch error={error} refetch={refetch} />;
  }

  if (isLoading) {
    <Spin />;
  }

  return (
    <div className="ranking-v2">
      <Spin spinning={isLoading}>
        <Card className="ranking-card">
          <Typography.Title level={4}>{titleText}</Typography.Title>
          <Row justify="space-between" data-ut-id="ranking-tab">
            <Col>
              <Radio.Group
                onChange={(e) => setCurrentTab(e.target.value)}
                value={currentTab}
                id="rankTab"
                buttonStyle="outline"
                options={tabs}
                optionType="button"
              />
            </Col>
            <Col>
              <RankSortBtn
                sort={sort === "DESC"}
                handleSortChange={handleSortChange}
              />
            </Col>
          </Row>
          <RankingV2View data={data} formatter="percent" />
        </Card>
      </Spin>
    </div>
  );
};
export default AirportRanking;

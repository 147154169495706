import React, { useEffect } from "react";
import { Col, Descriptions, Row, Spin, Typography } from "antd";
import useGlobal from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import Subscribe from "../Subscribe";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import _ from "lodash";
import { getServer } from "Service/server";

interface FollowProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  refetchCount: number;
  isFormal: boolean;
}

const { Title } = Typography;

const Follow: React.FC<FollowProps> = (props: FollowProps) => {
  const { queryUrl, moduleCode, chartTableCode, refetchCount, isFormal } =
    props;
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const query = airlinesQueryCondition;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    lazey: true,
  });
  const subscribed = data && data.subscribed ? data.subscribed : 2;
  const followAirlineCount =
    data && data.followAirlineCount ? data.followAirlineCount : 0;
  const followIndex = data && data.followIndex ? data.followIndex : 0;

  const refetch = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
    });
  };

  useEffect(() => {
    refetch();
  }, [query, refetchCount]);

  if (error) {
    return <Refetch error={error} refetch={refetch} />;
  }

  return (
    <Spin spinning={isLoading}>
      <Row gutter={20} align="middle" style={{ paddingLeft: 15 }}>
        <Col style={{ flex: 1 }}>
          <Descriptions>
            <Descriptions.Item
              label={getSharkText("config_page_suggested_follow_flight_count")}
            >
              <Title level={2} style={{ margin: "0 0 0 10px" }}>
                {followAirlineCount}
              </Title>
            </Descriptions.Item>
            <Descriptions.Item
              label={getSharkText("config_page_suggested_follow_index")}
            >
              <Title level={2} style={{ margin: "0 0 0 10px" }}>
                {followIndex ? _.round(followIndex, 1) : ""}%
              </Title>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col style={{ paddingBottom: 10 }}>
          {isFormal ? (
            <Subscribe
              queryUrl={QUERY_URL[2].subscribe}
              moduleCode={MODULE_CODE[2]}
              chartTableCode={CHART_TABLE_CODE[2].subscribe}
              subscribed={subscribed}
              refetch={refetch}
            />
          ) : undefined}
        </Col>
      </Row>
    </Spin>
  );
};

export default Follow;

// Created by xh_zhu on 2021-01-29

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  durationFormatter,
  genSeriesByDimensions,
  isSame,
  listMap2dataSet,
  showNum,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import moment from "moment";
import { DATE_MINUTE_FORMAT } from "Constants";
import { cloneDeep, isEmpty, isNumber, round } from "lodash";
import { ISeries } from "Interface";
import EChartsBase from "Components/EChartsBase";
import {
  IFlightReviewFilter,
  ISearchData,
  TCompareType,
} from "Page/AI/FlightReview/ReviewCommon";
import { getServer } from "Service/server";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightReview/fetchCode";

const defaultSeries: ISeries[] = [
  {
    name: getSharkText("config_page_route_search_index"),
    type: "line",
    connectNulls: true,
    encode: {
      x: "diffDay",
      y: "searchIndex",
    },
  },
  {
    name: getSharkText("config_page_seat_increment"),
    type: "bar",
    yAxisIndex: 1,
    connectNulls: true,
    encode: {
      x: "diffDay",
      y: "seatInc",
    },
  },
];
interface ISearchProps {
  queryExt?: IFlightReviewFilter;
  type: TCompareType;
  xMax?: number;
  xMin?: number;
  setXMax: (v: number | undefined) => void;
  setXMin: (v: number | undefined) => void;
  onChartInstanceChange: (v: any) => void;
}

/**
 * 1. 请求数据时设置dataFilled为[],
 * 2. 请求数据后对携程和预测数据进行起飞小时重算,并且合并数据, 放入dataMerged
 * 3. 根据dataMerged计算最大最小值, 并且上报到parent组件
 * 4. 监听最大最小值, 并且填充空白维度数据,放入dataFilled
 * 5. 监听dataFilled并且更新option
 */
const Search = (props: ISearchProps): ReactElement => {
  const {
    queryExt,
    type,
    xMax,
    xMin,
    setXMax,
    setXMin,
    onChartInstanceChange,
  } = props;
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition } = globalState;
  const [option, setOption] = useState<any>(undefined);
  const [dataFilled, setDataFilled] = useState<ISearchData[]>([]);
  const ref = useRef<any>();

  useEffect(() => {
    if (ref) {
      try {
        onChartInstanceChange(ref.current.getEchartsInstance());
      } catch (e) {
        console.log(e);
      }
    }
  }, [ref, onChartInstanceChange]);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].search,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
      type,
    },
  });

  const refetch = useCallback(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...queryExt,
        type,
      },
    });
  }, [queryExt, type, airlinesQueryCondition, doFetch]);

  /**
   * 查询条件变更, 拉取新数据
   */
  useEffect(() => {
    setDataFilled([]);
    refetch();
  }, [refetch]);

  // 计算最大最小X轴的值
  useEffect(() => {
    let max: number | undefined;
    let min: number | undefined;
    const setMaxMin = (item: ISearchData) => {
      if (max === undefined || max < item.diffDay) {
        max = item.diffDay;
      }
      if (min === undefined || min > item.diffDay) {
        min = item.diffDay;
      }
    };
    if (Array.isArray(data)) {
      data.forEach(setMaxMin);
      if (max !== undefined && min !== undefined) {
        setXMax(max);
        setXMin(min);
      }
    }
    console.log("load max min end", max, min);
  }, [data, setXMax, setXMin]);

  // 补齐坐标轴
  useEffect(() => {
    const dataCopy = cloneDeep(data);
    if (xMax !== undefined && xMin !== undefined && dataCopy !== undefined) {
      for (let i = xMax; i >= xMin; i--) {
        const idx = dataCopy.findIndex(
          (d: ISearchData) => round(d.diffDay) === round(i)
        );
        if (idx < 0) {
          const newItem = cloneDeep(dataCopy[0]);
          if (newItem) {
            Object.keys(newItem).forEach((key) => {
              newItem[key] = null;
            });
          }
          dataCopy.push({ ...newItem, diffDay: i, day: "" } as ISearchData);
        }
      }
      setDataFilled(dataCopy || []);
    }
  }, [data, xMax, xMin]);

  useEffect(() => {
    if (ref && ref.current) {
      if (isLoading) {
        ref.current.showLoading();
      } else {
        ref.current.hideLoading();
      }
    }
  }, [isLoading, ref]);

  useEffect(() => {
    console.log(option);
    if (option && ref && ref.current) {
      ref.current.setOption(option, { notMerge: true });
    }
  }, [ref, option]);

  useEffect(() => {
    if (dataFilled.length) {
      const dataSource2 =
        Array.isArray(dataFilled) &&
        listMap2dataSet(
          dataFilled.sort((a: ISearchData, b: ISearchData) =>
            a.diffDay < b.diffDay ? 1 : -1
          )
        );
      const dimensions = Array.isArray(dataSource2) ? dataSource2[0] : [];

      const xMaxMin: any = {};
      let sliderStart = 0;
      const defaultSliderStart = 15 * 24;
      if (
        xMax !== undefined &&
        xMin !== undefined &&
        defaultSliderStart > xMin &&
        defaultSliderStart < xMax
      ) {
        sliderStart = 100 - ((defaultSliderStart - xMin) / (xMax - xMin)) * 100;
      }
      console.log("sliderStart", sliderStart);

      const opt = {
        color: [
          "#01C5DB",
          "#FC9B4F",
          "#177DFE",
          "#E96B5B",
          "#6950a1",
          "#769149",
        ],
        title: {
          text: getSharkText("key.search.button"),
          // textVerticalAlign: 'middle'
          top: "center",
          left: "2%",
        },
        dataset: {
          dimensions,
          source: dataSource2,
        },
        legend: {
          itemGap: 20,
          itemWidth: 16,
          itemHeight: 8,
          top: 0,
        },
        dataZoom: [
          {
            type: "inside",
            start: sliderStart,
            filterMode: "weakFilter",
          },
          {
            type: "slider",
            bottom: 0,
            start: sliderStart,
            filterMode: "weakFilter",
            labelFormatter: (val: string, valStr: string) => {
              const hours = round(parseFloat(valStr));
              return durationFormatter(hours * 3600, "d");
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            // console.log('load', params);
            const dayIndex = params[0].dimensionNames.indexOf("gatherDay");
            const currentDay = moment(params[0].data[dayIndex]).format(
              DATE_MINUTE_FORMAT
            );
            let compareDay;
            const compareDayIndex =
              params[0].dimensionNames.indexOf("compareDay");
            if (compareDayIndex >= 0) {
              const hasCompareDayPoint = params.find(
                (point: any) => !!point.data[compareDayIndex]
              );
              compareDay = hasCompareDayPoint
                ? moment(hasCompareDayPoint.data[compareDayIndex]).format(
                    DATE_MINUTE_FORMAT
                  )
                : undefined;
            }
            // 150时当前起飞航班文字最长宽度, 11px是线条颜色点的宽度
            const day = `<span style="width: 150px; margin-left:11px; display:inline-block">${currentDay}</span>${
              compareDay ? `<span>${compareDay}</span>` : ""
            }`;
            let dom = "";
            params.forEach((item: any) => {
              const baseSeriesName = `${item.seriesName}`;
              const compareBaseSeriesName = `${getSharkText(
                "key.compare.name"
              )}${baseSeriesName}`;
              const series = params.filter(
                (item: any) =>
                  item.seriesName === baseSeriesName ||
                  item.seriesName === compareBaseSeriesName
              );
              if (
                series.length > 0 &&
                series.some((s: any) => s.value[s.encode.y[0]] !== null)
              ) {
                const dot = `<span class='dot' style='background:${series[0].color}'></span>`;
                const currentSeries = series.find(
                  (item: any) => item.seriesName === baseSeriesName
                );
                const currentVal =
                  currentSeries &&
                  currentSeries.value[currentSeries.encode.y[0]];
                const currentDom = `${baseSeriesName}: ${
                  isNumber(currentVal) ? showNum(currentVal, "num", 1) : "--"
                }`;
                let compareDom;
                const val = `<span style="width: 150px; display:inline-block">${currentDom}</span>${
                  compareDom ? `<span>${compareDom}</span>` : ""
                }`;
                dom = dom + "<br />" + dot + val;
              }
            });
            if (isEmpty(dom)) {
              return null;
            }
            dom = day + dom;
            return dom;
          },
        },
        xAxis: {
          type: "category",
          name: getSharkText("config_page_days_from_takeoff"),
          nameLocation: "start",
          nameTextStyle: {
            padding: [25, 35, 0, 0],
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            overflow: "truncate",
            interval: (index: number, value: string) => {
              const hours = round(parseFloat(value));
              // 仅显示整天的数据
              const tmp = hours % 24;
              if (tmp !== 0) {
                return false;
              }
              return true;
            },
            formatter: (val: number) => {
              // const hours = moment(record.takeofftime).diff(moment(val), 'hours');
              const hours = round(val);
              return durationFormatter(hours * 3600, "h");
            },
          },
          ...xMaxMin,
        },
        yAxis: [
          {
            name: "航线搜索指数",
            type: "value",
            splitNumber: 4,
            min: (v: any) => v.min * 0.9,
            axisLabel: {
              formatter: (value: number) => showNum(value, "num", 1),
            },
          },
          {
            show: true,
            name: getSharkText("config_page_seat_increment"),
            splitNumber: 4,
            axisLabel: {
              formatter: (value: number) => showNum(value),
            },
          },
        ],
        series: genSeriesByDimensions(dimensions, defaultSeries),
      };
      if (!isSame(opt, option)) {
        setOption(opt);
        console.log("load updated option", option);
        // ref && ref.current && ref.current.setOption(opt);
        // console.log(opt, ref);
      } else {
        console.log("option same");
      }
    } else if (!dataFilled || dataFilled.length === 0) {
      setOption({
        graphic: {
          id: "noData",
          type: "text",
          $action: "merge",
          z: 100,
          left: "center",
          top: "center",
          style: {
            fill: "#666",
            text: [
              getSharkText("config_page_data_empty"),
              getSharkText("config_page_please_reselect_filter_conditions"),
            ].join("\n"),
            font: "26px Microsoft YaHei",
          },
        },
      });
    }
  }, [dataFilled]);

  return <EChartsBase ref={ref}></EChartsBase>;
};
export default Search;

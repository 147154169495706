import { SubCabinEntityExtend } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import { countBy, sortBy, toPairs } from "lodash";
import React, { ReactNode } from "react";

const SMALL_CABIN_TITLE = getSharkText(
  "config_page_current_effective_small_cabin"
);
const FARE_PRICE_TITLE = getSharkText("effective_fare_price");

export const getCabinTips = (
  subCabin: SubCabinEntityExtend
): { color: string; popupContent: ReactNode[] } | null => {
  let color = "";
  if (subCabin.effectiveSmallCabin && subCabin.dynamicFarePrice) {
    color = "orange";
  } else if (subCabin.dynamicFarePrice) {
    color = "var(--main)";
  } else if (subCabin.effectiveSmallCabin) {
    color = "green";
  } else {
    return null;
  }
  const popupContent = [];
  if (subCabin.effectiveSmallCabin) {
    const effPrice = subCabin.effectiveFarePrice
      ? `, ${subCabin.effectiveFarePrice}`
      : "";
    popupContent.push(
      <div key="smallCabin" style={{ color: "green" }}>
        <span style={{ marginRight: 5 }}>{SMALL_CABIN_TITLE}:</span>
        {subCabin.effectiveSmallCabin}
        {effPrice}
      </div>
    );
  }
  if (subCabin.dynamicFarePrice) {
    popupContent.push(
      <div key="farePrice" style={{ color: "var(--main)" }}>
        <span style={{ marginRight: 5 }}>{FARE_PRICE_TITLE}:</span>
        {subCabin.dynamicFarePrice}
      </div>
    );
  }
  return { color, popupContent };
};

/** 航节排序 先区分出发到达统计, 按出发降序排序 */
const legOrder = (legs: string[]): string[] => {
  const departArr = legs.map((leg) => leg.substring(0, 3));
  const arriveArr = legs.map((leg) => leg.substring(3));
  const count = countBy(departArr);
  const sortedCount = sortBy(toPairs(count), (item) => -item[1]);
  const sorted = sortedCount.map((item) => item[0]);
  arriveArr.reduce((pre, cur) => {
    if (!pre.includes(cur)) {
      sorted.push(cur);
    }
    return sorted;
  }, sorted);
  return sorted;
};

export { legOrder };
